// https://stackoverflow.com/questions/36759985/how-to-style-mui-tooltip
export const tooltipTheme = (theme) => ({
  styleOverrides: {
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 12,
      fontWeight: 400,
      padding: 6,
    },
    arrow: {
      color: theme.palette.common.black,
    },
  },
});
