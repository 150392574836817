export const buttonTheme = (theme) => ({
  variants: [
    {
      props: { size: 'large' },
      style: {
        fontSize: 19,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
  ],
});
