import React, { useState } from 'react';

import { Alert, Box, Typography, Divider, Collapse } from '@mui/material';
import { Button } from '@components/button/Button';
import TextField from '@components/TextField/TextField';

// import { Alert } from '@mui/lab';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSendEmail } from '@fetch-components/members';

import pluralize from 'pluralize';
import { useCurrentUser } from '@contexts/CurrentUserContext';

const EmailMembersModal = ({ members, waitlist, scope, membersCount }) => {
  const { currentUser } = useCurrentUser();

  const onSuccess = () => {};

  const mutation = useSendEmail({ waitlistId: waitlist.id, onSuccess });

  console.log('members', members);

  return (
    <div style={{ width: '100%', maxWidth: 480 }}>
      <Box my={2}>
        <Typography variant="h3">Email members</Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Limits
        membersCount={membersCount || members.length}
        monthlyEmailsSent={currentUser.limits.monthly_emails_sent}
        monthlyEmailsLimit={currentUser.limits.monthly_emails_limit}
      />
      {mutation.isError && <ErrorMessage message={'Something went wrong'} />}
      {mutation.isSuccess && (
        <Box my={2}>
          <SuccessMessage message={'Done. Your emails are on their way...'} />
        </Box>
      )}
      {!mutation.isSuccess && (
        <div>
          <Box mt={4}>
            <Form
              to={members}
              handleSubmit={async (values) => {
                await mutation.mutateAsync({
                  ...values,
                  emails: members.map((m) => m.user.email),
                  scope: scope,
                });
              }}
            />
          </Box>
        </div>
      )}
    </div>
  );
};
EmailMembersModal.defaultProps = {
  members: [],
};

const Limits = ({ membersCount, monthlyEmailsSent, monthlyEmailsLimit }) => {
  const [showDetails, setShowDetails] = useState(false);

  const available = monthlyEmailsLimit - monthlyEmailsSent;
  const percentAvailable =
    available > 0 ? (available * 100) / monthlyEmailsLimit : 0;

  return (
    <Box my={2}>
      <Typography variant="subtitle1">
        This email will be sent to <strong>{membersCount}</strong> list{' '}
        {pluralize('member', membersCount)}.
      </Typography>
      <Typography variant="body1">
        {percentAvailable}% of your send limit is left.{' '}
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowDetails(!showDetails);
          }}
        >
          View details.
        </a>
      </Typography>

      <Collapse in={showDetails} direction="up">
        <Box mt={2}>
          <Typography variant="body2">
            Your plan allows you up to{' '}
            <strong>{monthlyEmailsLimit} email sends per month</strong> — you
            have{' '}
            <strong>
              {monthlyEmailsLimit - monthlyEmailsSent} email sends left
            </strong>
            . After sending your emails, it can take a minute or two to update
            your send numbers.{' '}
            <a href="/plans" target="_blank">
              Learn more.
            </a>
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

const validationSchema = yup.object().shape({
  subject: yup.string().required(),
  content: yup.string().required(),
});

const Form = ({ handleSubmit, to }) => {
  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit: handleFormSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleFormSubmit}>
            <Box>
              <TextField
                placeholder="Subject of your email"
                variant="outlined"
                id="subject"
                label="Subject"
                value={values.subject}
                onChange={handleChange}
                error={Boolean(errors.subject)}
                helperText={errors.subject}
                sx={{ width: '100%' }}
              />
            </Box>

            <Box my={2}>
              <TextField
                aria-label="message content"
                size="medium"
                variant="outlined"
                multiline
                placeholder="This is the content of your email"
                id="content"
                label="Content"
                value={values.content}
                onChange={handleChange}
                error={Boolean(errors.content)}
                helperText={errors.content}
                sx={{ width: '100%' }}
              />
            </Box>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              sx={{ width: '100%' }}
            >
              Send
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
EmailMembersModal.defaultProps = {
  scope: 'all',
};
export default EmailMembersModal;

const SuccessMessage = ({ message }) => (
  <Alert severity="success">{message}</Alert>
);
const ErrorMessage = ({ message }) => <Alert severity="error">{message}</Alert>;
