import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@mui/material";

const SelectFieldItem = ({ value, children }) => (
  <MenuItem value={value}>{children}</MenuItem>
);

SelectFieldItem.propTypes = {};

export default SelectFieldItem;
