import { useMutation } from "react-query";
import { authToken } from "./auth";
import { API_URL } from "./api";

export const useCreateExport = ({ waitlistId, onSuccess, onError }) => {
  const request = (values) =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members/exports`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (onError) {
        onError(response);
      }
    });

  return useMutation(request, {
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {},
  });
};
