import TextField from '@components/TextField/TextField';
import { useUpdateMember } from '@fetch-components/members';
import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import {
  ActionsCell,
  ExpandCell,
  JoinDateCell,
  PaymentCell,
  PointsCell,
  PositionCell,
  SelectCell,
  UsernameCell,
} from './Cell';

const Row = ({ isLoading, row, notesEnabled }) => {
  if (isLoading) {
    return (
      <TableRow>
        <SelectCell isLoading />
        <ExpandCell isLoading />
        <PositionCell isLoading />
        <UsernameCell isLoading />
        <JoinDateCell isLoading />
        <PointsCell isLoading />
        <PaymentCell isLoading />
        <ActionsCell isLoading />
      </TableRow>
    );
  }
  if (!row) {
    return null;
  }
  return (
    <>
      <TableRow
        hover
        aria-checked={row.isSelected}
        selected={row.isSelected}
        sx={{ '& > *': { borderBottom: row.isExpanded ? 'unset' : undefined } }}
      >
        {row.cells.map((cell) => cell.render('Cell'))}
      </TableRow>
      {row.isExpanded && (
        <SubRow
          isSelected={row.isSelected}
          member={row.original}
          notesEnabled={notesEnabled}
        />
      )}
    </>
  );
};

const SubRow = ({ member, isSelected, notesEnabled }) => (
  <TableRow>
    <TableCell colSpan={8}>
      <Stack direction="row" spacing={4} justifyContent="space-between">
        <Box pl={2} sx={{ marginTop: -1 }}>
          {member.waitlist.requirements.full_name &&
            member.user.profile.name && <div>{member.user.profile.name}</div>}
          <div>
            {member.user.email}
            {!member.user.confirmed_at && (
              <>
                &nbsp;
                <Chip size="small" label={'Unconfirmed'} />
              </>
            )}
          </div>
          {member.waitlist.requirements.phone_number &&
            member.user.profile?.phone_number && (
              <div>{member.user.profile.phone_number}</div>
            )}
          {member.waitlist.requirements.mailing_address && (
            <div>
              {[member.user.profile?.address, member.user.profile?.address_two]
                .filter((e) => !!e)
                .join(', ')}
              <br />
              {[
                member.user.profile?.city,
                member.user.profile?.state,
                member.user.profile?.postal_code,
              ]
                .filter((e) => !!e)
                .join(', ')}
            </div>
          )}
          {member.waitlist.custom_requirements && (
            <div>
              {member.waitlist.custom_requirements.map((customRequirement) => (
                <Box>
                  <strong>{customRequirement.name} </strong>
                  {member.extra_data[customRequirement.slug]}
                </Box>
              ))}
            </div>
          )}
          {member.removed_at && (
            <Box mt={2}>
              <Divider style={{ width: 100, marginBottom: 16 }} />
              <div>
                <span>Joined: </span>
                {moment(member.entered_at)
                  .utc()
                  .format('MMM. Do YYYY, h:mm:ss a')}
              </div>
              <div>
                <span>Removed: </span>
                {moment(member.removed_at)
                  .utc()
                  .format('MMM. Do YYYY, h:mm:ss a')}
              </div>
            </Box>
          )}
        </Box>
        <Box pr={2} sx={{ width: 400 }}>
          {notesEnabled && <Note member={member} />}
        </Box>
      </Stack>
    </TableCell>
  </TableRow>
);

const Note = ({ member }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [note, setNote] = useState(member.note);

  const mutation = useUpdateMember({
    waitlistId: member.waitlist.id,
    memberId: member.id,
    onSuccess: (response) => {
      enqueueSnackbar('Member note updated!', {
        variant: 'success',
      });
    },
  });

  const debounceLoadData = useCallback(_.debounce(mutation.mutate, 1000), []);

  return (
    <div>
      {mutation.isLoading && <LinearProgress />}

      <TextField
        multiline
        autoComplete="off"
        aria-label="empty textarea"
        placeholder="Add a note (for your eyes only)"
        value={note}
        fullWidth
        onChange={(e) => {
          setNote(e.target.value);
          debounceLoadData({ note: e.target.value });
        }}
      />
    </div>
  );
};

export default Row;
