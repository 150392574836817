function RouterStack() {
  this.push = function (action) {
    var stack = JSON.parse(localStorage.getItem("router-stack")) || [];
    stack.push(action);
    var json = JSON.stringify(stack);
    localStorage.setItem("router-stack", json);
  };

  this.pop = function () {
    var stack = JSON.parse(localStorage.getItem("router-stack")) || [];
    var action = stack.pop();
    var json = JSON.stringify(stack);
    localStorage.setItem("router-stack", json);

    return action;
  };

  this.pushFunction = function (func) {
    var stack = JSON.parse(localStorage.getItem("router-function-stack")) || [];
    stack.push(func);
    var json = JSON.stringify(stack);
    localStorage.setItem("router-function-stack", json);
  };

  this.popFunction = function () {
    var stack = JSON.parse(localStorage.getItem("router-function-stack")) || [];
    var func = stack.pop();
    var json = JSON.stringify(stack);
    localStorage.setItem("router-stack", json);

    return func;
  };
}

module.exports = RouterStack;
