require("jquery-serializejson");

function JoinListFormController(list, stripe, options) {
  options = typeof options !== "undefined" ? options : {};
  var controller = this;
  this.list = list;
  this.stripe = stripe;
  this.joinListButtonLoader = new Loader(".joinListButtonLoader");
  this.joinListButtonLoader.hide();
  this.joinButton = $(".joinListButtonLoader");
  this.options = options;
  var form = $("form#new_member");
  formMessages = new FormMessages(form);

  window.joinController = controller;

  setUpNewMemberValidation();

  setUpStripeForm();

  var onSubmitFormCallback = function (event) {
    event.preventDefault();

    var form = $("form#new_member");
    var submitButton = form.find('button[type="submit"]');
    //controller.joinListButtonLoader.show();
    submitButton.attr("disabled", true);

    if (!form.valid()) {
      submitButton.attr("disabled", false);
      return false;
    }

    controller.joinListButtonLoader.show();

    if (
      controller.options.accept_payments &&
      (controller.options.payment_required || !controller.cardNumber._empty)
    ) {
      formMessages.clear();

      controller.stripe
        .createToken(controller.cardNumber)
        .then(function (response) {
          if (response.error) {
            errorMessage = response.error.message;
            formMessages.addError(errorMessage);
            formMessages.scrollToMessages();
            submitButton.removeAttr("disabled");
            controller.joinListButtonLoader.hide();

            return false;
          }

          $('input[name="credit_card_token"]').val(response.token.id);

          controller.createNewMembership();
        });
    } else {
      controller.createNewMembership();
    }

    return false;
  };

  $("form#new_member").on("submit", onSubmitFormCallback);

  this.createNewMembership = function () {
    var form = $("form#new_member");

    var data = {
      referral: $('input[name="referral"]').val(),
      credit_card_token: $('input[name="credit_card_token"]').val(),
      member: {
        provider: $('input[name="member[provider]"]').val(),
        uuid: $('input[name="member[uuid]"]').val(),
        id: $('input[name="member[id]"]').val(),
        email: $('input[name="member[email]"]').val(),
        name: $('input[name="member[name]"]').val(),
        phone_number: $('input[name="member[phone_number]"]').val(),
        address: {
          street: $('input[name="member[address][street]"]').val(),
          city: $('input[name="member[address][city]"]').val(),
          state: $('input[name="member[address][state]"]').val(),
          postal_code: $('input[name="member[address][postal_code]"]').val(),
        },
      },
    };

    data = $("#new_member").serializeJSON();
    if ($('#new_member input[type="datetime-local"]').length) {
      $.each($('#new_member input[type="datetime-local"]'), function (input) {
        var inputName = $(this).attr("name");
        inputName = inputName.substring(
          inputName.lastIndexOf("[") + 1,
          inputName.lastIndexOf("]")
        );
        data.member.extra_data[inputName] = moment(
          data.member.extra_data[inputName]
        ).format("MMM DD, YYYY h:mm A");
      });
    }

    $.ajax({
      url: form.attr("action"),
      method: "POST",
      data: data,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    });
  };

  // $(document).on('change', 'input[name="show-payment-details"]', function(event) {
  //   controller.options.showPaymentDetails = $(this).is(':checked');
  //   if(controller.options.showPaymentDetails) {
  //     $('.paymentInfo').slideDown();
  //   } else {
  //     $('.paymentInfo').slideUp();
  //   }
  // });

  $(document).on("click", ".firstStep button, #mobileJoinButton", function (e) {
    $(".join .firstStep").fadeOut("slow", function () {
      $(".join .joinForm").fadeIn();
      $("#member_email").focus();

      //if(isJoinFormReadyToJoin()) {
      //  $('.join .joinForm button').click();
      //}
    });
  });

  $(document).on("click", ".toggleEmbed", function () {
    $(this).closest(".Modal").removeClass("Modal--show");
  });

  $(document).on("click", "#open-check-your-spot-modal", function () {
    $("#check-your-spot-modal").toggleClass("Modal--show");
  });

  $(document).on("click", "#check-your-spot", function () {
    ajaxize({
      // url: '/lists/' + controller.list.id + '/members/position?login=' + $('input[name="login"]').val(),
      url:
        "/api/v1/lists/" +
        controller.list.id +
        "/memberships/find?login=" +
        $('input[name="login"]').val(),
      beforeSend: function () {},
      done: function (response) {
        $("#check-your-spot-modal").toggleClass("Modal--show");
        if (!!response) {
          if (response["still_on_list"]) {
            var membership = {
              id: response.id,
              memberId: response["user_id"],
              referralCode: response["referral_code"],
              position: response.position,
            };
            var waitlistShare = new WaitlistShare(
              controller.list,
              membership,
              new WaitlistrModal($("#share-to-email-modal"))
            );
            window.waitlistShare = waitlistShare;

            $("#your-spot-modal #member-position-msg").text(
              "You're #" + membership.position + " on the list"
            );
            $("#your-spot-modal #member-position-msg").show();
            $("#your-spot-modal #not-member-msg").hide();

            var joinedDate = moment(response["entered_at"])
              .utc()
              .format("MMM DD YYYY - HH:mm");
            var pointsArray = response.member_points.map(function (m) {
              return (
                "+" +
                m.quantity +
                " " +
                m.description.toLowerCase() +
                (m.quantity > 1 ? "s" : "")
              );
            });
            var pointsSum = response.member_points
              .map(function (mp) {
                return mp.quantity;
              })
              .reduce(function (a, b) {
                return a + b;
              });

            $("#member-points").html(
              $.parseHTML(
                joinedDate +
                  "<br>" +
                  pointsSum +
                  (pointsSum > 1 ? " points" : " point") +
                  " (" +
                  pointsArray.join(", ") +
                  ")"
              )
            );

            if ($("#your-spot-modal .shareIcons").length > 0) {
              $("#your-spot-modal .shareIcons").html(
                $("#your-spot-modal .shareIcons")
                  .html()
                  .replace("membership.referral_code", membership.referralCode)
              );
              $("#your-spot-modal #listShareUrl")
                .parent()
                .html(
                  $("#your-spot-modal #listShareUrl")
                    .parent()
                    .html()
                    .replace(
                      "membership.referral_code",
                      membership.referralCode
                    )
                );
              $("#your-spot-modal .shareIcons").show();
            }
          } else {
            $("#your-spot-modal #member-position-msg").hide();
            var message =
              "You were on this list. Please log into your account for details.";
            $("#your-spot-modal #not-member-msg").text(message);
            $("#your-spot-modal #not-member-msg").show();
            $("#your-spot-modal .share-icons").hide();
          }
        } else {
          $("#your-spot-modal #member-position-msg").hide();
          var message = "You're not a member of the list";
          $("#your-spot-modal #not-member-msg").text(message);
          $("#your-spot-modal #not-member-msg").show();
          $("#your-spot-modal .share-icons").hide();
        }

        $("#your-spot-modal").toggleClass("Modal--show");
      },
    });
  });

  function isJoinFormReadyToJoin() {
    if (controller.options.accept_payments) {
      return false;
    }
    var validator = $("#new_member").validate();
    var valid = validator.checkForm();
    validator.submitted = {};
    return valid;
  }

  function setUpStripeForm() {
    if (!controller.options.accept_payments) {
      return false;
    }

    const stripeElements = controller.stripe.elements();

    controller.cardNumber = stripeElements.create("cardNumber");
    controller.cardNumber.mount("#card-number");
    controller.cardExpiry = stripeElements.create("cardExpiry");
    controller.cardExpiry.mount("#card-expiry");
    controller.cardCvc = stripeElements.create("cardCvc");
    controller.cardCvc.mount("#card-cvc");
    controller.postalCode = stripeElements.create("postalCode");
    controller.postalCode.mount("#postal-code");
  }

  function setUpNewMemberValidation() {
    controller.newMemberFormValidator = $("form#new_member").validate({
      errorPlacement: function (errorMap, errorList) {},
      onsubmit: true,
      rules: {
        "member[email]": {
          required: true,
        },
        "member[username]": {
          required: true,
        },
        "member[name]": {
          required: true,
        },
        "member[phone_number]": {
          required: true,
        },
        "member[address][street]": {
          required: true,
        },
        "member[address][city]": {
          required: true,
        },
        "member[address][state]": {
          required: true,
        },
        "member[address][postal_code]": {
          required: true,
        },
      },
      highlight: function (element) {
        $(element).addClass("Error");
      },
      unhighlight: function (element) {
        $(element).removeClass("Error");
      },
    });
  }
}

module.exports = JoinListFormController;
