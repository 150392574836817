import { UnfoldMore as UnfoldMoreIcon } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import React from 'react';
import {
  ActionsCell,
  EntriesCell,
  ExpandCell,
  JoinDateCell,
  PaymentCell,
  PointsCell,
  PositionCell,
  SelectCell,
  UsernameCell,
} from './Cell';

const RightAlignedCell = ({ children }) => (
  <Box sx={{ textAlign: 'right' }}>{children}</Box>
);

const CenterAlignedCell = ({ children }) => (
  <Box sx={{ textAlign: 'center' }}>{children}</Box>
);

const selectOptions = () => ({
  accessor: 'select',
  Header: ({ getToggleAllRowsSelectedProps }) => (
    <Checkbox
      inputProps={{ 'aria-label': 'select all' }}
      {...getToggleAllRowsSelectedProps()}
    />
  ),
  Cell: ({ cell, row }) => (
    <SelectCell
      memberId={row.original.id}
      {...cell.getCellProps()}
      checkBoxProps={row.getToggleRowSelectedProps()}
    />
  ),
  disableSortBy: true,
});

const expandOptions = () => ({
  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    <Tooltip arrow title="Expand all rows">
      <Box>
        <IconButton
          size="small"
          aria-label="expand all"
          {...getToggleAllRowsExpandedProps()}
        >
          <UnfoldMoreIcon />
        </IconButton>
      </Box>
    </Tooltip>
  ),
  Cell: ({ cell, row }) => (
    <ExpandCell
      expanded={row.isExpanded}
      memberId={row.original.id}
      {...cell.getCellProps()}
      buttonProps={row.getToggleRowExpandedProps()}
    />
  ),
  accessor: 'expand',
  disableSortBy: true,
});

const entriesOptions = () => ({
  Header: ({ column }) => (
    <RightAlignedCell>
      <SortableIfAvailable column={column}>#</SortableIfAvailable>
    </RightAlignedCell>
  ),
  accessor: 'entries_count',
  Cell: ({ value, cell, row }) => (
    <EntriesCell
      entries={value}
      onClick={row.getToggleRowExpandedProps().onClick}
      {...cell.getCellProps()}
    />
  ),
});

const positionOptions = () => ({
  Header: ({ column }) => (
    <RightAlignedCell>
      <SortableIfAvailable column={column}>#</SortableIfAvailable>
    </RightAlignedCell>
  ),
  accessor: 'position',
  Cell: ({ value, cell, row }) => (
    <PositionCell
      position={value}
      onClick={row.getToggleRowExpandedProps().onClick}
      {...cell.getCellProps()}
    />
  ),
});

const usernameOptions = () => ({
  Header: ({ column }) => (
    <SortableIfAvailable column={column}>Members</SortableIfAvailable>
  ),
  accessor: 'user.username',
  Cell: ({ value, cell, row }) => (
    <UsernameCell
      username={value}
      onClick={row.getToggleRowExpandedProps().onClick}
      {...cell.getCellProps()}
    />
  ),
});

const joinDateOptions = () => ({
  Header: ({ column }) => (
    <RightAlignedCell>
      <SortableIfAvailable column={column}>Joined</SortableIfAvailable>
    </RightAlignedCell>
  ),
  accessor: 'entered_at',
  Cell: ({ value, cell, row }) => (
    <JoinDateCell
      date={value}
      onClick={row.getToggleRowExpandedProps().onClick}
      {...cell.getCellProps()}
    />
  ),
});

const pointsOptions = () => ({
  Header: ({ column }) => (
    <CenterAlignedCell>
      <SortableIfAvailable column={column}>Points</SortableIfAvailable>
    </CenterAlignedCell>
  ),
  accessor: 'score',
  Cell: ({ value, cell, row }) => (
    <PointsCell
      points={row.original.member_points}
      score={value}
      {...cell.getCellProps()}
    />
  ),
});

const SortableIfAvailable = ({ column, children }) => {
  if (!column.canSort) {
    return children;
  }
  return (
    <TableSortLabel
      active={column.isSorted}
      direction={column.isSortedDesc ? 'desc' : 'asc'}
      onClick={() => {
        column.toggleSortBy(!column.isSortedDesc);
      }}
    >
      {children}
    </TableSortLabel>
  );
};

const paymentOptions = ({ onPaymentClick, onInvoiceClick }) => ({
  Header: ({ column }) => (
    <RightAlignedCell>
      <SortableIfAvailable column={column}>Payment</SortableIfAvailable>
    </RightAlignedCell>
  ),
  accessor: 'payment.amount',
  Cell: ({ value, cell, row }) => (
    <PaymentCell
      payment={row.original.billing_payment}
      invoice={row.original.billing_invoice}
      onPaymentClick={() => {
        onPaymentClick(row.original);
      }}
      onInvoiceClick={() => {
        onInvoiceClick(row.original);
      }}
      {...cell.getCellProps()}
    />
  ),
});

const actionsOptions = ({
  onPaymentClick,
  onNewInvoiceClick,
  onInvoiceClick,
  onRemoveClick,
  onDisqualifyClick,
  includeDisqualify,
  includeRemove,
}) => ({
  Header: ({ column }) => (
    <RightAlignedCell>
      <SortableIfAvailable column={column}>Actions</SortableIfAvailable>
    </RightAlignedCell>
  ),
  accessor: 'actions',
  disableSortBy: true,
  Cell: ({ cell, row }) => (
    <ActionsCell
      member={row.original}
      {...cell.getCellProps()}
      onPaymentClick={() => {
        onPaymentClick(row.original);
      }}
      onInvoiceClick={() => {
        onInvoiceClick(row.original);
      }}
      onNewInvoiceClick={() => {
        onNewInvoiceClick([row.original]);
      }}
      onRemoveClick={() => {
        onRemoveClick(row.original);
      }}
      onDisqualifyClick={() => {
        onDisqualifyClick(row.original);
      }}
      includeDisqualify={includeDisqualify}
      includeRemove={includeRemove}
    />
  ),
});

export const buildColumns = (waitlist, options = {}) => {
  if (!waitlist) {
    return [];
  }
  let skip = options?.skip || [];

  options.actions.includeDisqualify =
    waitlist.list_type === 'giveaway' && !options.skip.includes('disqualify');
  options.actions.includeRemove = !options.skip.includes('remove');

  return [
    waitlist.status === 'archived' || skip.includes('select')
      ? null
      : selectOptions(),
    expandOptions(),
    waitlist.list_type === 'giveaway' ? entriesOptions() : positionOptions(),
    usernameOptions(),
    joinDateOptions(),
    pointsOptions(),
    paymentOptions({
      onPaymentClick: options.actions?.onPaymentClick,
      onInvoiceClick: options.actions?.onInvoiceClick,
    }),
    waitlist.status === 'archived' ? null : actionsOptions(options.actions),
  ].filter((c) => !!c);
};
