function FormMessages(form) {
  this.form = form;

  this.addError = function (errorMessage) {
    errorList = this.form.find("ul.Error");
    if (errorList.length == 0) {
      errorList = $('<ul class="Error"></ul>');
      this.form.prepend(errorList);
    }

    var errorHash = this.hashCode(errorMessage);
    if (
      errorList.find('li span[data-error-key="' + errorHash + '"]').length > 0
    ) {
      return;
    }
    var listItemHtml =
      '<li><span class="error-message" data-error-key="' +
      errorHash +
      '">' +
      errorMessage +
      "</span></li>";
    errorList.append(listItemHtml);
  };

  this.scrollToMessages = function () {
    $("html,body").animate(
      {
        scrollTop: this.form.find(".Error").offset().top,
      },
      "slow"
    );
  };

  this.clear = function () {
    errorList = this.form.find("ul.Error");
    if (errorList.length == 0) {
      errorList = $('<ul class="Error"></ul>');
      this.form.prepend(errorList);
    }

    errorList.find("li").remove();
  };

  this.removeError = function (errorMessage) {
    var errorHash = this.hashCode(errorMessage);
    errorList = this.form.find("ul.Error");
    if (errorList.length > 0) {
      errorList
        .find('li span[data-error-key="' + errorHash + '"]')
        .parent("li")
        .remove();
    }
  };

  this.hashCode = function (str) {
    var hash = 0;
    if (str.length == 0) return hash;
    for (i = 0; i < str.length; i++) {
      char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };
}

global.FormMessages = FormMessages;
