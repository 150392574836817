function ListsController(query) {
  listsController = this;
  this.query = query;
  this.pagination = new Pagination();

  $(".filterListLoader").hide();

  $(document).on("click", "#viewFilters .Nav-item", function (e) {
    $(this).closest("#viewFilters").find(".Nav-item").removeClass("Active");
    $(this).addClass("Active");
  });

  $(document).on("click", ".Card .Card-favorite", function (e) {
    var id = $(this).closest(".Card").data("id");

    var button = this;

    var favoriteFunction = function () {
      var url = "/waitlists/favorites";
      var method = "POST";
      var data = {
        favorite_waitlist: {
          waitlist_id: id,
        },
      };

      if ($(button).hasClass("selected")) {
        method = "DELETE";
        var url = "/waitlists/favorites/remove";
      }

      $.ajax({
        url: url,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        method: method,
        data: data,
      });
    };

    if (!!localStorage.getItem("authentication_token")) {
      favoriteFunction.call();
    } else {
      listsController.afterSignInFunction = favoriteFunction;
      $("#signInModal").addClass("Modal--show");
    }

    e.preventDefault();
    return false;
  });

  $(document).on("ajax:success", "#new_user", function (event) {
    $("#signInModal .closeModal").click();
    listsController.afterSignInFunction.call();
  });

  $(document).on("ajax:error", "#new_user", function (event) {
    const { status, error, redirect_to } = event.detail[0];

    if (status === 302) {
      window.location = redirect_to;
    } else {
      $(".login .error-message").text(error);
      $(".login #user_login").addClass("Error");
      $(".login #user_password").addClass("Error");
    }
  });

  $(document).on("click", "#order-recent", function () {
    $('input[name="order"]').val("recent");
    var orderBy = $('input[name="order"]').val();
    order(orderBy);
  });

  $(document).on("click", "#order-popular", function () {
    $('input[name="order"]').val("popular");
    var orderBy = $('input[name="order"]').val();
    order(orderBy);
  });

  var win = $(window);

  win.scroll(function () {
    if ($(document).height() - win.height() == win.scrollTop()) {
      if (!listsController.pagination.hasNextPages) {
        return;
      }
      if (listsController.pagination.searching) {
        return;
      }

      $(".filterListLoader").show();

      listsController.pagination.incCurrentPage();

      listsController.pagination.searching = true;
      var vars = window.location.search.substring(1).split("&");
      var searchParams = {};
      for (var i = 0; i < vars.length; i++) {
        searchParams[vars[i].split("=")[0]] = vars[i].split("=")[1];
      }

      $.ajax({
        url: "/lists/paginate",
        data: $.extend(searchParams, {
          page: listsController.pagination.currentPage,
          order: $('input[name="order"]').val(),
        }),
        complete: function (data) {
          listsController.pagination.searching = false;
          $(".filterListLoader").hide();
        },
      });
    }
  });

  order = function (orderBy) {
    $(".filterListLoader").show();
    listsController.pagination.setCurrentPage(1);

    $.ajax({
      url: "/lists/filter",
      data: {
        page: listsController.pagination.currentPage,
        order: orderBy,
        query: listsController.query,
      },
      complete: function (data) {
        $(".filterListLoader").hide();
      },
    });
  };
}

module.exports = ListsController;
