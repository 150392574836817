function IndexController() {
  const controller = this;

  $(document).on("click", ".Card .Card-favorite", function (e) {
    var id = $(this).closest(".Card").data("id");

    var button = this;

    var favoriteFunction = function () {
      var url = "/waitlists/favorites";
      var method = "POST";
      var data = {
        favorite_waitlist: {
          waitlist_id: id,
        },
      };

      if ($(button).hasClass("selected")) {
        method = "DELETE";
        var url = "/waitlists/favorites/remove";
      }

      $.ajax({
        url: url,
        method: method,
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: data,
      });
    };

    if (!!localStorage.getItem("authentication_token")) {
      favoriteFunction.call();
    } else {
      controller.afterSignInFunction = favoriteFunction;
      $("#signInModal").addClass("Modal--show");
    }

    e.preventDefault();
    return false;
  });

  $(document).on("ajax:success", "#new_user", function (event) {
    $("#signInModal .closeModal").click();
    controller.afterSignInFunction.call();
  });

  $(document).on("ajax:error", "#new_user", function (event) {
    const { status, error, redirect_to } = event.detail[0];

    if (status === 302) {
      window.location = redirect_to;
    } else {
      $(".login .error-message").text(error);
      $(".login #user_login").addClass("Error");
      $(".login #user_password").addClass("Error");
    }
  });
}

module.exports = IndexController;
