import { useGetWaitlist } from '@fetch-components/waitlists';
import { Container } from '@mui/material';
import React from 'react';
import { withPage } from '../../Page';
import StyledManagePage from './ManagePage.style';
import { ManagePageHeader } from './ManagePageHeader';
import Members from './Members';
import { ModalProvider } from '@contexts/ModalContext';

const ManagePage = (props) => {
  const { isLoading, isError, data } = useGetWaitlist({ id: props.id });

  return (
    <ModalProvider>
      <StyledManagePage>
        <Container maxWidth="lg" sx={{ py: [5, 10] }}>
          <ManagePageHeader isLoading={isLoading} waitlist={data?.waitlist} />
          {data?.waitlist && <Members waitlist={data.waitlist} />}
        </Container>
      </StyledManagePage>
    </ModalProvider>
  );
};

export default withPage(ManagePage);
