import { useQuery } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

export const useGetCurrentUser = () => {
  return useQuery('current-user', () =>
    fetch(`${API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};
