/**
 * Filter categories based on the waitlist title or description
 * @param categories the list of categories to be filtered
 * @param waitlist an object representing the waitlist, it should
 *                 contain attributes to be used as input to the
 *                 filter algorithm
 * @return up to four filtered categories
 **/
export const filterCategoriesByKeywords = (categories, waitlist) => {
  const { title, description } = waitlist;

  if (!title && !description) {
    return [];
  }

  let titleTokens = [];

  if (title) {
    titleTokens = title.toLowerCase().trim().split(" ");
  }

  let descriptionTokens = [];

  if (description) {
    descriptionTokens = description
      .toLowerCase()
      .replace("\n", " ")
      .split(" ")
      .map((t) => t.trim());
  }

  const filteredCategories = categories
    .filter((category) => {
      const categoryName = category.name.toLowerCase().trim();

      if (
        titleTokens.includes(categoryName) ||
        descriptionTokens.includes(categoryName)
      ) {
        return true;
      }

      const keywords = category.keywords.map((kw) => kw.toLowerCase().trim());

      return keywords.some(
        (kw) => titleTokens.includes(kw) || descriptionTokens.includes(kw)
      );
    })
    .map((category) => ({ ...category, match: true }));

  const limit = 4;
  const matches = filteredCategories.length;

  if (matches < limit) {
    return filteredCategories.concat(
      categories
        .filter((c) => !filteredCategories.includes(c))
        .slice(0, limit - matches)
    );
  }

  return filteredCategories;
};
