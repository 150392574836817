const Pagination = function () {
  this.searching = false;
  this.hasNextPages = true;
  this.currentPage = 1;
};

Pagination.prototype.incCurrentPage = function () {
  this.currentPage = this.currentPage + 1;
};

Pagination.prototype.setCurrentPage = function (page) {
  this.currentPage = parseInt(page);
};

Pagination.prototype.setHasNextPages = function (hasNextPages) {
  this.hasNextPages = hasNextPages === "true";
};

Pagination.prototype.isSearching = function () {
  return this.searching;
};

global.Pagination = Pagination;
