class ChatRoomsController {
  constructor() {
    this.pagination = new Pagination();

    $('.paginateRoomsLoader').hide();
    let roomId = $('.Chat-messages.chatRoom').data('room-id');
    this.subscribe(roomId);

    var controller = this;
    var win = $('.rooms .Chat-rooms--wrap');

    $(document).on('click', '#msgSubmit', (e) => {
      var msg = $('#chatMessage').val();

      if (msg) {
        this.postMessage(this.formatMessage($('#chatMessage').val()));
      }
    });

    win.scroll(function () {
      if (
        $(this).scrollTop() + $(this).innerHeight() >=
        $(this)[0].scrollHeight
      ) {
        if (!controller.pagination.hasNextPages) {
          return;
        }
        if (controller.pagination.searching) {
          return;
        }

        $('.paginateRoomsLoader').show();

        controller.pagination.incCurrentPage();

        controller.pagination.searching = true;
        $.ajax({
          url: '/chat/rooms/paginate',
          data: {
            page: controller.pagination.currentPage,
          },
          complete: function (data) {
            controller.pagination.searching = false;
            $('.paginateRoomsLoader').hide();
          },
        });
      }
    });
  }

  messageAdded(message) {
    if ($('.roomMessage[data-id="' + message.id + '"]').length == 0) {
      let AUTH_TOKEN = localStorage.getItem('authentication_token');
      let csrfToken = $('meta[name="csrf-token"]').attr('content');

      let url =
        '/chat/messages/' +
        message.id +
        '/message_added?authenticity_token=' +
        AUTH_TOKEN;

      // fetch(url, {
      //   method: "GET",
      //   headers: {
      //     "X-Requested-With": "XMLHttpRequest",
      //     "X-CSRF-Token": csrfToken,
      //     "Content-Type": "text/javascript"
      //   }
      // });

      $.ajax({
        url: url,
        method: 'GET',
        dataType: 'script',
        data: {},
      });
    }
  }

  subscribe(roomId) {
    let controller = this;
    let pubnub = new PubNub({
      publishKey: localStorage.getItem('pubnubPublishKey'),
      subscribeKey: localStorage.getItem('pubnubSubscribeKey'),
    });

    pubnub.addListener({
      message: function (m) {
        controller.messageAdded(m.message);
      },
    });

    pubnub.subscribe({
      channels: ['chat_room_' + roomId],
    });
  }

  formatMessage(message) {
    message = message.replace(/\n/g, '<br>');

    return message;
  }

  postMessage(message) {
    $('#postMessageLoader').show();
    let roomId = $('.Chat-messages.chatRoom').data('room-id');
    $.ajax({
      url: '/chat/rooms/' + roomId + '/messages',
      method: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      dataType: 'script',
      data: {
        message: {
          content: message,
        },
      },
    });
  }
}

module.exports = ChatRoomsController;
