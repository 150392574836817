import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

export const AvatarStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 126,
  height: 126,
  'img': {
    objectFit: 'cover',
    width: '100%',
    height: 126,
  }
}));

export const AvatarUploaderStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  width: 126,
  height: 126,
  borderRadius: 126,
  textAlign: 'center',
  transition: 'all 0.5s',
  borderSize: 2,
  borderStyle: 'dashed',
  borderColor: theme.palette.grey.medium,
  '.MuiFormLabel-root': {
    width: 120,
    height: 120,
    position: 'absolute',
    top: 0,
    left: 0
  },
  '.MuiIconButton-root': {
    width: 120,
    height: 120
  }
}));

export const AvatarEditIconStyled = styled(IconButton)(({ theme }) => ({
  opacity: 0,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0.25)'
  }
}));
