$(function () {
  // JS for main nav
  // mobile menu toggeling
  $("#navToggle").click(function () {
    $(this).siblings("#navMenu").toggleClass("showNav");
  });
  $("#navClose").click(function () {
    $(this).closest("#navMenu").toggleClass("showNav");
  });
  // hover nav
  $("#dropNavToggle").hover(function () {
    $(this).toggleClass("showDropNav");
  });

  // global method for toggeling
  $(".navToggle").click(function () {
    $(this).siblings(".navMenu").toggleClass("showNav");
  });

  // toggle between tabs
  // be sure to tag content w/ .tabContent
  $(".Nav-tablist .Nav-item").click(function () {
    var tab_id = $(this).attr("data-tab");

    $("#waitlistNav .Nav-item, .toggleNav .Nav-item").removeClass("Active");
    $(".tabContent").hide();

    $(this).addClass("Active");
    $("." + tab_id).show();
  });

  // use a link to trigger a click on a tab
  $(".toggleNav .navLink").click(function () {
    var tab_id = $(this).attr("data-clickTab");

    $("." + tab_id).click();
  });

  // show a modal
  // add .showModal to click target

  // and data-modal="modalID"

  $(document).on("click", ".showModal", function () {
    var modal_id = $(this).attr("data-modal");

    $("#" + modal_id).addClass("Modal--show");
  });

  // close a modal
  // add .closeModal to click target
  $(document).on("click", ".closeModal", function () {
    $(this).closest(".Modal, .Card-modal").removeClass("Modal--show");
  });

  // hide floating modal
  // on click outside
  $(document).mouseup(function (e) {
    if ($(e.target).closest(".Modal--floating .Modal-body").length === 0) {
      $(".Modal--floating").removeClass("Modal--show");
      $("body").removeClass("u-no_scroll");
    }
  });

  // Toggle a set with header and body
  $(".toggleSetHeader").click(function () {
    $(this).siblings(".toggleSetBody").slideToggle();
    $(".toggleSetArrow", this).toggleClass("u-rotate--90");
  });

  // switch layouts from grid to list views
  $(document).on("click", "#gridView", function () {
    $("#layoutView").addClass("grid");
    $("#layoutView").removeClass("list");
  });
  $(document).on("click", "#listView", function () {
    $("#layoutView").addClass("list");
    $("#layoutView").removeClass("grid");
  });

  // add auto size to all textareas
  autosize($("textarea"));

  // add smooth scrolling to all pages
  smoothScroll.init();
});

// check if an element is in the viewport
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};
