function EditBankingController(stripe) {
  var controller = this;
  this.stripe = stripe;
  this.form = $("form#banking-form");
  formMessages = new FormMessages(this.form);
  this.creditCardForm = $("form#credit_card_form");
  creditCardFormMessages = new FormMessages(this.creditCardForm);
  this.submitButtonLoader = new Loader(".submitButtonLoader");
  this.submitButtonLoader.hide();

  setUpStripeForm();
  setUpBankingFormValidation();

  function setUpStripeForm() {
    if ($("#card-number").length) {
      const stripeElements = controller.stripe.elements();

      controller.cardNumber = stripeElements.create("cardNumber");
      controller.cardNumber.mount("#card-number");
      controller.cardExpiry = stripeElements.create("cardExpiry");
      controller.cardExpiry.mount("#card-expiry");
      controller.cardCvc = stripeElements.create("cardCvc");
      controller.cardCvc.mount("#card-cvc");
      controller.postalCode = stripeElements.create("postalCode");
      controller.postalCode.mount("#postal-code");
    }
  }

  function setUpBankingFormValidation() {
    $("#banking-form").validate({
      errorPlacement: function (error, element) {
        formMessages.addError($(error).text());
      },
      onsubmit: true,
      onkeyup: false,
      invalidHandler: function (event, validator) {
        formMessages.clear();
      },
      rules: {
        "banking_settings[bank_account_full_name]": {
          required: true,
        },
        "banking_settings[bank_account_routing_number]": {
          required: true,
        },
        "banking_settings[bank_account_number]": {
          required: true,
        },
        "banking_settings[profile_country_id]": {
          required: true,
        },
        "banking_settings[profile_first_name]": {
          required: true,
        },
        "banking_settings[profile_last_name]": {
          required: true,
        },
        "banking_settings[profile_date_of_birth]": {
          required: true,
        },
        "banking_settings[profile_address]": {
          required: true,
        },
        "banking_settings[profile_city]": {
          required: true,
        },
        "banking_settings[profile_state]": {
          required: true,
        },
        "banking_settings[profile_postal_code]": {
          required: true,
        },
      },
      messages: {
        "banking_settings[bank_account_full_name]": {
          required: "Bank Account Full Name required",
        },
        "banking_settings[bank_account_routing_number]": {
          required: "Bank Account Routing Number required",
        },
        "banking_settings[bank_account_number]": {
          required: "Bank Account Number required",
        },
        "banking_settings[profile_country_id]": {
          required: "Country required",
        },
        "banking_settings[profile_first_name]": {
          required: "First Name required",
        },
        "banking_settings[profile_last_name]": {
          required: "Last Name required",
        },
        "banking_settings[profile_date_of_birth]": {
          required: "Date of Birth required",
        },
        "banking_settings[profile_address]": {
          required: "Address required",
        },
        "banking_settings[profile_city]": {
          required: "City required",
        },
        "banking_settings[profile_state]": {
          required: "State required",
        },
        "banking_settings[profile_postal_code]": {
          required: "Postal Code required",
        },
      },
      highlight: function (element) {
        $(element).addClass("Error");
      },
      unhighlight: function (element, error, valid, _orig) {
        $(element).removeClass("Error");
      },
    });
  }

  $("#select-country-button").on("click", function () {
    if (!!$("#account_country").val()) {
      document.location.search = "country=" + $("#account_country").val();
    } else {
      $("#account_country").addClass("Error");
    }
  });

  $("form#credit_card_form").on("submit", function (event) {
    event.preventDefault();

    controller.stripe
      .createToken(controller.cardNumber)
      .then(function (response) {
        if (response.error) {
          const submitButton = controller.creditCardForm.find(
            'button[type="submit"]'
          );

          creditCardFormMessages.addError(response.error.message);
          creditCardFormMessages.scrollToMessages();
          submitButton.removeAttr("disabled");

          return false;
        }

        $("#billing_credit_card_last_four_numbers").val(
          response.token.card.last4
        );
        $("#billing_credit_card_token").val(response.token.id);

        controller.createCreditCard();
      });

    return false;
  });

  this.createCreditCard = function () {
    controller.submitButtonLoader.show();
    controller.creditCardForm
      .find('button[type="submit"]')
      .attr("disabled", true);

    var data = {
      billing_credit_card: {
        token: $('input[name="billing_credit_card[token]"]').val(),
        holder_name: $('input[name="billing_credit_card[holder_name]"]').val(),
        last_four_numbers: $(
          'input[name="billing_credit_card[last_four_numbers]"]'
        ).val(),
      },
    };

    $.ajax({
      url: controller.creditCardForm.attr("action"),
      method: controller.creditCardForm.attr("method"),
      data: data,
    });
  };
}

module.exports = EditBankingController;
