function ListManageController(params) {
  var controller = this;
  window.ola = controller;
  this.removeMembersModal = new WaitlistrModal(
    $("#removeSelectedMembersModal")
  );
  this.removeMembersModalLoader = new Loader(
    $("#removeSelectedMembersModal .Loader")
  );
  this.emailMembersModal = new WaitlistrModal($("#emailSelectedMembersModal"));
  this.emailMembersModalLoader = new Loader(
    $("#emailSelectedMembersModal .Loader")
  );

  this.listId = params.listId;
  this.listSlug = params.listSlug;
  this.ownerSlug = params.ownerSlug;
  this.scope = params.scope || "current-members";
  this.perPage = $("#per_page").val();
  this.currentPage = 1;
  this.waitlistMemberLoaders = {};

  this.searchMembersLoader = new Loader($("#search-members-loader"));
  this.pickWinnerLoader = new Loader($(".pickWinnerLoader"));

  $("form#importMembersForm").validate({
    errorPlacement: function (errorMap, errorList) {},
    onsubmit: true,
    rules: {
      file: {
        required: true,
      },
    },
    highlight: function (element) {
      $(element).addClass("Error");
    },
    unhighlight: function (element) {
      $(element).removeClass("Error");
    },
  });

  $(document).on("click", "#current-members-link", function () {
    filterCurrentMembers();
    return false;
  });

  $(document).on("click", "#create-export-link", function () {
    $(this).find(".Loader").show();
  });

  $(document).on("click", "#importLink", function () {
    $(".importContent").slideDown();
    return false;
  });

  $(document).on("click", "#pickWinnerButton", function () {
    pickWinner();
    return false;
  });

  $(document).on("click", ".refundButton", function (e) {
    $(this).find(".Loader").show();
  });

  $(document).on("click", ".pagination .page a", function () {
    controller.currentPage = $(this).text();
  });

  $(document).on("ajax:error", "form.edit_billing_payment", function () {
    $("form.edit_billing_payment .updateBillingPaymentLoader").hide();
    $("form.edit_billing_payment .ErrorMessage").text(
      "Error while saving payment status!"
    );
    $("form.edit_billing_payment .ErrorMessage").fadeIn(500);
    setTimeout(function () {
      $("form.edit_billing_payment .ErrorMessage").fadeOut(1500, function () {
        $("form.edit_billing_payment .ErrorMessage").text("");
      });
    }, 3000);
  });

  $(document).on("submit", "form.edit_billing_payment", function () {
    $(this).find(".updateBillingPaymentLoader").show();
  });

  $(document).on("change", "form.edit_billing_payment select", function () {
    $(this).parent("form.edit_billing_payment").submit();
  });

  // $(document).on('click', ".refundRequestButton", function() {
  //   $(this).find('.Loader').show();
  // });

  $(document).on("change", "#per_page", function () {
    // controller.perPage = $(this).val();
    // filterMembers({ scope: controller.scope });

    var query = document.location.search;
    query =
      "?scope=" +
      controller.scope +
      "&members_page=" +
      controller.currentPage +
      "&members_per_page=" +
      $(this).val();
    document.location.search = query;
    return false;
  });

  $(document).on("click", "#removeSelectedMembersButton", function (e) {
    removeSelectedMembers();

    e.preventDefault();
    return false;
  });

  $(document).on(
    "click",
    '#selectAllMembers input[type="checkbox"]',
    function () {
      $(".selectMemberCheckbox").attr("checked", this.checked);
    }
  );

  $(document).on("click", ".removeSelected", function (e) {
    if ($(".selectMemberCheckbox:checked").length > 0) {
      $("#selectedMembersCount").text(
        $(".selectMemberCheckbox:checked").length
      );
      controller.removeMembersModal.show();
    }
  });

  $(document).on("click", ".emailSelected", function (e) {
    var checked = $(".selectMemberCheckbox:checked");
    $("#emailMembersCount").text(checked.length);
    $("#emailAllUsersInput").val("no");
    var emails = checked.map(function () {
      return $(this).parents(".membershipTableItem").attr("data-member-email");
    });

    if (emails.length > 0) {
      $("#selectedMembersCount").text(emails.length);
      $("#emailListInput").val(Array.from(emails).join(","));

      $("#emailSubject").val("");
      $("#emailContent").val("");
      $("#emailErrorWrapper").hide();
      $("#emailSuccess").hide();
      controller.emailMembersModal.show();
    }
  });

  $(document).on("click", ".emailEntireList", function (e) {
    $("#emailMembersCount").text($(this).attr("data-members-count"));
    $("#emailAllUsersInput").val("yes");
    controller.emailMembersModal.show();
  });

  $(document).on(
    "click",
    ".cancelMemberInvoiceModal .actionButton",
    function () {
      $(this).attr("disabled", true);
      $(this).find(".Loader").show();
    }
  );

  $(document).on("click", ".invoiceButton", function () {
    $(this).find(".Loader").show();
  });

  $(document).on("click", ".cancelMemberInvoice", function () {
    var cancelMemberInvoiceModal = new WaitlistrModal(
      $(this).parents(".memberInvoice").find(".cancelMemberInvoiceModal")
    );
    cancelMemberInvoiceModal.show();
  });

  $(document).on("click", ".invoiceSelected", function (e) {
    if ($(".selectMemberCheckbox:checked").length > 0) {
      var usernames = [];
      $(".selectMemberCheckbox:checked").each(function () {
        usernames.push(
          $(this).parents(".membershipTableItem").find(".username").text()
        );
      });
      $(this).find(".Loader").show();
      $.ajax({
        url: "/billing/invoices/new",
        data: {
          waitlist_id: controller.listId,
          to: usernames,
        },
      });
    }
  });

  $(document).on("click", "#past-members-link", function () {
    filterPastMembers();
    return false;
  });

  function pickWinner() {
    controller.pickWinnerLoader.show();
    ajaxize({
      url: "/lists/" + controller.listId + "/pick_giveaway_winner",
      data: {},
      method: "POST",
      done: function (response) {
        controller.pickWinnerLoader.hide();
        filterMembers({ scope: controller.scope });
      },
      error: function () {
        controller.pickWinnerLoader.hide();
      },
    });
  }

  function removeSelectedMembers(memberIds) {
    controller.removeMembersModalLoader.show();
    ajaxize({
      url: "/lists/" + controller.listId + "/members/mass_remove",
      data: {
        ids: memberIds,
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      method: "DELETE",
      done: function (response) {
        // hideAllWaitlistMembersLoaders();
        // controller.removeMembersModal.hide();
        // controller.removeMembersModalLoader.hide();
        // // controller.waitlistMemberLoaders[response.data.id].hide();
        // // controller.removeMemberModals[response.data.id].hide();
        // filterMembers({ scope: controller.scope });
        window.location.href =
          "/lists/" +
          controller.ownerSlug +
          "/" +
          controller.listSlug +
          "/manage";
      },
      error: function () {
        hideAllWaitlistMembersLoaders();
        controller.removeMembersModalLoader.hide();
      },
    });
  }

  function changeScope(scope) {
    controller.scope = scope;

    if (scope === "current-members") {
      $("#past-members-link").removeClass("Active");
      $("#current-members-link").addClass("Active");
      $("#removeSelectedMembers").show();
      return;
    }

    if (scope === "past-members") {
      $("#past-members-link").addClass("Active");
      $("#current-members-link").removeClass("Active");
      $("#removeSelectedMembers").hide();
      return;
    }

    $("#current-members-link").removeClass("Active");
    $("#past-members-link").removeClass("Active");
  }

  changeScope(this.scope);

  $("#filter-form #submit-filter").on("click", function (event) {
    var elements = document.getElementById("filter-form").elements;
    var obj = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      obj[item.name] = item.value;
    }

    // filterMembers({ filter: obj });
  });

  $('input[name="search"]').bind("keyup", function (event) {
    if (event.keyCode == 13) {
      if ($(this).val().trim().length > 0) {
        filterMembers({ query: $(this).val(), scope: "" });
      } else {
        location.reload();
      }
    }
  });

  this.removeMemberModals = {};
  this.disqualifyMemberModals = {};

  setUpNoteHashCodes();

  $(document).on("rowAdded", "table", function () {
    setUpNoteHashCodes();
  });

  $(document).on("click", "#confirmSelectedMembersRemove", function (event) {
    var memberIds = [];
    $(".selectMemberCheckbox:checked")
      .parents("tr")
      .each(function (key, value) {
        memberIds.push($(value).data("member-id"));
      });
    // var memberIds = $('.selectMemberCheckbox:checked').parents('tr').map(function(e) {return $(this).data('member-id')});
    $(memberIds).each(function (key, memberId) {
      // var loaderElement = $('tr[data-id="' + membershipId + '"]').find('.Loader');
      // controller.waitlistMemberLoaders[membershipId] = new Loader(loaderElement);
      // controller.waitlistMemberLoaders[membershipId].show();
    });

    removeSelectedMembers(memberIds);
  });

  $(document).on("click", "#confirm-member-remove", function () {
    $(this).find(".removeMemberLoader").show();
  });

  $(document).on("click", "#remove-member-modal-link", function (event) {
    var membershipId = $(this).parents("tr").data("id");
    var modalElement = $(this).parents("tr").find("#remove-member-modal");
    controller.removeMemberModals[membershipId] = new WaitlistrModal(
      modalElement
    );
    controller.removeMemberModals[membershipId].show();

    // var membershipId = $(this).parents('tr').data('id');
    var loaderElement = $(this).parents("tr").find(".membershipLoader");
    controller.waitlistMemberLoaders[membershipId] = new Loader(loaderElement);
    controller.waitlistMemberLoaders[membershipId].show();
  });

  $(document).on(
    "ajax:success",
    "#remove-member-modal",
    function (event) {
      const [data, _status, _xhr] = event.detail;

      if (data.errors !== undefined && data.errors.length > 0) {
        showErrors(data.errors);
        hideAllWaitlistMembersLoaders();
        return;
      }
      controller.waitlistMemberLoaders[data.id].hide();
      controller.removeMemberModals[data.id].hide();
      filterCurrentMembers();
    }
  );

  $(document).on(
    "ajax:error",
    "#remove-member-modal",
    function (event, response) {
      alert("Error while removing member!");
    }
  );

  // disqualify

  $(document).on("click", "#disqualify-member-modal-link", function (event) {
    var membershipId = $(this).parents("tr").data("id");
    var modalElement = $(this).parents("tr").find("#disqualify-member-modal");
    controller.disqualifyMemberModals[membershipId] = new WaitlistrModal(
      modalElement
    );
    controller.disqualifyMemberModals[membershipId].show();

    // var membershipId = $(this).parents('tr').data('id');
    var loaderElement = $(this).parents("tr").find(".Loader");
    controller.waitlistMemberLoaders[membershipId] = new Loader(loaderElement);
    controller.waitlistMemberLoaders[membershipId].show();
  });

  $(document).on(
    "ajax:success",
    "#disqualify-member-modal",
    function (event) {
      const [data, _status, _xhr] = event.detail;
      if (data.errors !== undefined && data.errors.length > 0) {
        showErrors(data.errors);
        hideAllWaitlistMembersLoaders();
        return;
      }
      controller.waitlistMemberLoaders[data.id].hide();
      controller.disqualifyMemberModals[data.id].hide();
      filterMembers({ scope: controller.scope });
    }
  );

  $(document).on(
    "ajax:error",
    "#disqualify-member-modal",
    function (event, response) {
      alert("Error while disqualifing member!");
    }
  );

  $(document).on(
    "ajax:success",
    "form.updateNoteForm",
    function (event) {
      const [data, _status, _xhr] = event.detail;

      if (data.errors !== undefined && data.errors.length > 0) {
        showErrors(data.errors);
        hideAllWaitlistMembersLoaders();
        return;
      }
      var tableRow = $('tr[data-id="' + data.id + '"]');
      tableRow
        .find("textarea")
        .data("saved-note-hashcode", Encrypt.hashCode(data.note));

      successMessage = $('tr[data-id="' + data.id + '"]').find(
        ".SuccessMessage"
      );

      console.log(data.id);
      controller.waitlistMemberLoaders[data.id].hide();
      successMessage.text("Note saved!");
      successMessage.fadeIn(1500);

      setTimeout(function () {
        successMessage.fadeOut(1500, function () {
          successMessage.text("");
        });
      }, 3000);
    }
  );

  $(document).on("ajax:error", "form.updateNoteForm", function () {
    alert("Error while saving note!");
  });

  $(document).on(
    "focusout",
    "form textarea[name='membership[note]']",
    function () {
      var originalHashCode = $(this).data("saved-note-hashcode");
      var currentHashCode = Encrypt.hashCode($(this).val().trim());

      if (originalHashCode !== currentHashCode) {
        var membershipId = $(this).parents("tr").data("id");
        var loaderElement = $(this).parents("tr").find(".Loader");
        controller.waitlistMemberLoaders[membershipId] = new Loader(
          loaderElement
        );
        controller.waitlistMemberLoaders[membershipId].show();
        Rails.fire($(this).parent("form")[0], 'submit');
      }
    }
  );

  function setUpNoteHashCodes() {
    var textAreas = $("form #membership_note");
    textAreas.each(function (index) {
      textArea = textAreas[index];

      $(textArea).data(
        "saved-note-hashcode",
        Encrypt.hashCode($(this).val().trim())
      );
    });
  }

  function showErrors(errors) {
    alert(errors[0].title);
  }

  function hideAllWaitlistMembersLoaders() {
    var loaders = controller.waitlistMemberLoaders;
    for (var key in loaders) {
      loaders[key].hide();
    }
  }

  $(document).on("manage.filter-current-members", function (e) {
    filterCurrentMembers();
  });

  function filterCurrentMembers() {
    changeScope("current-members");

    controller.searchMembersLoader.show();
    ajaxize({
      method: "GET",
      url: "/lists/" + controller.listId + "/members/",
      data: {
        scope: controller.scope,
      },
      error: function () {
        console.log("Error");
      },
      done: function (response) {
        // $('#search-results-info').show();
        controller.searchMembersLoader.hide();
      },
    });
  }

  function filterPastMembers() {
    changeScope("past-members");

    controller.searchMembersLoader = new Loader($("#search-members-loader"));
    controller.searchMembersLoader.show();
    ajaxize({
      method: "GET",
      url: "/lists/" + controller.listId + "/members/",
      data: {
        scope: controller.scope,
      },
      error: function () {
        console.log("Error");
      },
      done: function (response) {
        // $('#search-results-info').show();
        controller.searchMembersLoader.hide();
      },
    });
  }

  function filterMembers(params) {
    if (params.query) {
      $("#search-results-info").text("Results for " + '"' + params.query + '"');
      $("#removeSelectedMembers").show();
    } else {
      $("#search-results-info").text("");
    }

    changeScope(params.scope);

    controller.searchMembersLoader.show();

    data = {
      scope: controller.scope,
      per: controller.perPage,
      page: controller.currentPage,
    };
    if (params.query !== undefined && params.query.trim().length > 0) {
      data["query"] = params.query;
    }

    url = "/lists/" + controller.listId + "/members/";

    ajaxize({
      method: "GET",
      url: url,
      data: data,
      error: function () {
        console.log("Error");
        controller.searchMembersLoader.hide();
      },
      done: function (response) {
        if ($("#search-results-info").text().trim().length > 0) {
          $("#search-results-info").show();
        }
        controller.searchMembersLoader.hide();
      },
    });
  }
}

Encrypt = {
  hashCode: function (str) {
    var hash = 0;
    if (str.length == 0) return hash;
    for (i = 0; i < str.length; i++) {
      char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  },
};

module.exports = ListManageController;
