import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { ModalCloseButton, ModalContent, ModalWrap } from './Modal.styled';

export const DefaultModal = ({
  children,
  open,
  onClose,
  ariaLabelledBy,
  ariaDescribedBy,
}) => {
  return (
    <ModalWrap
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      <ModalContent>
        <ModalCloseButton onClick={onClose}>
          <CloseIcon />
        </ModalCloseButton>
        {children}
      </ModalContent>
    </ModalWrap>
  );
};

DefaultModal.defaultProps = {
  open: false,
};
