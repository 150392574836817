const Facebook = {
  init: function () {
    $.ajaxSetup({ cache: true });
    $.getScript("https:////connect.facebook.net/en_US/sdk.js", function () {
      FB.init({
        appId: localStorage.getItem("waitlistr-facebook-app-id"),
        version: "v2.7",
      });
    });
  },
};

global.Facebook = Facebook;
