import React from 'react';

import { Box, Typography, Divider, Alert } from '@mui/material';
import pluralize from 'pluralize';
import { Button } from '@components/button/Button';
import { useRemoveMembers } from '@fetch-components/members';

const RemoveMembersModal = ({ members, waitlist }) => {
  const onSuccess = () => {};

  const mutation = useRemoveMembers({ waitlistId: waitlist.id, onSuccess });

  return (
    <div style={{ width: '100%', maxWidth: 380 }}>
      <Box mt={2}>
        <Typography variant="h3">Remove list members</Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      {mutation.isError && <ErrorMessage message={'Something went wrong'} />}
      {mutation.isSuccess && (
        <SuccessMessage message={'Members removed successfully'} />
      )}
      {!mutation.isSuccess && (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            You are about to remove{' '}
            <strong>
              {members.length} {pluralize('members', members.length)}
            </strong>{' '}
            from <strong>"{waitlist.title}"</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Note:</strong> They will appear in the "Removed members"
            tab.
          </Typography>

          <Box mt={4}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              isLoading={mutation.isLoading}
              disabled={mutation.isLoading}
              onClick={() => {
                mutation.mutate({
                  waitlist_id: waitlist.id,
                  ids: members.map((m) => m.id),
                });
              }}
              type="submit"
            >
              YES, DO IT
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default RemoveMembersModal;

const SuccessMessage = ({ message }) => (
  <Alert severity="success">{message}</Alert>
);
const ErrorMessage = ({ message }) => <Alert severity="error">{message}</Alert>;
