import React from 'react';

import TextField from '@components/TextField/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePicker = ({ ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker {...rest} />
    </LocalizationProvider>
  );
};
DatePicker.defaultProps = {
  renderInput: (params) => <TextField {...params} />,
};

export default DatePicker;
