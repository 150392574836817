import React from 'react';

import TextareaAutosize from '@mui/material/TextareaAutosize';

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const textareaStyles = css`
  background: white;
  padding: 18.5px 14px;
  width: 100%;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  font: inherit;
  min-height: 108px;
  &:focus {
    outline: #c08eef;
    border-color: #c08eef;
    border-width: 2px;
    padding: 17.5px 13px;
  }
`;

const TextArea = ({ ...rest }) => {
  return (
    <TextareaAutosize
      css={css`
        ${textareaStyles};
      `}
      {...rest}
    />
  );
};

export default TextArea;
