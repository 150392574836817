import React from 'react';
import TextField from '@components/TextField';
import {
  Box,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { URL_REGEX } from '../../utils/validationRegex';
import SelectField from '@components/form/SelectField/SelectField';
import { Button } from '@components/button/Button';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  url: yup
    .string()
    .required('URL is required')
    .matches(URL_REGEX, 'invalid url format'),
  event_type: yup.string().required('Event type is required'),
});

const WebhookForm = ({ initialValues, onSubmit }) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography>Webhook Name</Typography>
        <TextField
          fullWidth
          placeholder="Name your webhook"
          variant="outlined"
          size="small"
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.name}
          helperText={errors.name}
        />
      </Box>
      <Box>
        <Typography>Webhook URL</Typography>
        <TextField
          fullWidth
          placeholder="Enter the URL we send data to"
          variant="outlined"
          size="small"
          id="url"
          value={values.url}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.url)}
          helperText={errors.url}
        />
      </Box>
      <Box>
        <Typography>Events to Send</Typography>
        <SelectField
          variant="outlined"
          size="small"
          id="event_type"
          fullWidth
          value={values.event_type}
          onChange={(e) => {
            setFieldValue('event_type', e.target.value);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.event_type)}
          helperText={errors.event_type}
        >
          <MenuItem value="all">
            <em>All Events</em>
          </MenuItem>
          <MenuItem value="joined">Joined list</MenuItem>
          <MenuItem value="removed">Removed from list</MenuItem>
          <MenuItem value="left">Left list</MenuItem>
        </SelectField>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography>Status of Webhook</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={values.active}
              onChange={(e) => {
                setFieldValue('active', e.target.checked);
              }}
            />
          }
          label={values.active ? 'Enabled' : 'Disabled'}
        />
      </Stack>
      <Button
        variant="contained"
        fullWidth
        type="submit"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        {initialValues.id ? 'Update' : 'Create'}
      </Button>
    </form>
  );
};

WebhookForm.defaultProps = {
  initialValues: { active: true, event_type: 'all' },
};

export default WebhookForm;
