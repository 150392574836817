import styled from "@emotion/styled";

export const withStyles = (Component) =>
  styled(Component)`
    width: 100%;
    & label {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      & span {
        display: block;
        width: 100%;
      }
    }
    .MuiRadio-root {
      background: none !important;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  `;
