import { Button } from '@components/button/Button';
import DatePicker from '@components/DatePicker/DatePicker';
import TextField from '@components/TextField';
import { Search as SearchIcon, Tune as TuneIcon } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import FilterSets from './FilterSets';
import { useCurrentUser } from '@contexts/CurrentUserContext';

const Filter = ({ waitlist, onFilter, isFiltering }) => {
  const [filters, setFilters] = useState({
    dateField: '',
    valueField: '',
    valueOperator: '',
    customField: '',
    customFieldValue: '',
    query: '',
    dateFrom: null,
    dateTo: null,
    profileQuery: '',
  });
  const [advancedOptionsOpened, setAdvancedOptionsOpened] = useState(false);

  const debounceLoadData = useCallback(_.debounce(onFilter, 500), []);

  const { currentUser } = useCurrentUser();

  return (
    <>
      <Box px={2} py={1.5} sx={{ flexGrow: 1 }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box mr={1}>
              <SearchIcon />
            </Box>
            <InputBase
              fullWidth
              placeholder="Search by email or username"
              value={filters.query || ''}
              onChange={(e) => {
                const newFilters = { ...filters, query: e.target.value };
                setFilters(newFilters);
                debounceLoadData(formatFilters(newFilters));
              }}
            />
          </Grid>
          <Grid container item xs justifyContent="flex-end">
            <Button
              endIcon={<TuneIcon />}
              onClick={() => {
                setAdvancedOptionsOpened(!advancedOptionsOpened);
              }}
            >
              {advancedOptionsOpened ? 'basic' : 'advanced'}
            </Button>
          </Grid>
        </Grid>
        <Collapse in={advancedOptionsOpened}>
          <Box>
            <Box mt={1} mb={2}>
              <Divider light />
            </Box>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Date</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  labelId="date-field-label"
                  id="date-field"
                  value={filters.dateField}
                  onChange={(e) => {
                    setFilters({ ...filters, dateField: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem>
                    <em>optional</em>
                  </MenuItem>
                  <MenuItem value="join-removed-date">
                    join & removed date
                  </MenuItem>
                  <MenuItem value="join-date">join date</MenuItem>
                  <MenuItem value="removed-date">removed date</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  disabled={!filters.dateField}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="from"
                  label="From"
                  value={filters.dateFrom || null}
                  defaultValue={null}
                  onChange={(date) => {
                    setFilters({
                      ...filters,
                      dateFrom: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  disabled={!filters.dateField}
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="to"
                  label="To"
                  value={filters.dateTo || null}
                  defaultValue={null}
                  onChange={(date) =>
                    setFilters({
                      ...filters,
                      dateTo: date,
                    })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>

            <Box my={1} />

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Value</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  labelId="value-field-label"
                  id="value-field"
                  value={filters.valueField}
                  onChange={(e) => {
                    setFilters({ ...filters, valueField: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={0}>
                    <em>optional</em>
                  </MenuItem>
                  <MenuItem value="score">Points</MenuItem>
                  <MenuItem value="payment.amount">Payment amount</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  disabled={!filters.valueField}
                  labelId="value-operator-label"
                  id="value-operator"
                  value={filters.valueOperator}
                  onChange={(e) => {
                    setFilters({ ...filters, valueOperator: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={0}>
                    <em>optional</em>
                  </MenuItem>
                  <MenuItem value=">">greater than</MenuItem>
                  <MenuItem value="<">less than</MenuItem>
                  <MenuItem value="=">equal to</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <NumberFormat
                  fullWidth
                  placeholder="Enter a number"
                  variant="outlined"
                  disabled={!filters.valueField}
                  value={filters.valueNumber || ''}
                  customInput={TextField}
                  onChange={(e) => {
                    setFilters({ ...filters, valueNumber: e.target.value });
                  }}
                />
              </Grid>
            </Grid>

            <Box my={1} />

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Profile has</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  value={filters.profileQuery || ''}
                  onChange={(e) => {
                    setFilters({ ...filters, profileQuery: e.target.value });
                  }}
                  fullWidth
                  placeholder="Includes: username, email, real name, address, & phone number"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Box my={1} />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography variant="subtitle2">Custom fields</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  labelId="custom-field-label"
                  id="custom-field"
                  value={filters.customField}
                  onChange={(e) => {
                    setFilters({ ...filters, customField: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={0}>
                    <em>optional</em>
                  </MenuItem>
                  {(waitlist?.custom_requirements || []).map((customField) => (
                    <MenuItem value={customField.slug} key={customField.slug}>
                      {customField.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={7}>
                <TextField
                  value={filters.customFieldValue || ''}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      customFieldValue: e.target.value,
                    });
                  }}
                  fullWidth
                  placeholder="Enter terms or a number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  {currentUser?.features?.filter_set && (
                    <FilterSets
                      waitlist={waitlist}
                      filters={filters}
                      onApply={(filterSetFilters) => {
                        setFilters(filterSetFilters);
                        onFilter(formatFilters(filterSetFilters));
                      }}
                    />
                  )}
                </Box>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    onClick={() => {
                      setFilters({});
                      onFilter(formatFilters({}));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    isLoading={isFiltering}
                    disabled={isFiltering}
                    onClick={() => {
                      onFilter(formatFilters(filters));
                    }}
                  >
                    Search
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Divider light />
    </>
  );
};

const formatFilters = (filters) => {
  const formattedFilters = {
    query: filters.query,
    profile: { query: filters.profileQuery },
  };
  if (filters.dateField) {
    formattedFilters[filters.dateField] = {
      from: moment(filters.dateFrom).format('YYYY-MM-DD'),
      to: moment(filters.dateTo).format('YYYY-MM-DD'),
    };
  }
  if (filters.valueField) {
    formattedFilters[filters.valueField] = {
      operator: filters.valueOperator,
      value: filters.valueNumber,
    };
  }
  if (filters.customField && filters.customFieldValue) {
    formattedFilters[filters.customField] = filters.customFieldValue;
  }

  return formattedFilters;
};

export default Filter;
