import React from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';

const TablePagination = ({
  perPage,
  rowsCount,
  page,
  onPageChange,
  onPerPageChange,
  rowsPerPageOptions,
}) => (
  <MuiTablePagination
    rowsPerPage={perPage}
    rowsPerPageOptions={rowsPerPageOptions}
    component="div"
    count={rowsCount}
    page={page}
    onPageChange={(_, newPage) => {
      onPageChange(newPage + 1);
    }}
    onRowsPerPageChange={(e) => {
      onPerPageChange(parseInt(e.target.value));
      onPageChange(1);
    }}
  />
);
TablePagination.defaultProps = {
  rowsPerPageOptions: [20, 50, 100],
};

export default TablePagination;
