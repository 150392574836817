import React from 'react';

import { Grid, Link, Typography } from '@mui/material';

import { Formik } from 'formik';

import InputSet from '@components/form/input/InputSet/InputSet';

import TextField from '@components/TextField/TextField';

import * as yup from 'yup';
import { Button } from '@components/button/Button';
import CountriesSelect from '@components/CountriesSelect/CountriesSelect';

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
});

const PersonalProfileForm = ({ initialValues, handleSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit: handleFormSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleFormSubmit}>
            <InputSet header="E-mail">
              <Typography>{values.email}</Typography>
              <Link
                href="#"
                onClick={() => {
                  window.open('/users/edit', '_self');
                }}
              >
                Edit
              </Link>
            </InputSet>

            <InputSet header="Full Name">
              <TextField
                size="medium"
                variant="outlined"
                name="name"
                value={values.name || ''}
                fullWidth="true"
                label="Full Name"
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                autoComplete="off"
              />
            </InputSet>

            <InputSet header="Phone Number (optional)">
              <TextField
                size="medium"
                variant="outlined"
                name="phone_number"
                value={values.phone_number || ''}
                onChange={handleChange}
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number}
                autoComplete="off"
                type="tel"
              />
            </InputSet>

            <InputSet header="Address (optional)">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Address"
                    name="address"
                    value={values.address || ''}
                    onChange={handleChange}
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Address line two"
                    name="address_two"
                    value={values.address_two || ''}
                    onChange={handleChange}
                    error={Boolean(errors.address_two)}
                    helperText={errors.address_two}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="City"
                    name="path"
                    value={values.path || ''}
                    onChange={handleChange}
                    error={Boolean(errors.city)}
                    helperText={errors.city}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="State/Province"
                    name="state"
                    value={values.state || ''}
                    onChange={handleChange}
                    error={Boolean(errors.state)}
                    helperText={errors.state}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Postal Code"
                    name="postal_code"
                    value={values.postal_code || ''}
                    onChange={handleChange}
                    error={Boolean(errors.postal_code)}
                    helperText={errors.postal_code}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CountriesSelect
                    selectedCountryId={values.country_id}
                    onChange={(e, value) =>
                      setFieldValue('country_id', value.id)
                    }
                    label="Country"
                  />
                </Grid>
              </Grid>
            </InputSet>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              fullWidth
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save Personal Profile
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default PersonalProfileForm;
