import { styled } from '@mui/material/styles';
import { Tabs as MuiTabs } from '@mui/material';

export const TabsStyled = styled(MuiTabs)(({ theme }) => ({
  '.MuiTab-root': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '.MuiTabs-indicator': {
    height: 3,
  },
  // if primary colors use
  '.MuiTab-textColorPrimary:hover': {
    color: theme.palette.grey.xdark,
  },
  '.MuiTab-textColorPrimary.Mui-selected': {
    color: theme.palette.grey.xdark,
    fontWeight: theme.typography.fontWeightBold,
  },
}));
