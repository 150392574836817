import React from "react";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";

import { withStyles } from "./ThemeSelector.styles";

const StyledThemeSelector = withStyles(FormControl);

const ThemeSelector = ({ value, handleChange }) => (
  <StyledThemeSelector>
    <RadioGroup name="theme" value={value} onChange={handleChange}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            value="light"
            control={<Radio />}
            label={
              <Box className="Business-theme_card Business--light">
                <h2>Light Theme</h2>
                <Typography paragraph>Description</Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            value="dark"
            control={<Radio />}
            label={
              <Box className="Business-theme_card Business--dark">
                <h2>Dark Theme</h2>
                <Typography paragraph>Description</Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            value="branded"
            disableRipple
            control={<Radio />}
            label={
              <Box className="Business-theme_card Business--branded">
                <h2>Purple Theme</h2>
                <Typography paragraph>Description</Typography>
              </Box>
            }
          />
        </Grid>
      </Grid>
    </RadioGroup>
  </StyledThemeSelector>
);

export default ThemeSelector;
