import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EmailIcon from '@mui/icons-material/Email';
import { useModal } from '@contexts/ModalContext';

import EmailMembersModal from '@components/member/EmailMembersModal';

export default function EmailMenu({ waitlist }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { showModal } = useModal();

  const showEmailMembersModal = (members, scope = 'all') => {
    let membersCount = null;
    if (scope === 'all') {
      membersCount = waitlist.waitlist_members_count;
    } else if (scope === 'current') {
      membersCount = waitlist.current_waitlist_members_count;
    } else if (scope === 'past') {
      membersCount = waitlist.past_waitlist_members_count;
    }
    showModal(
      <EmailMembersModal
        members={members}
        scope={scope}
        waitlist={waitlist}
        membersCount={membersCount}
      />
    );
  };

  return (
    <div>
      <Button
        id="email-button"
        aria-controls={open ? 'email-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<EmailIcon />}
      >
        Email List Members
      </Button>
      <Menu
        id="email-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'email-button',
        }}
      >
        <MenuItem
          onClick={() => {
            showEmailMembersModal([], 'all');
            handleClose();
          }}
        >
          All Members
        </MenuItem>
        <MenuItem
          onClick={() => {
            showEmailMembersModal([], 'current');
            handleClose();
          }}
        >
          Current Members
        </MenuItem>
        <MenuItem
          onClick={() => {
            showEmailMembersModal([], 'past');
            handleClose();
          }}
        >
          Removed Members
        </MenuItem>
      </Menu>
    </div>
  );
}
