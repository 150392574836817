import { theme } from '../../themes/default';
import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledPrimaryButton = styled(Button)`
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: ${theme.palette.primary.dark};
  }
  ${theme.breakpoints.up('sm')} {
    font-size: 1.25rem;
    padding: 14px 32px;
  }
`;

export const StyledActionButton = styled(Button)`
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${theme.palette.action.selected};
  color: ${theme.palette.grey.dark};
  box-shadow: none;
  &:hover {
    background-color: ${theme.palette.action.hover};
    box-shadow: none;
  }
  &:active {
    background-color: ${theme.palette.action.active};
    box-shadow: none;
  }
`;
