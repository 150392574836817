import { useCurrentUser } from '@contexts/CurrentUserContext';
import { useMembers } from '@contexts/waitlist/ManagePage/MembersContext';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useExpanded, useRowSelect, useTable, useSortBy } from 'react-table';
import { buildColumns } from './columns';
import { MembersTableWrap } from './MembersTable.styled';
import Row from './Row';

const MembersTable = ({
  isLoading,
  loadingRowsSize,
  waitlist,
  data,
  onSelect,
  onSort,
  scope,
}) => {
  const {
    showNewInvoiceModal,
    showPaymentModal,
    showInvoiceModal,
    showRemoveMemberModal,
    showDisqualifyMemberModal,
  } = useMembers();

  const onNewInvoiceClick = (members) => {
    showNewInvoiceModal(waitlist, members);
  };
  const onPaymentClick = (member) => {
    showPaymentModal(member);
  };
  const onInvoiceClick = (member) => {
    showInvoiceModal(member);
  };
  const onRemoveClick = (member) => {
    showRemoveMemberModal(member);
  };
  const onDisqualifyClick = (member) => {
    showDisqualifyMemberModal(member);
  };

  const columns = useMemo(
    () =>
      buildColumns(waitlist, {
        actions: {
          onNewInvoiceClick,
          onPaymentClick,
          onInvoiceClick,
          onRemoveClick,
          onDisqualifyClick,
        },
        skip: scope === 'removed' ? ['remove', 'disqualify'] : [],
      }),
    [waitlist, scope]
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useSortBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    if (!isLoading && sortBy && sortBy.length) {
      onSort({
        field: sortBy[0].id,
        direction: sortBy[0].desc ? 'desc' : 'asc',
      });
    }
  }, [sortBy]);

  useEffect(() => {
    if (!isLoading) {
      onSelect(selectedFlatRows.map((f) => f.original));
    }
  }, [selectedFlatRows]);

  return (
    <MembersTableWrap>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {isLoading ? (
            <LoadingBody rowsSize={loadingRowsSize} />
          ) : (
            <Body rows={rows} prepareRow={prepareRow} />
          )}
        </TableBody>
      </Table>
    </MembersTableWrap>
  );
};

const LoadingBody = ({ rowsSize }) => (
  <>
    {_.times(rowsSize, () => (
      <Row isLoading />
    ))}
  </>
);

export const Body = ({ rows, prepareRow }) => {
  const { currentUser } = useCurrentUser();

  return rows.map((row, i) => {
    prepareRow(row);
    if (!row) {
      return null;
    }

    return (
      <Row
        key={`member-table-row-${i}`}
        row={row}
        notesEnabled={currentUser?.features?.member_notes}
      />
    );
  });
};

MembersTable.defaultProps = {
  loadingRowsSize: 10,
};

export default MembersTable;
