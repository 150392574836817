import React, { createContext, useContext, useState } from 'react';
import { DefaultModal } from '@components/modal';

const ModalContext = createContext();

const ModalProvider = (props) => {
  const [modal, setModal] = useState(null);

  return (
    <ModalContext.Provider value={{ showModal: setModal }}>
      {props.children}
      {modal && (
        <DefaultModal
          open
          onClose={() => {
            setModal(null);
          }}
        >
          {modal}
        </DefaultModal>
      )}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

export { ModalProvider, useModal };
