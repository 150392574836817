import React, { useMemo } from 'react';
import { usePickGiveawayWinner } from '@fetch-components/members';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { buildColumns } from '../MembersTable/columns';
import { useExpanded, useRowSelect, useTable, useSortBy } from 'react-table';
import { Body } from '../MembersTable/MembersTable';
import { GiveawayWinnersTableStyled } from './GiveawayWinnersTable.styled';
import { useModal } from '@contexts/ModalContext';
import DisqualifyMemberModal from '../DisqualifyMemberModal';
import { useMembers } from '@contexts/waitlist/ManagePage/MembersContext';

const GiveawayWinnersTable = ({ waitlist, data, isLoading }) => {
  const { showModal } = useModal();

  const {
    showNewInvoiceModal,
    showPaymentModal,
    showInvoiceModal,
    showRemoveMemberModal,
    showDisqualifyMemberModal,
  } = useMembers();

  const onNewInvoiceClick = (members) => {
    showNewInvoiceModal(waitlist, members);
  };
  const onPaymentClick = (member) => {
    showPaymentModal(member);
  };
  const onInvoiceClick = (member) => {
    showInvoiceModal(member);
  };
  const onRemoveClick = (member) => {
    showRemoveMemberModal(member);
  };
  const onDisqualifyClick = (member) => {
    showDisqualifyMemberModal(member);
  };

  const columns = useMemo(
    () =>
      buildColumns(waitlist, {
        actions: {
          onNewInvoiceClick,
          onPaymentClick,
          onInvoiceClick,
          onRemoveClick,
          onDisqualifyClick,
        },
        skip: ['select'],
      }),
    [waitlist]
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data || [],
      autoResetExpanded: false,
    },
    useExpanded
  );

  return (
    <GiveawayWinnersTableStyled>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {isLoading ? (
            <LoadingBody />
          ) : (
            <Body rows={rows} prepareRow={prepareRow} />
          )}
        </TableBody>
      </Table>
    </GiveawayWinnersTableStyled>
  );
};

const LoadingBody = () => <Row isLoading />;

export default GiveawayWinnersTable;
