const getUrlParameter = (sParam) => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
    return false;
};

const randomString = (elemLength) => {
    var elemLength = elemLength ? elemLength : 5;
    var text = "";
    var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < elemLength - 1; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const ajaxize = (settingsIn) => {
    var settings = {
        method: settingsIn.method ? settingsIn.method : "GET",
        url: settingsIn.url,
        beforeSend: settingsIn.beforeSend ? settingsIn.beforeSend : null,
        data: settingsIn.data ? settingsIn.data : null,
        success: settingsIn.callback ? settingsIn.callback : null,
        error: settingsIn.error ? settingsIn.error : null,
        callback: settingsIn.callback ? settingsIn.callback : null,
        done: settingsIn.done ? settingsIn.done : null,
        complete: settingsIn.complete ? settingsIn.complete : null,
        fail: settingsIn.fail ? settingsIn.fail : null,
        dataType: settingsIn.dataType ? settingsIn.dataType : null,
        async: settingsIn.async ? settingsIn.async : false,
        headers: settingsIn.headers
    };

    $.ajax({
        method: settings.method,
        dataType: settings.dataType,
        url: settings.url,
        beforeSend: settings.beforeSend,
        headers: settings.headers,
        data: settings.data,
        succes: settings.success,
        error: settings.error,
        complete: settings.complete,
    })
        .done(settings.done)
        .done(
            !!settings.callback
                ? settings.callback
                : function () {
                      console.log("call is completed");
                  }
        );
    return true;
};

const isEmail = (email) => {
    var pattern = new RegExp(
        /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i
    );
    return pattern.test(email);
};

global.getUrlParameter = getUrlParameter;
global.randomString = randomString;
global.ajaxize = ajaxize;
global.isEmail = isEmail;
