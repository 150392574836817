import React from 'react';
import { Button as MuiButton } from '@mui/material';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

// import { StyledActionButton } from './Button.style';

export const Button = ({ isLoading, disabled, children, ...rest }) => (
  <MuiButton
    disabled={disabled}
    {...rest}
    startIcon={isLoading && <CircularProgress color="inherit" size={20} />}
  >
    {children}
  </MuiButton>
);

Button.defaultProps = {
  disabled: false,
  isLoading: false,
};
Button.propTypes = {
  disabled: PropTypes.bool,
};
