import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useModal } from '@contexts/ModalContext';
import WebhooksModal from './WebhooksModal';

import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  useListWebhooks,
  useRemoveWebhook,
  useTestWebhook,
} from '@fetch-components/webhooks';
import _ from 'lodash';
import ConfirmationDialog from '@components/Dialog/ConfirmationDialog';
import { useSnackbar } from 'notistack';

const WebhooksTab = ({ waitlistId }) => {
  const { showModal } = useModal();

  const { isFetching, isLoading, data } = useListWebhooks({ waitlistId });

  return (
    <Box my={3}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 3 }}
      >
        <Grid item xs>
          <Typography>
            Webhooks notify your application about events that occur in
            Waitlistr for this specific list. Events are sent asynchronously
            through HTTP POST request. <a href="https://waitlistr.freshdesk.com/en/support/solutions/articles/48001230560-webhook-integration" target="_blank">Learn more</a>
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => {
              showModal(<WebhooksModal waitlistId={waitlistId} />);
            }}
          >
            Add Webhook
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2}>
        {isFetching && data?.webhooks && data.webhooks.length > 0 && (
          <LinearProgress />
        )}
        {isLoading
          ? _.times(3, () => <Webhook />)
          : data?.webhooks?.map((webhook) => <Webhook data={webhook} />)}
      </Stack>
    </Box>
  );
};

const Webhook = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const removeMutation = data
    ? useRemoveWebhook({
        waitlistId: data.waitlist_id,
        onSuccess: () => {},
      })
    : null;

  const showWebhookTestFailed = () => {
    enqueueSnackbar('Something went wrong!', {
      variant: 'error',
    });
  };
  const showWebhookTestSucceed = () => {
    enqueueSnackbar('Test successfully sent!', {
      variant: 'success',
    });
  };

  const testMutation = data
    ? useTestWebhook({
        id: data?.id,
        onSuccess: (data) => {
          if (data.success) {
            showWebhookTestSucceed();
          } else {
            showWebhookTestFailed();
          }
        },
        onError: () => {
          showWebhookTestFailed();
        },
      })
    : null;

  return (
    <Paper sx={{ p: 4 }}>
      {(removeMutation?.isLoading || testMutation?.isLoading) && (
        <LinearProgress />
      )}
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h4">
            {data ? data.name : <Skeleton />}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Link
            underline="none"
            onClick={() => {
              testMutation.mutate();
            }}
          >
            Test Webhook
          </Link>
          <ActionMenu
            waitlistId={data?.waitlist_id}
            webhook={data}
            onDeleteButtonClick={() => {
              removeMutation.mutate(data);
            }}
          />
        </Stack>
      </Stack>
      <Box>
        <Typography>{data ? `ID: ${data.public_id}` : <Skeleton />}</Typography>
      </Box>
      <Box>
        <Typography>{data ? data.url : <Skeleton />}</Typography>
      </Box>
      <Box>
        <Typography>{data ? data.event_type : <Skeleton />}</Typography>
      </Box>
    </Paper>
  );
};

const ActionMenu = ({ waitlistId, webhook, onDeleteButtonClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { showModal } = useModal();

  return (
    <div>
      <IconButton
        id="action-button"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'action-button',
        }}
      >
        <MenuItem
          onClick={() => {
            showModal(<WebhooksModal waitlistId={waitlistId} data={webhook} />);
          }}
        >
          Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteDialogOpened(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={deleteDialogOpened}
        onCancel={() => {
          setDeleteDialogOpened(false);
        }}
        onConfirm={() => {
          onDeleteButtonClick();
        }}
        content="When you delete this webhook, the associated events will also be removed from the Events tab"
      />
    </div>
  );
};

export default WebhooksTab;
