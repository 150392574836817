function WaitlistrModal(element) {
  this.element = element;
  var modal = this;

  $(this.element).on("click", ".toggleEmbed", function () {
    modal.hide();
  });
  $(this.element).on("click", ".closeModal", function () {
    modal.hide();
  });

  this.show = function () {
    //$(modal.element).addClass('Modal--show').hide().fadeIn();
    $(modal.element).addClass("Modal--show");
  };

  this.hide = function () {
    //$(modal.element).removeClass('Modal--show').fadeOut();
    $(modal.element).removeClass("Modal--show");
  };
}

global.WaitlistrModal = WaitlistrModal;
