import { useMutation, useQuery, useQueryClient } from "react-query";
import { authToken } from "./auth";
import { API_URL } from "./api";

export const useGetProfile = ({ onError }) => {
  return useQuery(
    ["get-profile"],
    () =>
      fetch(`${API_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      }),
    {
      onError: onError,
    }
  );
};

export const useCreateProfile = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries("get-profile");
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
};

export const useUpdateProfile = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/profile`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries("get-profile");
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
};
