import { useMutation } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

export const useCreateChatRoom = ({ onSuccess }) => {
  return useMutation(
    ({ participants }) =>
      fetch(`${API_URL}/chat/rooms`, {
        body: JSON.stringify({
          room: { participants },
        }),
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json()),
    {
      onSuccess,
    }
  );
};
