import { useMutation, useQuery, useQueryClient } from "react-query";
import { authToken } from "../auth";
import { API_URL } from "../api";

import qs from "qs";

export const useCreateInvoice = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/billing/invoices`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries("list-members", variables.waitlist_id);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
