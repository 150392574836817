import { useQuery, useMutation, useQueryClient } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

import qs from 'qs';

export const useListWebhooks = ({ waitlistId }) => {
  const query = qs.stringify({
    waitlist_id: waitlistId,
  });
  return useQuery(['list-webhooks', waitlistId], () =>
    fetch(`${API_URL}/webhooks?${query}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};

export const useCreateWebhook = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/webhooks`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-webhooks', variables.waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useUpdateWebhook = ({ id, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/webhooks/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-webhooks', variables.waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useRemoveWebhook = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/webhooks/${values.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-webhooks', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useTestWebhook = ({ id, onSuccess, onError }) => {
  const request = () =>
    fetch(`${API_URL}/webhooks/${id}/send_test`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
  });
};

export const useDeliverWebhook = ({ id, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/webhooks/${id}/deliver`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
