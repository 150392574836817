import React, { createContext, useContext, useState } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addSuccessAlert = ({ message, title, expireIn, render }) => {
    setAlerts(
      alerts.concat({
        message,
        title,
        type: "success",
        expireIn,
      })
    );
  };

  const addErrorAlert = ({ message, title, expireIn, render }) => {
    setAlerts(
      alerts.concat({
        message,
        title,
        type: "error",
        expireIn,
      })
    );
  };

  return (
    <AlertContext.Provider value={{ alerts, addSuccessAlert, addErrorAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlerts = () => useContext(AlertContext);
