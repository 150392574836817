import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextFieldStyled = styled(MuiTextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.primary.contrastText,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '.MuiInputBase-multiline': {
    textarea: {
      minHeight: 72,
    },
  },
}));
