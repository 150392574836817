import { useQuery, useMutation, useQueryClient } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';
import qs from 'qs';

export const useListFilterSets = ({ waitlistId }) => {
  const query = qs.stringify({
    waitlist_id: waitlistId,
  });
  return useQuery(['list-filter-sets', waitlistId], () =>
    fetch(`${API_URL}/filter_sets?${query}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};

export const useCreateFilterSet = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/filter_sets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-filter-sets', variables.waitlist_id);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },
  });
};

export const useDeleteFilterSet = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/filter_sets/${values.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-filter-sets', variables.waitlist_id);
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }
    },
  });
};
