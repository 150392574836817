import React from 'react';
import {
  Alert,
  Box,
  // Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Button } from 'components/button/Button';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { useDeliverWebhook } from '@fetch-components/webhooks';

const EventsModal = ({ event }) => {
  const eventNameMapping = {
    joined: 'Joined list',
    removed: 'Removed from list',
    left: 'Left list',
  };
  const eventStatusChip = {
    success: <Chip label="Succeeded" color="primary" size="small" />,
    failed: <Chip label="Failed" color="error" size="small" />,
  };

  const mutation = useDeliverWebhook({
    id: event.webhook.id,
    onSuccess: () => {},
  });

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: 500 } }}>
      <Typography variant="h4">Event</Typography>
      <Stack mt={3} spacing={4}>
        <Paper variant="outlined" sx={{ p: 3 }}>
          <GridGroup title={'Event ID'} value={event.public_id} />
          <GridGroup
            title={'Name'}
            value={eventNameMapping[event.event_type]}
          />
          <GridGroup
            title={'Occured at'}
            value={moment(event.run_at).utc().format('MMM Do, YYYY HH:mm:ss')}
          />
          <GridGroup
            title={'JSON Content'}
            value={<ReactJson src={event.body} />}
          />
        </Paper>

        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{event.webhook.name}</Typography>
            {event.status === 'failed' && (
              <Button
                variant="outlined"
                size="small"
                isLoading={mutation.isLoading}
                onClick={async () => {
                  await mutation.mutateAsync({
                    event_id: event.id,
                  });
                }}
              >
                Resend Webhook
              </Button>
            )}
          </Stack>

          {mutation.isSuccess && (
            <Alert severity="success">Your webhook is queued again</Alert>
          )}

          <Paper variant="outlined" sx={{ p: 3, mt: 1 }}>
            <GridGroup title={'Webhook ID'} value={event.webhook.public_id} />
            <GridGroup title={'Status'} value={eventStatusChip[event.status]} />
            {event.status === 'failed' && (
              <GridGroup
                title={'Reason for last call failure'}
                value={event.error}
              />
            )}
            <GridGroup
              title={'Last called on'}
              value={moment(event.run_at).utc().format('MMM Do, YYYY HH:mm:ss')}
            />
          </Paper>
        </Box>
      </Stack>
    </Box>
  );
};

export default EventsModal;

const GridGroup = ({ title, value }) => {
  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      <Grid item xs={4}>
        <Typography sx={{ color: '#646464' }}>{title}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};
