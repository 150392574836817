import * as React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Chip,
  Skeleton,
  LinearProgress,
} from '@mui/material';

import { useModal } from '@contexts/ModalContext';
import EventsModal from './EventsModal';
import { useListWebhookEvents } from '@fetch-components/webhookEvents';

import moment from 'moment';

const EventsTab = () => {
  return (
    <Box my={3}>
      <Box mb={3}>
        <Typography>
          Listed below are the Events that were sent to your webhooks.
        </Typography>
      </Box>
      <EventsTable />
    </Box>
  );
};

const EventsTable = ({ waitlistId }) => {
  const { isFetching, isLoading, data } = useListWebhookEvents({ waitlistId });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Event ID</TableCell>
            <TableCell>Event Type</TableCell>
            <TableCell>Occured At</TableCell>
            <TableCell>Webhook Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching &&
            data?.webhook_events &&
            data.webhook_events.length > 0 && <LinearProgress />}
          {isLoading
            ? _.times(3, () => <Event />)
            : data?.webhook_events?.map((event) => <Event event={event} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Event = ({ event }) => {
  const { showModal } = useModal();
  const statusComponent = (status) => {
    if (status === 'success') {
      return <Chip label="Succeeded" color="primary" size="small" />;
    }
    if (status === 'failed') {
      return <Chip label="Failed" color="error" size="small" />;
    }
  };

  return (
    <TableRow
      key={event?.public_id}
      sx={{
        cursor: 'pointer',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      onClick={() => {
        showModal(<EventsModal event={event} />);
      }}
    >
      <TableCell component="th" scope="row">
        {event ? event.public_id : <Skeleton />}
      </TableCell>
      <TableCell>{event ? event.event_type : <Skeleton />}</TableCell>
      <TableCell>
        {event ? (
          moment(event.run_at).utc().format('MMM Do, YYYY HH:mm:ss')
        ) : (
          <Skeleton />
        )}
      </TableCell>
      <TableCell>
        {event ? statusComponent(event.status) : <Skeleton />}
      </TableCell>
    </TableRow>
  );
};

export default EventsTab;
