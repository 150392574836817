import { useQuery, useMutation, useQueryClient } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

import qs from 'qs';

export const useListMembers = ({
  listId,
  filters,
  page,
  perPage,
  skip = false,
  sortBy,
  order,
}) => {
  const query = qs.stringify({
    page,
    per_page: perPage,
    filters,
    sort_by: sortBy,
    order,
  });

  return useQuery(
    ['list-members', listId, query],
    () =>
      fetch(`${API_URL}/waitlists/${listId}/members?${query}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json()),
    {
      keepPreviousData: true,
      enabled: !skip,
    }
  );
};

export const useRemoveMembers = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: values.ids }),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-members', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useRemoveMember = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members/${values.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('giveaway-winners', waitlistId);
      queryClient.invalidateQueries('list-members', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useDisqualifyMember = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(
      `${API_URL}/waitlists/${waitlistId}/members/${values.id}/disqualify`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('giveaway-winners', waitlistId);
      queryClient.invalidateQueries('list-members', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useUpdateMember = ({ waitlistId, memberId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members/${memberId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-members', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useSendEmail = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = (values) =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members/send_email`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      if (onSuccess) {
        queryClient.invalidateQueries('current-user');
        onSuccess();
      }
    },
  });
};

export const useGetGiveawayWinners = ({ waitlistId }) => {
  const query = qs.stringify({
    filters: { giveaway_winner: true },
  });
  return useQuery(['giveaway-winners', waitlistId], () =>
    fetch(`${API_URL}/waitlists/${waitlistId}/members?${query}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};
