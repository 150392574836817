const ChatRoomController = require("./chat_room_controller");

class ChatController {
  constructor(currentUserEmail) {
    let controller = this;
    window.controller = controller;
    this.chatRoomWindows = {};

    this.chatTriggerLink = $(".chatTrigger");

    this.setUpLoaders = [];
    this.setUpLoaders.push(new Loader($(".chatTrigger .Loader")));
    this.setUpLoaders.push(new Loader($(".chatRoomsTrigger .Loader")));
    this.showSetUpLoaders();

    if (currentUserEmail === undefined || currentUserEmail.length == 0) {
      this.hideSetUpLoaders();
      return;
    }

    new ChatService().getUserInfo(currentUserEmail, (user) => {
      controller.user = user;
      this.chat = new Chat(
        user,
        (chat) => {
          this.hideSetUpLoaders();
          this.chatTriggerLink.attr("disabled", false);

          chat.onRoomMessageAdded((room, message) => {
            controller.popUpChatRoomWindow(room);
            // room.messageAdded(message)
          });

          chat.onInvitedToRoom((room) => {
            room.onMessageAdded((message) => {
              controller.popUpChatRoomWindow(room);
            });
            // controller.popUpChatRoomWindow(room)
          });

          chat.onRoomNotificationChanged((room) => {
            controller.roomNotificationUpdated(room);
          });

          controller.chatNotificationsChanged();

          controller.chatRoomsController = new ChatRoomsController(
            controller.user.username,
            chat.rooms,
            $(".chats"),
            this
          );
        },
        (room) => {
          controller.roomNotificationUpdated(room);
        }
      );
    });

    $(document).on("click", ".chatTrigger[disabled!=disabled]", (event) => {
      let chatTo = $(event.target).data("chat-to");
      if (chatTo === undefined) {
        chatTo = $(event.currentTarget).data("chat-to");
      }

      this.chat.startNewConversationWith(chatTo, (room) => {
        controller.popUpChatRoomWindow(room);
      });

      return false;
    });

    $(document).on("click", ".chatWindow .backButton", (event) => {
      for (let [roomName, roomController] of Object.entries(
        this.chatRoomWindows
      )) {
        roomController.close();
      }

      this.chatRoomsController.open();
    });

    $(document).on(
      "click",
      ".chatRoomIdTrigger[disabled!=disabled]",
      (event) => {
        let roomId = $(event.currentTarget).data("room-id");
        let room = null;
        for (let r of this.chat.rooms) {
          if (r.id == roomId) {
            room = r;
            break;
          }
        }
        controller.popUpChatRoomWindow(room);

        return false;
      }
    );
  }

  showSetUpLoaders() {
    for (let loader of this.setUpLoaders) {
      loader.show();
    }
  }

  hideSetUpLoaders() {
    for (let loader of this.setUpLoaders) {
      loader.hide();
    }
  }

  popUpChatRoomWindow(room) {
    let chatRoomController = this.chatRoomWindows[room.uniqueName];

    if (chatRoomController === undefined) {
      chatRoomController = new ChatRoomController(
        room,
        this.user.username,
        $(".chats")
      );
      this.chatRoomWindows[room.uniqueName] = chatRoomController;

      controller.chatRoomsController.prependRoomItem(room);
    }

    chatRoomController.open();
  }

  roomNotificationUpdated(room) {
    let chatTo = "";
    room.participants.forEach((value) => {
      if (value !== this.user.username) {
        chatTo = value;
      }
    });

    if (room.notificationCount[this.user.username] > 0) {
      $(
        '.chatRoomIdTrigger[data-room-id="' +
          room.id +
          '"] .chatNotificationCount'
      ).text("(" + room.notificationCount[this.user.username] + ")");
      $(
        '.chatRoomIdTrigger[data-room-id="' + room.id + '"] .Notice-dot'
      ).show();

      $(
        '.chatTrigger[data-chat-to="' + chatTo + '"] .chatNotificationCount'
      ).text("(" + room.notificationCount[this.user.username] + ")");
      $('.chatTrigger[data-chat-to="' + chatTo + '"] .Notice-dot').show();
    } else {
      $(
        '.chatRoomIdTrigger[data-room-id="' +
          room.id +
          '"] .chatNotificationCount'
      ).text("");
      $(
        '.chatRoomIdTrigger[data-room-id="' + room.id + '"] .Notice-dot'
      ).hide();

      $(
        '.chatTrigger[data-chat-to="' + chatTo + '"] .chatNotificationCount'
      ).text("");
      $('.chatTrigger[data-chat-to="' + chatTo + '"] .Notice-dot').hide();
    }

    this.chatNotificationsChanged();
  }

  chatNotificationsChanged() {
    let totalNotificationsCount = this.chat.notificationsCount();

    if (totalNotificationsCount > 0) {
      // $('.chatRoomsTrigger .chatNotificationCount').text('(' + totalNotificationsCount + ')')
      $(".chatRoomsTrigger .Notice-dot").show();
    } else {
      // $('.chatRoomsTrigger .chatNotificationCount').text('')
      $(".chatRoomsTrigger .Notice-dot").hide();
    }
  }
}

module.exports = ChatController;
