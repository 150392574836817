function UserFormController() {
  var form = $("#new_user");

  var formMessages = new FormMessages(form);

  $(".passwordGuideline").hide();

  $(document).on("focusin", "#user_password", function () {
    $(".passwordGuideline").slideDown("fast");
  });

  /*
  $.validator.addMethod("checkSpecialChars", function (value) {
    return /[!@#$%^&*()_=\[\]{};':"\\|,.<>\/?+-]/.test(value)
  }, "Password must contain at least one special character");

  $.validator.addMethod("atLeastOneLowercaseLetter", function (value, element) {
    return /[a-z]+/.test(value);
  }, "Password must have at least one lowercase letter");

  $.validator.addMethod("atLeastOneUppercaseLetter", function (value, element) {
    return /[a-z]+/.test(value);
  }, "Password must have at least one uppercase letter");

  $.validator.addMethod("atLeastOneNumber", function (value, element) {
    return /[0-9]+/.test(value);
  }, "Password must have at least one number");
*/

  form.validate({
    errorPlacement: function (error, element) {
      formMessages.addError($(error).text());
    },
    onsubmit: true,
    onkeyup: false,
    invalidHandler: function (event, validator) {
      formMessages.clear();
    },
    rules: {
      "user[email]": {
        required: true,
        email: true,
      },
      "user[username]": {
        required: true,
        minlength: 4,
      },
      "user[password]": {
        required: true,
        minlength: 8,
        maxlength: 32,
        //checkSpecialChars: true,
        //atLeastOneUppercaseLetter: true,
        //atLeastOneLowercaseLetter: true,
        //atLeastOneNumber: true
      },
    },
    messages: {
      "user[username]": {
        required: "Username can't be blank",
        minlength: "Username should have at least 5 characters",
      },
      "user[email]": {
        required: "Email can't be blank",
        email: "Email can't be blank",
      },
      "user[password]": {
        required: "Password can't be blank",
        minlength: "Password should have at least 8 characters",
      },
    },
    highlight: function (element) {
      if ($(element).attr("id") == "user_password") {
        $(element).val("");
      }
      $(element).addClass("Error");
    },
    unhighlight: function (element, error, valid, _orig) {
      $(element).removeClass("Error");
    },
  });
}

module.exports = UserFormController;
