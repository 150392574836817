function WaitlistShare(list, membership, shareToEmailModal) {
  waitlistShareComponent = this;
  this.list = list;
  this.membership = membership;
  this.shareToEmailModal = shareToEmailModal;
  this.apiUrl = location.origin + "/lists";

  Facebook.init();

  this.useApiUrl = function (rootUrl) {
    this.apiUrl = rootUrl;

    return this;
  };

  $(document).on("click", ".shareIcons .buttonFacebook", function (e) {
    FB.ui(
      {
        method: "share",
        href: listUrlWithReferral("facebook"),
        quote: listShareQuote(),
      },
      function (response) {
        if (response !== undefined) {
          saveNewShare("facebook");
        }
      }
    );

    return false;
  });

  $(document).on("click", ".shareIcons .shareToEmail", function (e) {
    waitlistShareComponent.shareToEmailModal.show();

    return false;
  });

  $(document).on(
    "ajax:success",
    "#share-to-emails-form",
    function (event) {
      const [data, _status, _xhr] = event.detail;
      if (data.errors !== undefined && data.errors.length > 0) {
        console.log("Error");
        return;
      }
      waitlistShared();
      waitlistShareComponent.shareToEmailModal.hide();
    }
  );

  var script = document.createElement("script");
  script.onload = function () {
    if (twttr != undefined) {
      twttr.events.bind("tweet", function (ev) {
        saveNewShare("twitter");
      });
    }
  };
  script.src = "https://platform.twitter.com/widgets.js";

  document.head.appendChild(script);

  function saveNewShare(destination) {
    if (waitlistShareComponent.membership.id === undefined) {
      return null;
    }

    var url =
      this.apiUrl +
      "/" +
      waitlistShareComponent.list.id +
      "/members/" +
      waitlistShareComponent.membership.memberId +
      "/shares";

    var params = {
      share: {
        destination: destination,
        membership_id: waitlistShareComponent.membership.id,
      },
      waitlist_id: waitlistShareComponent.list.id,
      member_id: waitlistShareComponent.membership.memberId,
    };

    ajaxize({
      url: url,
      data: params,
      method: "POST",
      done: function (response) {
        waitlistShared();
      },
    });
  }

  function listUrlWithReferral(utmSource) {
    var url =
      App.DOMAIN_WITH_PROTOCOL +
      "/lists/" +
      waitlistShareComponent.list.ownerSlug +
      "/" +
      waitlistShareComponent.list.slug +
      "?utm_source=" +
      utmSource;

    if (waitlistShareComponent.membership.referralCode !== undefined) {
      url = url + "&referral=" + waitlistShareComponent.membership.referralCode;
    }

    return url;
  }

  function listShareQuote() {
    return "I joined the waitlist '" + list.title + "'. Check it out.";
  }

  function waitlistShared() {
    //$('.notifications').html('<div class="Notice alert-notice">Thanks for sharing!</div>').delay(5000).fadeOut("slow");
  }
}

module.exports = WaitlistShare;
