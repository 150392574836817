import { CurrentUserProvider } from '@contexts/CurrentUserContext';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { theme } from '../../themes/default';
import { ModalProvider } from '@contexts/ModalContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Page = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ModalProvider>
              <div>{children}</div>
            </ModalProvider>
          </SnackbarProvider>
        </CurrentUserProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
export default Page;

export const withPage = (Component, options) => (props) =>
  (
    <Page>
      <Component {...props} />
    </Page>
  );
