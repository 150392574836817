import React from 'react';

import { Alert, Box, Stack, Typography } from '@mui/material';
import WebhookForm from '@components/webhooks/WebhookForm';
import { useCreateWebhook, useUpdateWebhook } from '@fetch-components/webhooks';

const WebhooksModal = ({ waitlistId, data }) => {
  const mutation = data?.id
    ? useUpdateWebhook({ id: data.id, onSuccess: (data) => {} })
    : useCreateWebhook({ onSuccess: (data) => {} });

  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: 500 } }}>
      <Typography variant="h4">Create a Webhook</Typography>
      <Stack mt={3} spacing={2}>
        {mutation.isSuccess && (
          <SuccessMessage message={'Your webhook is created!'} />
        )}
        {mutation.isError && (
          <ErrorMessage
            message={
              "Something went wrong and we couldn't create the webhook. Try again later."
            }
          />
        )}
        {!mutation.isSuccess && (
          <WebhookForm
            initialValues={data}
            onSubmit={async (values) => {
              await mutation.mutateAsync({
                ...values,
                waitlist_id: waitlistId,
              });
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

const SuccessMessage = ({ message }) => (
  <Alert severity="success">{message}</Alert>
);
const ErrorMessage = ({ message }) => <Alert severity="error">{message}</Alert>;

export default WebhooksModal;
