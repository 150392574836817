import React, { useState } from 'react';
import { TabsStyled } from './Tabs.styled';
import Tab from './Tab';

const Tabs = ({ children, initialSelectedTab, options, ...rest }) => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  return (
    <>
      <TabsStyled
        value={selectedTab}
        indicatorColor="secondary"
        onChange={(e, value) => {
          setSelectedTab(value);
        }}
        {...rest}
      >
        {options.map((option) => (
          <Tab value={option.value} label={option.label} />
        ))}
      </TabsStyled>
      {children({ selectedTab })}
    </>
  );
};
export default Tabs;
