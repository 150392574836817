import { useCurrentUser } from '@contexts/CurrentUserContext';
import {
  useCreateBusinessProfile,
  useGetBusinessProfile,
  useUpdateBusinessProfile,
} from '@fetch-components/businessProfiles';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import BusinessProfileForm from './BusinessProfileForm';

const BusinessProfileTab = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return <LinearProgress />;
  }

  const { isLoading, data } = useGetBusinessProfile({
    userId: currentUser?.id,
    onError: (error) => {
      if (error.status === 500) {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
        });
      }
    },
  });

  const createMutation = useCreateBusinessProfile({
    onSuccess: (data) => {
      if (data.errors) {
        enqueueSnackbar(data.errors[0].full_message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Business Profile created successfully!', {
          variant: 'success',
        });
      }
    },
  });

  const updateMutation = useUpdateBusinessProfile({
    onSuccess: (data) => {
      if (data.errors) {
        enqueueSnackbar(data.errors[0].full_message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Business Profile updated successfully!', {
          variant: 'success',
        });
      }
    },
  });

  const mutation = data?.id ? updateMutation : createMutation;

  if (mutation.isError) {
    enqueueSnackbar('Something went wrong!', {
      variant: 'error',
    });
  }

  return (
    <div>
      <p className="u-spaceTM">
        The details you provide in your business profile are used to create your
        free business landing page. Your business landing page will show off all
        your lists and help your customers find out more about you. Customize to
        your heart's content.
      </p>

      {isLoading ? (
        <></>
      ) : (
        <BusinessProfileForm
          user={currentUser}
          initialValues={data}
          handleSubmit={async (values) => {
            await mutation.mutateAsync(values);
          }}
        />
      )}
    </div>
  );
};

export default BusinessProfileTab;
