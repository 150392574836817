require("./core_functions");
require("./uploader");
require("./components");
require("./jquery-components");
require("./controllers");
require("./facebook");
require("./form_messages");
require("./loader");
require("./notifications");
require("./pagination");
require("./waitlistr_modal");
