import React from 'react';

import { Box, Typography, Divider } from '@mui/material';
import qs from 'qs';

const buildStripeConnectUrl = (user) => {
  const appBaseUrl = window.location.origin.replace('http://', 'https://');
  const stripeBaseUrl = 'https://connect.stripe.com/express/oauth/authorize';
  const params = {
    redirect_uri: appBaseUrl,
    client_id: process.env.STRIPE_CLIENT_ID,
    'stripe_user[email]': user.email,
    'stripe_user[first_name]': user.profile.first_name,
    'stripe_user[last_name]': user.profile.last_name,
  };
  return `${stripeBaseUrl}${qs.stringify(params, {
    encode: false,
    skipNulls: true,
    addQueryPrefix: true,
  })}`;
};

const btnClick = (event) => {
  const stripeUrl = buildStripeConnectUrl(currentUser);
  window.open(stripeUrl);
};

export const EnablePaymentsModal = () => (
  <div style={{ width: '100%', maxWidth: 600 }}>
    <Box p={3}>
      <Typography variant="h2">
        Get paid from your waitlists or send custom invoices
      </Typography>

      <ul class="u-spaceTM u-bigger">
        <li>
          <strong>Simple &amp; secure.</strong> By partnering with Stripe, we've
          taken care of creating secure payment processing so you don't have to.
          You can even connect your existing Stripe account!
        </li>
        <li>
          <strong>No surprise fees, ever.</strong> No setup, monthly, or hidden
          fees. All money is deposited directly into your bank account.
        </li>
        <li>
          <strong>Easy tracking.</strong> Payments received are associated with
          memberships on your waitlist.
        </li>
        <li>
          <strong>Flexible for your needs.</strong> Make payment optional,
          required...or send custom invoices one at a time.
        </li>
        <li>
          <a
            href="https://waitlistr.freshdesk.com/support/solutions/articles/48000986690-accepting-payments"
            target="_blank"
          >
            <strong>Learn more...</strong>
          </a>
        </li>
      </ul>

      <Box my={2}>
        <Divider />
      </Box>

      <a
        href="##"
        id="setup-banking-settings-link"
        class="Button-action Button--full u-hide"
      >
        <strong>Get Started:</strong> Add payments to your account
      </a>

      <button
        onClick={btnClick}
        class="Button-action Button--full banking-button"
      >
        Get Started: Add Stripe payments to your account
      </button>
    </Box>
  </div>
);
