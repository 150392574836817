// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
// require jquery.remotipart
// require jquery.pin/jquery.pin
// require fingerprintjs2
// require highcharts
// require chartkick
// require spectrum

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import ResizeObserver from "resize-observer-polyfill";
import "channels";
import { createPopper } from '@popperjs/core';

Rails.start();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// require images
require.context('../images', true);

// Globals
global.Rails = Rails;
global.ResizeObserver = ResizeObserver;
global.moment = require("moment-timezone");
global.jQuery = require("jquery");
global.$ = global.jQuery;
global.autosize = require("autosize");
global.smoothScroll = require("smooth-scroll");

// jQuery plugins
require("jquery-validation");
require("slick-carousel/slick/slick");
require("jquery.countdown");

// Custom JS code
require("./waitlistr");
