import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, FormControlLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  position: 'relative',
  width: 100,
  margin: 0,
}));

export const RadioStyled = styled(Radio)(({ theme }) => ({
  position: 'absolute',
  top: -2,
  right: 'calc(50% - 33px)', // half of imageWrap width
  padding: 0,
  color: 'rgba(0,0,0,0)',
}));

export const CheckCircleIconStyled = styled(CheckCircleIcon)(({ theme }) => ({
  color: '#fd9103',
  backgroundColor: '#ffffff',
  borderRadius: '100%',
}));

export const ImageWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  width: 66,
  height: 66,
  borderRadius: '100%',
  backgroundColor: '#ffffff',
  boxShadow: '1px 3px 5px rgba(0,0,0,0.2)',
  img: {
    maxWidth: 66,
    maxHeight: 66,
    '&.inactive': {
      filter: 'grayscale(1)',
      opacity: 0.5,
    },
  },
}));

export const NameWrap = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 14,
  '&.active': {
    fontWeight: 600,
  },
}));

export const CategoryList = styled(Box)(({ theme }) => ({
  maxHeight: 280,
  position: 'relative',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    width: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
}));
