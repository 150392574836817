const Notifications = {
  addNoticeMessage: function (message) {
    $(".notifications").append(
      '<div class="Notice alert-notice">' + message + "</div>"
    );
  },

  addNoticeMessageAfterReload: function (message) {
    localStorage.setItem("notice-message", message);
    // $('.notifications').append('<div class="Notice alert-notice">' + message + '</div>');
  },
};

global.Notifications = Notifications;

$(document).ready(function () {
  if (localStorage.getItem("notice-message") !== null) {
    Notifications.addNoticeMessage(localStorage.getItem("notice-message"));
    localStorage.removeItem("notice-message");
  }
});
