function EditAccountController() {
  var controller = this;

  var form = $("#edit_user");

  var formMessages = new FormMessages(form);
  var cancelFormMessages = new FormMessages($("#cancel-account-form"));
  var changePasswordLoader = new Loader($("#ChangePasswordButton .Loader"));

  var editPasswordFormMessages = new FormMessages($("#edit_password_form"));
  var editPasswordLoader = new Loader($("#edit_password_form .Loader"));

  editAccountController = this;

  var changePasswordLoader = new Loader($("#ChangePasswordButton .Loader"));

  var cancelAccountModal = new WaitlistrModal($("#cancel-account-modal"));

  $(".passwordGuideline").hide();

  $(document).on("focusin", "#user_password", function () {
    $(".passwordGuideline").slideDown(500);
  });

  $(document).on("click", "#cancel-account-button", function (e) {
    cancelAccountModal.show();
  });

  $(document).on("click", "#ChangePasswordButton", function (e) {
    changePasswordLoader.show();
    $.ajax({
      url: "/users/password",
      data: {
        user: {
          email: $("#user_email").val(),
        },
      },
      method: "POST",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response, status, xhr) {
        changePasswordLoader.hide();
        if (!!response.errors) {
          response.errors.forEach(function (error) {
            formMessages.addError(error.title);
          });
          return;
        }

        location.href = "/users/login";
      },
    });

    e.preventDefault();
    return false;
  });

  // $('#changePassword').hide();

  $("#showPassword").click(function () {
    $("#changePassword").slideDown(500);
  });

  /*
  $.validator.addMethod("checkSpecialChars", function (value) {
    return /[!@#$%^&*()_=\[\]{};':"\\|,.<>\/?+-]/.test(value)
  }, "Password must contain at least one special character");

  $.validator.addMethod("atLeastOneLowercaseLetter", function (value, element) {
    return /[a-z]+/.test(value);
  }, "Password must have at least one lowercase letter");

  $.validator.addMethod("atLeastOneUppercaseLetter", function (value, element) {
    return /[a-z]+/.test(value);
  }, "Password must have at least one uppercase letter");

  $.validator.addMethod("atLeastOneNumber", function (value, element) {
    return /[0-9]+/.test(value);
  }, "Password must have at least one number");
*/

  $("#edit_password_form").validate({
    errorPlacement: function (error, element) {
      editPasswordFormMessages.addError($(error).text());
    },
    onsubmit: true,
    onkeyup: false,
    invalidHandler: function (event, validator) {
      editPasswordFormMessages.clear();
    },
    rules: {
      "user[password]": {
        required: function (element) {
          return $("#user_password").is(":visible");
        },
        minlength: 8,
        maxlength: 32,
        //checkSpecialChars: true,
        //atLeastOneUppercaseLetter: true,
        //atLeastOneLowercaseLetter: true,
        //atLeastOneNumber: true
      },
      "user[password_confirmation]": {
        required: function (element) {
          return $("#user_password").is(":visible");
        },
        equalTo: "#user_password",
      },
      "user[current_password]": {
        required: function (element) {
          return $("#user_password").is(":visible");
        },
      },
    },
    messages: {
      "user[password]": {
        required: "Password can't be blank",
        minlength: "Password should have at least 8 characters",
      },
      "user[password_confirmation]": {
        required: "Password confirmation can't be blank",
        equalTo: "Password and password confirmation should match",
      },
      "user[current_password]": {
        required: "Current Password can't be blank",
      },
    },
    highlight: function (element) {
      $(element).addClass("Error");
      if ($(element).attr("id") == "user_password") {
        $(element).val("");
      }
    },
    unhighlight: function (element, error, valid, _orig) {
      $(element).removeClass("Error");
    },
  });

  form.validate({
    errorPlacement: function (error, element) {
      formMessages.addError($(error).text());
    },
    onsubmit: true,
    onkeyup: false,
    invalidHandler: function (event, validator) {
      formMessages.clear();
    },
    rules: {
      "user[email]": {
        required: true,
      },
      "user[username]": {
        required: true,
        minlength: 4,
      },
      "user[password]": {
        required: function (element) {
          return $("#user_password").is(":visible");
        },
        minlength: 8,
        maxlength: 32,
        //checkSpecialChars: true,
        //atLeastOneUppercaseLetter: true,
        //atLeastOneLowercaseLetter: true,
        //atLeastOneNumber: true
      },
      "user[password_confirmation]": {
        required: function (element) {
          return $("#user_password").is(":visible");
        },
        equalTo: "#user_password",
      },
    },
    messages: {
      "user[username]": {
        required: "Username can't be blank",
        minlength: "Username should have at least 5 characters",
      },
      "user[email]": {
        required: "Email can't be blank",
      },
      "user[password]": {
        required: "Password can't be blank",
        minlength: "Password should have at least 8 characters",
      },
      "user[password_confirmation]": {
        required: "Password confirmation can't be blank",
        equalTo: "Password and password confirmation should match",
      },
    },
    highlight: function (element) {
      $(element).addClass("Error");
      if ($(element).attr("id") == "user_password") {
        $(element).val("");
      }
    },
    unhighlight: function (element, error, valid, _orig) {
      $(element).removeClass("Error");
    },
  });
}

module.exports = EditAccountController;
