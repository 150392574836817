import { useQuery } from "react-query";
import { authToken } from "./auth";
import { API_URL } from "./api";

export const useListCountries = () => {
  return useQuery("list-countries", () =>
    fetch(`${API_URL}/countries`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json())
  );
};
