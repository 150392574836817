import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';

const InvoiceModal = ({ member, invoice }) => (
  <div style={{ width: '100%', minWidth: 380 }}>
    <Typography variant="h6">Invoice</Typography>
    <Box my={2}>
      <Divider />
    </Box>
    <div>
      <Typography variant="subtitle1">
        Sent to <strong>{member.user.username}</strong>
      </Typography>
      <Typography variant="subtitle1">
        Sent on{' '}
        {moment(invoice.created_at).utc().format('MMM. Do YYYY, h:mm:ss a')}
      </Typography>

      <Box my={2}>Status: Invoiced</Box>

      <Box style={{ background: '#f4f4f4' }} p={2}>
        <Grid container>
          <Grid item xs={12}>
            <div>
              <strong>Description:</strong>
            </div>
            {invoice.description}
          </Grid>

          <Grid item xs={12}>
            <Box my={2}>
              <Divider />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <strong>Amount: </strong>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <strong>
              {
                <NumberFormat
                  value={invoice.amount_cents / 100}
                  prefix={'$'}
                  displayType="text"
                />
              }
            </strong>
          </Grid>
        </Grid>
      </Box>
    </div>
  </div>
);

export default InvoiceModal;
