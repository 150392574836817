import React, { useState } from 'react';

import {
  Alert,
  Divider,
  IconButton,
  Link,
  Grid,
  Box,
  Typography,
} from '@mui/material';

import { FieldArray, Formik } from 'formik';
import { ErrorMessage } from '@components/form/ErrorMessage/ErrorMessage';
import AvatarUploader from '@components/avatar/AvatarUploader';
import InputSet from '@components/form/input/InputSet/InputSet';
import TextField from '@components/TextField/TextField';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WebIcon from '@mui/icons-material/Web';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import * as yup from 'yup';
import CustomLinkFormFields from './CustomLinkFormFields';
import { Button } from '@components/button/Button';

import ThemeSelector from './ThemeSelector/ThemeSelector';
import CountriesSelect from '@components/CountriesSelect/CountriesSelect';
import { URL_REGEX } from '../../../utils/validationRegex';

const validationSchema = yup.object().shape({
  avatar_key: yup.string().required('avatar is required'),
  name: yup.string().required().min(3),
  slug: yup
    .string()
    .required()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Invalid character in URL. Only numbers, regular characters, and dashes are accepted.'
    )
    .min(3),
  description: yup.string().required().min(10),
  website_url: yup.string().nullable().matches(URL_REGEX, 'invalid url format'),
  twitter_url: yup.string().nullable().matches(URL_REGEX, 'invalid url format'),
  instagram_url: yup
    .string()
    .nullable()
    .matches(URL_REGEX, 'invalid url format'),
  facebook_url: yup
    .string()
    .nullable()
    .matches(URL_REGEX, 'invalid url format'),
  customLinks: yup.array().of(
    yup.object().shape({
      title: yup.string().required(),
      url: yup
        .string()
        .matches(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          'invalid url'
        )
        .required(),
    })
  ),
});

const BusinessProfileForm = ({ user, handleSubmit, initialValues }) => {
  return (
    <Formik
      initialValues={{
        theme: 'light',
        ...initialValues,
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit: handleFormSubmit,
        isSubmitting,
        isValidating,
      }) => {
        const keys = Object.keys(errors);
        if (keys.length > 0 && isSubmitting && !isValidating) {
          const selector = `form [name="${keys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.focus();
          }
        }
        return (
          <form onSubmit={handleFormSubmit}>
            <InputSet
              header={'Logo'}
              instructions={
                'This image will also be used for navigation. 350 x 350 recommended. JPEG, PNG, or GIF. 10MB file limit.'
              }
            >
              <>
                <AvatarUploader
                  metadata={{
                    email: user.email,
                    user_id: user.id,
                    source: 'business-profile',
                  }}
                  initialPreviewImage={
                    values.avatar_key &&
                    `https://ucarecdn.com/${values.avatar_key}/`
                  }
                  onSet={(uuid) => {
                    setFieldValue('avatar_key', uuid);
                  }}
                />
                {Boolean(errors.avatar_key) && (
                  <ErrorMessage error>{errors.avatar_key}</ErrorMessage>
                )}
              </>
            </InputSet>

            <InputSet header="Business Name">
              <TextField
                size="medium"
                variant="outlined"
                name="name"
                value={values.name || ''}
                fullWidth="true"
                label="Business Name"
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                autoComplete="off"
              />
            </InputSet>

            <InputSet
              header="Custom URL"
              instructions={
                'Personalize the URL for your business profile. No spaces. Use dashes between words'
              }
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={2}>
                  {initialValues.id ? (
                    <Link
                      href={`${window.location.origin}/${values.slug}`}
                      target="_blank"
                    >
                      www.waitlistr.com/
                    </Link>
                  ) : (
                    'www.waitlistr.com/'
                  )}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    label="your-custom-url"
                    name="slug"
                    value={values.slug || ''}
                    onChange={handleChange}
                    error={Boolean(errors.slug)}
                    helperText={errors.slug}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </InputSet>

            <InputSet
              header="Business Description"
              instructions="Tell us a bit about what you do"
            >
              <TextField
                size="medium"
                variant="outlined"
                fullWidth="true"
                placeholder="Description"
                multiline
                name="description"
                value={values.description || ''}
                onChange={handleChange}
                error={Boolean(errors.description)}
                helperText={errors.description}
                autoComplete="off"
              />
            </InputSet>

            <InputSet
              header="Web & Social Links (optional)"
              instructions="Enter the full URLs for each link you want to show on your profile page"
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                  <WebIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <TextField
                    placeholder="https://www.your-website.com"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="website_url"
                    value={values.website_url || ''}
                    onChange={handleChange}
                    error={Boolean(errors.website_url)}
                    helperText={errors.website_url}
                  />
                </Grid>

                <Grid item xs>
                  <TwitterIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <TextField
                    placeholder="https://twitter.com/username"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="twitter_url"
                    value={values.twitter_url || ''}
                    onChange={handleChange}
                    error={Boolean(errors.twitter_url)}
                    helperText={errors.twitter_url}
                  />
                </Grid>

                <Grid item xs>
                  <InstagramIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <TextField
                    placeholder="https://instagram.com/username"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="instagram_url"
                    value={values.instagram_url || ''}
                    onChange={handleChange}
                    error={Boolean(errors.instagram_url)}
                    helperText={errors.instagram_url}
                  />
                </Grid>

                <Grid item xs>
                  <FacebookIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <TextField
                    placeholder="https://facebook.com/page"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="facebook_url"
                    value={values.facebook_url || ''}
                    onChange={handleChange}
                    error={Boolean(errors.facebook_url)}
                    helperText={errors.facebook_url}
                  />
                </Grid>
              </Grid>
            </InputSet>

            <InputSet
              header="Custom Links (optional)"
              instructions="Add links to anything you want your customers to see"
            >
              <Box mt={2}>
                <FieldArray
                  name="custom_links"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.custom_links?.map((customLink, index) => (
                        <Box
                          mb={2}
                          style={{
                            background: '#e5e5e5',
                            border: '1px solid #dcdcdc',
                            padding: '1rem',
                          }}
                        >
                          <CustomLink
                            data={customLink}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                            }}
                            onUpdate={(values) => {
                              arrayHelpers.replace(index, values);
                            }}
                            errors={errors}
                          />
                        </Box>
                      ))}
                      <Box
                        mb={2}
                        style={{
                          background: '#e5e5e5',
                          border: '1px solid #dcdcdc',
                          padding: '1rem',
                        }}
                      >
                        <CustomLinkFormFields
                          onSubmit={(data) => arrayHelpers.push(data)}
                        />
                      </Box>
                    </Box>
                  )}
                />
              </Box>
            </InputSet>

            <InputSet header="Phone Number (optional)">
              <TextField
                size="medium"
                variant="outlined"
                name="phone_number"
                value={values.phone_number || ''}
                onChange={handleChange}
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number}
                autoComplete="off"
                type="tel"
              />
            </InputSet>

            <InputSet header="Address (optional)">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Address"
                    name="address"
                    value={values.address || ''}
                    onChange={handleChange}
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Address line two"
                    name="address_two"
                    value={values.address_two || ''}
                    onChange={handleChange}
                    error={Boolean(errors.address_two)}
                    helperText={errors.address_two}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="City"
                    name="city"
                    value={values.city || ''}
                    onChange={handleChange}
                    error={Boolean(errors.city)}
                    helperText={errors.city}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="State/Province"
                    name="state"
                    value={values.state || ''}
                    onChange={handleChange}
                    error={Boolean(errors.state)}
                    helperText={errors.state}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    placeholder="Postal Code"
                    name="postal_code"
                    value={values.postal_code || ''}
                    onChange={handleChange}
                    error={Boolean(errors.postal_code)}
                    helperText={errors.postal_code}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CountriesSelect
                    selectedCountryId={values.country_id}
                    onChange={(e, value) =>
                      setFieldValue('country_id', value.id)
                    }
                    label="Country"
                  />
                </Grid>
              </Grid>
            </InputSet>

            <InputSet header="Hours of Operation (optional)">
              {[
                'Saturday',
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
              ].map((weekDay) => (
                <Box mt={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} sm={2}>
                      <Box component="span">{weekDay}</Box>
                    </Grid>
                    <Grid item xs={9} sm={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder="hours"
                        onChange={handleChange}
                        name={`hours.${weekDay.toLowerCase()}`}
                        value={
                          values.hours
                            ? values.hours[weekDay.toLowerCase()]
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </InputSet>

            <InputSet
              header="Theme"
              instructions="Select a theme to fit your business"
            >
              <Box ml={2}>
                <ThemeSelector
                  value={values.theme}
                  handleChange={handleChange}
                />
              </Box>
            </InputSet>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              fullWidth
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {initialValues.id ? 'Save Changes' : 'Save Business Profile'}
            </Button>

            <Box my={4}>
              <Divider />
            </Box>
            {initialValues.id ? (
              <Box>
                <Button
                  variant="contained"
                  color="grey"
                  size="large"
                  href={`${window.location.origin}/${values.slug}`}
                  fullWidth
                >
                  View Business Lander
                </Button>
              </Box>
            ) : (
              <Box>
                <Alert severity="info">
                  <Typography>
                    Save your business profile, then you can view and share it.
                  </Typography>
                </Alert>
              </Box>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

const CustomLink = ({ data, onDelete, onUpdate }) => {
  const [editing, setEditing] = useState(false);

  return (
    <Box>
      {editing ? (
        <CustomLinkFormFields
          data={data}
          onSubmit={(values) => {
            onUpdate(values);
            setEditing(false);
          }}
        />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={10}>
            <Link target="_blank" href={data.url}>
              <strong>{data.name}</strong>
            </Link>
            <Box ml={2} component="span" style={{ color: '#9a9a9a' }}>
              {data.url}
            </Box>
          </Grid>
          <Grid item xs>
            <Box component="span">
              <IconButton
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Box component="span">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

BusinessProfileForm.defaultProps = {
  initialValues: {},
};

export default BusinessProfileForm;
