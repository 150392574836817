import { Box, Chip } from '@mui/material';
import React from 'react';

const Subcategory = ({ category, selected, onChange }) => {
  const isCurrentlySelected = selected?.id === category.id;

  return (
    <Chip
      label={category.name}
      variant={category.id === selected?.id ? 'selected' : 'outlined'}
      clickable
      onClick={() =>
        isCurrentlySelected ? onChange(null) : onChange(category)
      }
      sx={{ mr: 1 }}
    />
  );
};

const Subcategories = ({ topCategory, selected, onChange }) => {
  if (!topCategory || topCategory.subcategories.length === 0) {
    return null;
  }

  return (
    <Box my={2}>
      <p className="Input-instructions">
        <strong>Choose a subcategory if one applies</strong>
      </p>

      <Box>
        {selected && (
          <input
            type="hidden"
            name="waitlist[subcategory_id]"
            value={selected.id}
          />
        )}
        {topCategory.subcategories.map((category) => (
          <Subcategory
            key={`subcategory-${category.id}`}
            category={category}
            selected={selected}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Subcategories;
