import { Box, Divider, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';

const boxcolor = grey[200];

const PaymentModal = ({ payment, member }) => (
  <Box sx={{ width: '100%', maxWidth: 380 }}>
    <Typography variant="h6">Payment</Typography>
    <Box my={2}>
      <Divider />
    </Box>

    <Box my={2}>
      Status: {_.startCase(payment.status)}
      <Typography variant="subtitle1">From: {member.user.username}</Typography>
      <Typography variant="subtitle1">
        Paid on{' '}
        {moment(payment.created_at).utc().format('MMM. Do YYYY, h:mm:ss a')}
      </Typography>
    </Box>

    <Box sx={{ backgroundColor: boxcolor }} p={2}>
      <Stack spacing={2}>
        <Box>
          <strong>Description:</strong>
          <br />
          {payment.description}
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box>Gross: </Box>
          <Box>
            {
              <NumberFormat
                value={payment.amount_cents / 100}
                prefix={'$'}
                displayType="text"
              />
            }
          </Box>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box>Fee:</Box>
          <Box>
            {
              <NumberFormat
                value={payment.application_fee_cents / 100}
                prefix={'$'}
                displayType="text"
              />
            }
          </Box>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box>
            <strong>Net: </strong>
          </Box>
          <Box>
            <strong>
              {
                <NumberFormat
                  value={payment.net_cents / 100}
                  prefix={'$'}
                  displayType="text"
                />
              }
            </strong>
          </Box>
        </Stack>
      </Stack>
    </Box>
  </Box>
);

export default PaymentModal;
