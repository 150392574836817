import TextField from '@components/TextField/TextField';
import { Box, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { URL_REGEX } from '../../../utils/validationRegex';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  url: yup.string().matches(URL_REGEX, 'invalid url').required(),
});

const CustomLinkFormFields = ({ data, onSubmit }) => {
  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            actions.resetForm({ values: {} });

            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <>
                <Grid item xs={12} sm={5}>
                  <TextField
                    placeholder="Enter title"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="name"
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    value={values.name || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    placeholder="https://url"
                    size="medium"
                    variant="outlined"
                    fullWidth="true"
                    name="url"
                    onChange={handleChange}
                    error={Boolean(errors.url)}
                    helperText={errors.url}
                    value={values.url || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button variant="outlined" onClick={handleSubmit}>
                    {Object.keys(data).length > 0 ? 'Update' : 'Add Link'}
                  </Button>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Grid>
    </Box>
  );
};

CustomLinkFormFields.defaultProps = {
  data: {},
};

export default CustomLinkFormFields;
