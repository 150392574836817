import React from 'react';
import { withPage } from '@components/pages/Page';
import { Box, Link, Typography } from '@mui/material';

import Tabs from '@components/Tabs/Tabs';

import WebhooksTab from './WebhooksTab';
import EventsTab from './EventsTab';

import { useCurrentUser } from '@contexts/CurrentUserContext';

const IntegrationsTab = ({ id }) => {
  const { currentUser } = useCurrentUser();

  return (
    <div className="Layout-body Business-edit_profile">
      <div className="Layout-content Wrap">
        <Box mb={3}>
          <Typography variant="h1">Integrations</Typography>
        </Box>

        <Content user={currentUser} waitlistId={id} />
      </div>
    </div>
  );
};

const Content = ({ user, waitlistId }) => {
  const tabOptions = [
    { value: 'webhooks', label: 'Webhooks' },
    { value: 'events', label: 'Events' },
  ];

  if (!user) {
    return <></>;
  }
  if (user.features.integrations) {
    return (
      <Tabs initialSelectedTab="webhooks" options={tabOptions}>
        {({ selectedTab }) =>
          selectedTab === 'events' ? (
            <EventsTab />
          ) : (
            <WebhooksTab waitlistId={waitlistId} />
          )
        }
      </Tabs>
    );
  } else {
    return (
      <div>
        <p class="u-bigger">
          Would you like to send your list member data to another application? &nbsp;
          <Link
            underline="hover"
            onClick={() => {
              window.open('/plans', '_blank');
            }}
          >
            {'Sign up for a plan that includes Webhooks!'}
          </Link>
        </p>
        <p>
          If you are new to webhooks, take a look at some of these articles
          we've created:
        </p>
        <ul>
          <li>
            <a
              href="https://waitlist.us/using-webhooks-to-help-automate-your-business/"
              target="_blank"
            >
              How can using webhooks help you automate your business?
            </a>
          </li>
          <li>
            <a
              href="https://waitlist.us/webhooks-versus-apis-a-simple-explanation/"
              target="_blank"
            >
              Webhooks versus APIs: A Simple Explanation
            </a>
          </li>
        </ul>
      </div>
    );
  }
};

export default withPage(IntegrationsTab);
