import { IconButton, Modal, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalWrap = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ModalContent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3, 4, 4),
  margin: theme.spacing(2),
  position: 'relative',
  overflow: 'auto',
  maxHeight: '90vh',
}));

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute !important',
  top: theme.spacing(3),
  right: theme.spacing(4),
  height: 35,
  width: 35,
}));
