import React from "react";

const InputSet = ({ header, instructions, children }) => (
  <div className="Input-set">
    {(header || instructions) && (
      <div className="Input-header">
        {header && <h2 className="Input-title">{header}</h2>}
        {instructions && <p className="Input-instructions">{instructions}</p>}
      </div>
    )}
    {children}
  </div>
);

export default InputSet;
