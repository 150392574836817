import { FormHelperText } from '@mui/material';
import React from 'react';
import { SelectStyled } from './SelectField.styled';

const SelectField = ({ children, value, onChange, helperText, ...rest }) => (
  <>
    <SelectStyled value={value} onChange={onChange} {...rest}>
      {children}
    </SelectStyled>
    {helperText && (
      <FormHelperText error variant="outlined">
        {helperText}
      </FormHelperText>
    )}
  </>
);

SelectField.propTypes = {};

export default SelectField;
