import { theme } from "../../themes/default";

const styles = {
  avatarRemove: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
    'span': {
      width: "126px",
      height: "126px",
      borderRadius: "100%",
      color: theme.palette.primary.contrastText,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  avatarImage: {
    width: "126px",
    height: "126px",
    borderRadius: "100%",
    overflow: "hidden",
    img: {
      objectFit: "cover",
      width: "100%",
      height: "126px",
    },
  },
  loadingIndicator: {
    position: "absolute",
  },
  avatarPreview: {
    position: "relative",
  },
  avatarMedium: {
    borderSize: "2px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey.medium,
    width: "126px",
    height: "126px",
    borderRadius: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    background: theme.palette.primary.main,
    width: "126px",
    height: "126px",
    cursor: "pointer",
    textAlign: "center",
    transition: "all 0.5s",
  },
};

export default styles;
