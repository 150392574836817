import _ from 'lodash';

export const BASE_URL = process.env.API_URL;
export const API_URL = `${BASE_URL}/api/v2`;

export const transformData = (data) => {
  if (!_.isObject(data)) {
    return data;
  } else if (_.isArray(data)) {
    return data.map((v) => transformData(v));
  }
  return _.reduce(
    data,
    (r, v, k) => {
      return {
        ...r,
        [_.camelCase(k)]: transformData(v),
      };
    },
    {}
  );
};
