import React from 'react';
import { TextFieldStyled } from './TextField.styled';

const TextField = ({ ...rest }) => {
  return <TextFieldStyled {...rest} />;
};

TextField.defaultProps = {
  size: 'medium',
};

export default TextField;
