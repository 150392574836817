import { Box, CircularProgress, FormLabel, IconButton } from '@mui/material';
import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import { UploadClient } from '@uploadcare/upload-client';

import {
  AvatarStyled,
  AvatarUploaderStyled,
  AvatarEditIconStyled,
} from './Avatar.styled';

const client = new UploadClient({
  publicKey: process.env.UPLOADCARE_PUBLIC_KEY,
});

const AvatarUploader = ({ metadata, initialPreviewImage, onSet }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState(initialPreviewImage);

  const uploadFile = (file) => {
    setIsUploading(true);
    client.uploadFile(file, { metadata }).then((response) => {
      setIsUploading(false);
      onSet(response.uuid);
    });
  };

  return (
    <AvatarUploaderStyled>
      <Box>
        <FormLabel>
          <input
            type="file"
            id="btn-upload"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e) => {
              setImage(URL.createObjectURL(e.target.files[0]));
              uploadFile(e.target.files[0]);
            }}
          />
          {image ? (
            <AvatarEditIconStyled component="span">
              <EditIcon />
            </AvatarEditIconStyled>
          ) : (
            <IconButton component="span">
              <PhotoCameraIcon />
            </IconButton>
          )}
        </FormLabel>
      </Box>
      {isUploading && <CircularProgress sx={{ position: 'absolute' }} />}
      {image && (
        <AvatarStyled>
          <img src={image} />
        </AvatarStyled>
      )}
    </AvatarUploaderStyled>
  );
};
AvatarUploader.defaultProps = {
  metadata: {},
};

export default AvatarUploader;
