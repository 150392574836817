import { CircularProgress } from '@mui/material';
import React from 'react';
import { RoundedActionButtonStyled } from './Button.styled';

export const RoundedActionButton = ({
  children,
  isLoading,
  disabled,
  ...rest
}) => {
  return (
    <RoundedActionButtonStyled
      {...rest}
      component="span"
      size="small"
      disabled={disabled}
    >
      {isLoading ? <CircularProgress size={22} /> : children}
    </RoundedActionButtonStyled>
  );
};

RoundedActionButton.defaultProps = {
  isLoading: false,
};
