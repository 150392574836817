import React from 'react';
import { SelectStyled } from './SelectField.styled';

const SelectField = ({ children, value, onChange, ...rest }) => (
  <SelectStyled {...rest} value={value} onChange={onChange}>
    {children}
  </SelectStyled>
);

SelectField.propTypes = {};

export default SelectField;
