import React from 'react';

import { Alert, Box, Typography, Divider } from '@mui/material';
import { Button } from '@components/button/Button';
import { useDisqualifyMember } from '@fetch-components/members';

const DisqualifyMemberModal = ({ member, waitlist }) => {
  const onSuccess = () => {};

  const mutation = useDisqualifyMember({ waitlistId: waitlist.id, onSuccess });

  return (
    <div style={{ width: '100%', maxWidth: 380 }}>
      <Typography variant="h6" align="center">
        Are you sure?
      </Typography>
      <Box my={2}>
        <Divider />
      </Box>
      {mutation.isError && <ErrorMessage message={'Something went wrong'} />}
      {mutation.isSuccess && (
        <SuccessMessage message={'Member disqualified successfully'} />
      )}
      {!mutation.isSuccess && (
        <div>
          <Typography variant="subtitle1" gutterBottom>
            You are about to disqualify <strong>{member.user.username}</strong>{' '}
            from <strong>"{waitlist.title}"</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Note:</strong> Disqualifying a list member will prevent them
            from being able to rejoin your list with the same email address.
            They will appear under "Removed Members."
          </Typography>

          <Box mt={4}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              fullWidth
              isLoading={mutation.isLoading}
              disabled={mutation.isLoading}
              onClick={() => {
                mutation.mutate({
                  id: member.id,
                });
              }}
            >
              YES, DO IT
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default DisqualifyMemberModal;

const SuccessMessage = ({ message }) => (
  <Alert severity="success">{message}</Alert>
);
const ErrorMessage = ({ message }) => <Alert severity="error">{message}</Alert>;
