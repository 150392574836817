import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import {
  CheckCircleIconStyled,
  FormControlLabelStyled,
  ImageWrap,
  NameWrap,
  RadioStyled,
} from './Category.styled';

const Option = ({ checked, category, onClick }) => {
  return (
    <Stack spacing={0.75} alignItems="center" onClick={onClick}>
      <ImageWrap>
        <img
          src={category.active_icon_url}
          className={`${'img'} ${checked ? 'active' : 'inactive'}`}
        />
      </ImageWrap>
      <NameWrap className={`${checked ? 'active' : null}`}>
        {category.name}
      </NameWrap>
    </Stack>
  );
};

const Wrapper = ({ description, children }) => {
  if (description && description.length > 0) {
    return (
      <Tooltip arrow title={description}>
        {children}
      </Tooltip>
    );
  }
  return children;
};

const Category = ({ category, checked, onChange }) => {
  return (
    <Wrapper description={category.description}>
      <FormControlLabelStyled
        value={category.id}
        labelPlacement="bottom"
        control={<RadioStyled checkedIcon={<CheckCircleIconStyled />} />}
        label={
          <Option
            checked={checked}
            category={category}
            onClick={() => onChange(checked ? null : category)}
          />
        }
        checked={checked}
      />
    </Wrapper>
  );
};

export default Category;
