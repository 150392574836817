import React from "react";
import PropTypes from "prop-types";
import { Autocomplete as MuiAutocomplete } from "@mui/material";

const Autocomplete = ({ options, renderInput, ...rest }) => (
  <MuiAutocomplete options={options} renderInput={renderInput} {...rest} />
);

Autocomplete.propTypes = {};
Autocomplete.defaultProps = { options: [] };

export default Autocomplete;
