import React, { createContext, useContext, useState } from 'react';
import { useModal } from '@contexts/ModalContext';
import NewInvoiceModal from '@components/billing/invoice/NewInvoiceModal';
import PaymentModal from '@components/billing/payment/PaymentModal';
import InvoiceModal from '@components/billing/invoice/InvoiceModal';
import RemoveMembersModal from '@components/member/RemoveMembersModal';
import RemoveMemberModal from '@components/member/RemoveMemberModal';
import EmailMembersModal from '@components/member/EmailMembersModal';
import DisqualifyMemberModal from '@components/member/DisqualifyMemberModal';

const MembersContext = createContext();

export const useMembers = () => {
  const context = useContext(MembersContext);
  if (context === undefined) {
    throw new Error('useMembers must be used within a MembersProvider');
  }

  return context;
};

export const MembersProvider = ({ children }) => {
  const { showModal } = useModal();

  const showNewInvoiceModal = (waitlist, members) => {
    showModal(<NewInvoiceModal waitlist={waitlist} members={members} />);
  };

  const showPaymentModal = (member) => {
    showModal(
      <PaymentModal
        payment={
          member.billing_invoice?.billing_payment || member.billing_payment
        }
        member={member}
      />
    );
  };

  const showInvoiceModal = (member) => {
    showModal(
      <InvoiceModal invoice={member.billing_invoice} member={member} />
    );
  };

  const showRemoveMemberModal = (member) => {
    showModal(<RemoveMemberModal waitlist={member.waitlist} member={member} />);
  };

  const showRemoveMembersModal = (waitlist, members) => {
    showModal(<RemoveMembersModal waitlist={waitlist} members={members} />);
  };

  const showDisqualifyMemberModal = (member) => {
    showModal(
      <DisqualifyMemberModal waitlist={member.waitlist} member={member} />
    );
  };

  const showEmailMembersModal = (waitlist, members, scope = 'all') => {
    showModal(
      <EmailMembersModal members={members} scope={scope} waitlist={waitlist} />
    );
  };

  return (
    <MembersContext.Provider
      value={{
        showNewInvoiceModal,
        showPaymentModal,
        showInvoiceModal,
        showRemoveMemberModal,
        showRemoveMembersModal,
        showEmailMembersModal,
        showDisqualifyMemberModal,
      }}
    >
      {children}
    </MembersContext.Provider>
  );
};
