import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetCurrentUser } from '@fetch-components/users';

const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  const { data } = useGetCurrentUser();
  useEffect(() => {
    if (data) {
      setCurrentUser(data.user);
    }
  }, [data]);

  return (
    <CurrentUserContext.Provider value={{ currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
