import React from 'react';
import { useGetGiveawayWinners } from '@fetch-components/members';
import { usePickGiveawayWinner } from '@fetch-components/waitlists';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import GiveawayWinnersTable from '@components/member/GiveawayWinnersTable/GiveawayWinnersTable';
import { Button } from '@components/button/Button';

const GiveawayWinners = ({ waitlist }) => {
  const { isLoading, isFetching, data } = useGetGiveawayWinners({
    waitlistId: waitlist.id,
  });

  const mutation = usePickGiveawayWinner({ waitlistId: waitlist.id });

  return (
    <Box mb={4}>
      {isFetching && <LinearProgress />}
      <Paper elevation={2}>
        <Box pt={2} px={2}>
          <Typography variant="h3">Your Winners</Typography>
        </Box>
        <Box style={{ overflow: 'scroll' }}>
          <GiveawayWinnersTable
            waitlist={waitlist}
            data={data?.waitlist_members}
          />
        </Box>
        {!isLoading && (
          <Box p={2}>
            <Button
              variant="contained"
              color="secondary"
              isLoading={mutation.isLoading}
              disabled={mutation.isLoading}
              onClick={() => {
                mutation.mutate();
              }}
            >
              {data?.waitlist_members && data.waitlist_members.length > 0
                ? 'Select Another Random Winner'
                : 'Select a random winner'}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default GiveawayWinners;
