function CreditCardsController(stripe) {
  var controller = this;
  this.stripe = stripe;
  this.creditCardForm = $("form#credit_card_form");
  creditCardFormMessages = new FormMessages(this.creditCardForm);
  this.submitButtonLoader = new Loader(".submitButtonLoader");
  this.submitButtonLoader.hide();

  setUpStripeForm();

  function setUpStripeForm() {
    const stripeElements = controller.stripe.elements();

    controller.cardNumber = stripeElements.create("cardNumber");
    controller.cardNumber.mount("#card-number");
    controller.cardExpiry = stripeElements.create("cardExpiry");
    controller.cardExpiry.mount("#card-expiry");
    controller.cardCvc = stripeElements.create("cardCvc");
    controller.cardCvc.mount("#card-cvc");
    controller.postalCode = stripeElements.create("postalCode");
    controller.postalCode.mount("#postal-code");
  }

  $("form#credit_card_form").on("submit", function (event) {
    event.preventDefault();

    controller.stripe
      .createToken(controller.cardNumber)
      .then(function (response) {
        if (response.error) {
          const submitButton = controller.creditCardForm.find(
            'button[type="submit"]'
          );

          creditCardFormMessages.addError(response.error.message);
          creditCardFormMessages.scrollToMessages();
          submitButton.removeAttr("disabled");

          return false;
        }

        $("#billing_credit_card_last_four_numbers").val(
          response.token.card.last4
        );
        $("#billing_credit_card_token").val(response.token.id);

        controller.createCreditCard();
      });

    return false;
  });

  this.createCreditCard = function () {
    controller.submitButtonLoader.show();
    controller.creditCardForm
      .find('button[type="submit"]')
      .attr("disabled", true);

    var data = {
      billing_credit_card: {
        token: $('input[name="billing_credit_card[token]"]').val(),
        holder_name: $('input[name="billing_credit_card[holder_name]"]').val(),
        last_four_numbers: $(
          'input[name="billing_credit_card[last_four_numbers]"]'
        ).val(),
      },
    };

    $.ajax({
      url: controller.creditCardForm.attr("action"),
      method: controller.creditCardForm.attr("method"),
      data: data,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    });
  };
}

module.exports = CreditCardsController;
