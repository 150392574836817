import TextField from '@components/TextField/TextField';
import { useListCountries } from '@fetch-components/countries';
import React from 'react';
import Autocomplete from '../Autocomplete/Autocomplete';

const CountriesSelect = ({ selectedCountryId, onChange, label }) => {
  const { isLoading, data } = useListCountries();

  return (
    <Autocomplete
      loading={isLoading}
      options={data}
      onChange={onChange}
      label={label}
      getOptionLabel={(option) => option.name}
      value={data ? data.find((c) => c.id === selectedCountryId) : null}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderInput={(params) => <TextField {...params} label="Countries" />}
    />
  );
};

CountriesSelect.propTypes = {};

export default CountriesSelect;
