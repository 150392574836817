import { createTheme } from '@mui/material/styles';
import { buttonTheme } from './components/button';
import { chipTheme } from './components/chip';
import { tooltipTheme } from './components/tooltip';

export const theme = createTheme({
  palette: {
    primary: {
      // blue palette
      light: '#59c2eb',
      main: '#0d8cbd',
      dark: '#005f83',
      contrastText: '#fff',
    },
    secondary: {
      // purple palette
      light: '#dfc8f5',
      main: '#c08eef',
      dark: '#b478ec',
      contrastText: '#fff',
    },
    tertiary: {
      // green palette
      main: 'rgb(86, 165, 91)',
      light: 'rgb(224, 247, 226)',
      dark: 'rgb(45, 128, 49)',
      contrastText: '#fff',
    },
    grey: {
      // grey palette
      main: '#dcdcdc',
      light: '#e5e5e5',
      dark: '#C7C7C8',
      contrastText: '#5c5c5c',
      // custom values
      xlight: '#FAFAFA',
      neutral: '#dcdcdc',
      medium: '#989898',
      xdark: '#404040',
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1.25rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontSize: '1.25rem',
    },
  },
  toggle: {
    thumbOnColor: 'yellow',
    trackOnColor: 'red',
  },
});

theme.components = {
  MuiButton: buttonTheme(theme),
  MuiChip: chipTheme(theme),
  MuiTooltip: tooltipTheme(theme),
};
