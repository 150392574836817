const App = require("../app");

function WaitlistViewController(list, membership) {
  var waitlistViewController = this;
  window.waitlistViewController = waitlistViewController;
  this.list = list;
  this.membership = membership;
  // this.shareToEmailsModal = new WaitlistrModal($("#share-to-email-modal"));
  this.inviteMembersModal = new WaitlistrModal($("#invite-members-modal"));
  this.inviteMembersLoader = new Loader($("#invite-members-modal .Loader"));

  $(document).ready(function () {
    Facebook.init();
  });

  $(".Slider").slick({
    dots: true,
    speed: 500,
  });

  this.leaveListModal = new WaitlistrModal("#leave-list-modal");
  $(document).on("click", "#leave-list-link", function () {
    waitlistViewController.leaveListModal.show();

    return false;
  });

  $(document).on("click", "#navList", function () {
    $("#waitlistMembers .Loader").show();
  });

  this.onOpenCloseListModal = function (event) {
    $("#close-list-modal").toggleClass("Modal--show");
  };

  $(document).on("click", ".toggleEmbed", function () {
    $(this).closest(".Modal").removeClass("Modal--show");
  });

  $(document).on(
    "keyup",
    '.Waitlist-comment_add_reply textarea[name="waitlist_comment[content]"]',
    function (e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        $(".Waitlist-comment_add_reply form").submit();
      }
    }
  );

  $(document).on("submit", ".Waitlist-comment_add_reply form", function (e) {
    $(this).find('input[type="submit"]').attr("disabled", true);
  });

  $(".removeCommentLoader").hide();
  $(".editCommentLoader").hide();

  $(document).on("click", ".removeCommentLink", function (e) {
    $(this).find(".removeCommentLoader").show();
  });

  $(document).on("click", ".editCommentLink", function (e) {
    $(this).find(".editCommentLoader").show();
  });

  $(document).on("click", "#open-close-list-modal", this.onOpenCloseListModal);

  $(document).on("click", "#open-invite-members-modal", function () {
    waitlistViewController.inviteMembersModal.show();
  });

  $(document).on(
    "click",
    '#invite-members-modal input[type="submit"]',
    function () {
      waitlistViewController.inviteMembersLoader.show();

      $("#invite-members-form").submit();
    }
  );

  this.onOpenReopenListModal = function (event) {
    $("#reopen-list-modal").toggleClass("Modal--show");
  };

  // $(document).on('click', '.toggleEmbed', function() {
  //   $(this).closest('.Modal').removeClass('Modal--show');
  // });

  $(document).on(
    "click",
    "#open-reopen-list-modal",
    this.onOpenReopenListModal
  );

  $(document).on("click", ".shareIcons .buttonFacebook", function (e) {
    FB.ui(
      {
        method: "share",
        href: listUrlWithReferral("facebook"),
        //quote: listShareQuote()
      },
      function (response) {
        if (response !== undefined) {
          saveNewShare("facebook");
        }
      }
    );

    return false;
  });

  $(document).on("click", ".shareUpdate .buttonFacebook", function (e) {
    var update_quote = $(this).attr("data-update");
    FB.ui(
      {
        method: "share",
        href: listUrlWithReferral("facebook"),
        quote: update_quote,
      },
      function (response) {
        if (response !== undefined) {
          saveNewShare("facebook");
        }
      }
    );

    return false;
  });

  $(document).on("click", ".favorite-button", function (e) {
    var id = $(this).data("list-id");
    var button = this;

    var favoriteFunction = function () {
      var url = "/waitlists/favorites";
      var method = "POST";
      var data = {
        favorite_waitlist: {
          waitlist_id: id,
        },
      };

      if ($(button).hasClass("selected")) {
        method = "DELETE";
        var url = "/waitlists/favorites/remove";
      }

      $.ajax({
        url: url,
        method: method,
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: data,
      });
    };

    if (!!localStorage.getItem("authentication_token")) {
      favoriteFunction.call();
    } else {
      waitlistViewController.afterSignInFunction = favoriteFunction;
      $("#signInModal").addClass("Modal--show");
    }

    e.preventDefault();
    return false;
  });

  $(document).on("ajax:success", "#new_user", function (event) {
    $("#signInModal .closeModal").click();
    waitlistViewController.afterSignInFunction.call();
  });

  $(document).on("ajax:error", "#new_user", function (event) {
    const { status, error, redirect_to } = event.detail[0];

    if (status === 302) {
      window.location = redirect_to;
    } else {
      $(".login .error-message").text(error);
      $(".login #user_login").addClass("Error");
      $(".login #user_password").addClass("Error");
    }
  });

  if (twttr != undefined) {
    twttr.events.bind("tweet", function (ev) {
      saveNewShare("twitter");
    });
  }

  // $(document).on("click", ".shareIcons .shareToEmail", function(e) {
  //   waitlistViewController.shareToEmailsModal.show();
  //
  //   return false;
  // });

  $(document).on(
    "click",
    '#invite-members-form input[type="submit"]',
    function (e) {
      waitlistViewController.inviteMembersLoader.show();

      return false;
    }
  );

  $(document).on(
    "ajax:success",
    "#invite-members-form",
    function (event) {
      const [data, _status, _xhr] = event.detail;

      if (data.errors !== undefined && data.errors.length > 0) {
        console.log("Error");
        return;
      }
      invited();
      waitlistViewController.inviteMembersLoader.hide();
      waitlistViewController.inviteMembersModal.hide();
    }
  );

  $(document).on(
    "ajax:success",
    "#share-to-emails-form",
    function (event) {
      const [data, _status, _xhr] = event.detail;

      if (data.errors !== undefined && data.errors.length > 0) {
        console.log("Error");
        return;
      }
      waitlistShared();
      waitlistViewController.shareToEmailsModal.hide();
    }
  );

  function saveNewShare(destination) {
    var url = pathToShare();

    var params = {
      share: {
        destination: destination,
        membership_id: waitlistViewController.membership.id,
      },
      slug: waitlistViewController.list.ownerSlug,
      waitlist_id: waitlistViewController.list.id,
      member_id: waitlistViewController.membership.memberId,
    };

    ajaxize({
      url: url,
      data: params,
      method: "POST",
      done: function (response) {
        waitlistShared();
      },
    });
  }

  function listUrlWithReferral(utmSource) {
    return (
      App.DOMAIN_WITH_PROTOCOL +
      "/lists/" +
      waitlistViewController.list.ownerSlug +
      "/" +
      waitlistViewController.list.slug +
      "?referral=" +
      waitlistViewController.membership.referralCode +
      "&utm_source=" +
      utmSource
    );
  }

  function listShareQuote() {
    return "I joined the waitlist '" + list.title + "'. Check it out.";
  }

  function waitlistShared() {
    //$('.notifications').html('<div class="Notice alert-notice">Thanks for sharing!</div>').delay(5000).fadeOut("slow");
  }

  function invited() {
    alert("Invited");
  }

  function pathToShare() {
    memberId = waitlistViewController.membership.memberId;
    controllerList = waitlistViewController.list.id;

    if (memberId) {
      return "/lists/" + controllerList + "/members/" + memberId + "/shares";
    } else {
      return "/lists/" + controllerList + "/shares";
    }
  }
}

module.exports = WaitlistViewController;
