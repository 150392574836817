import MembersTable from '@components/member/MembersTable/MembersTable';
import TablePagination from '@components/TablePagination/TablePagination';
import { useCurrentUser } from '@contexts/CurrentUserContext';
import { MembersProvider } from '@contexts/waitlist/ManagePage/MembersContext';
import { useCreateExport } from '@fetch-components/exports';
import { useListMembers } from '@fetch-components/members';
import {
  AttachMoney as AttachMoneyIcon,
  Check as CheckIcon,
  Email as EmailIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  MailOutline as MailOutlineIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Filter from './Filter';

import EmailMembersModal from '@components/member/EmailMembersModal';
import { useMembers } from '@contexts/waitlist/ManagePage/MembersContext';
import { useModal } from '@contexts/ModalContext';
import GiveawayWinners from './GiveawayWinners';
import EmailMembers from './EmailMembers';

const Members = ({ waitlist }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState({
    field: 'position',
    direction: 'asc',
  });

  const [scope, setScope] = useState('current');

  let requestFilters = { ...filters, scope: scope };
  if (scope === 'current') {
    requestFilters.giveaway_winner = false;
  }
  const { isFetching, isLoading, data } = useListMembers({
    listId: waitlist.id,
    filters: requestFilters,
    page,
    perPage,
    sortBy: sortBy.field,
    order: sortBy.direction,
  });

  const { currentUser } = useCurrentUser();

  const { showNewInvoiceModal, showEmailMembersModal, showRemoveMembersModal } =
    useMembers();

  return (
    <Box>
      <Box pb={2} mb={1}>
        <ScopeToolbar
          currentUser={currentUser}
          waitlist={waitlist}
          scope={scope}
          onChange={(scope) => {
            setScope(scope);
          }}
          currentMembersCount={data?.meta?.current_members_count || 0}
          pastMembersCount={data?.meta?.past_members_count || 0}
          allMembersCount={data?.meta?.all_members_count || 0}
        />
      </Box>

      {waitlist?.list_type === 'giveaway' && scope !== 'removed' && (
        <GiveawayWinners waitlist={waitlist} />
      )}

      <Paper elevation={2}>
        <Filter
          waitlist={waitlist}
          isFiltering={
            isFetching &&
            data?.waitlist_members &&
            data.waitlist_members.length > 0
          }
          onFilter={(f) => {
            setPage(1);
            setFilters(f);
          }}
        />

        <TableContainer>
          {isFetching &&
            data?.waitlist_members &&
            data.waitlist_members.length > 0 && <LinearProgress />}

          <TableToolbar
            currentUser={currentUser}
            selectedDataCount={selectedMembers.length}
            scope={scope}
            onInvoiceClick={() => {
              showNewInvoiceModal(waitlist, selectedMembers);
            }}
            onRemoveClick={() => {
              showRemoveMembersModal(waitlist, selectedMembers);
            }}
            onSendEmailClick={() => {
              showEmailMembersModal(waitlist, selectedMembers);
            }}
          />

          <MembersTable
            waitlist={waitlist}
            onSelect={(selectedRows) => {
              setSelectedMembers(selectedRows);
            }}
            onSort={(sort) => {
              setSortBy(sort);
            }}
            isLoading={isLoading}
            data={data?.waitlist_members || []}
            scope={scope}
          />

          <TablePagination
            rowsCount={data?.meta?.total || 0}
            perPage={perPage}
            page={page - 1}
            onPageChange={setPage}
            onPerPageChange={setPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
};

const ScopeToolbar = ({
  currentUser,
  waitlist,
  scope,
  onChange,
  currentMembersCount,
  pastMembersCount,
  allMembersCount,
}) => {
  const { showModal } = useModal();

  const SendEmailModal = () => (
    <EmailMembersModal
      waitlist={waitlist}
      emailEntireList
      membersCount={allMembersCount}
    />
  );

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs>
        <ToggleButtonGroup
          value={scope}
          onChange={(_, value) => {
            onChange(value);
          }}
          exclusive
        >
          <ToggleButton value="current" color="primary">
            Current members ({currentMembersCount})
          </ToggleButton>
          <ToggleButton value="removed">
            Removed members ({pastMembersCount})
          </ToggleButton>
          <ToggleButton value="all">
            All members ({allMembersCount})
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs="auto">
        <Stack 
          direction={{ xs: "column", md: "row" }} 
          spacing={{ xs: 0, md: 2 }} 
          mt={{ xs: 2, md: 0 }}
        >
          <DownloadCsvFileButton waitlist={waitlist} />
          {(currentUser?.limits?.member_email_limit > 0 && (
            <>
              <EmailMembers waitlist={waitlist} />
              {/* <Button
                startIcon={<EmailIcon />}
                onClick={() => {
                  showModal(<SendEmailModal />);
                }}
              >
                Email List Members
              </Button> */}
            </>
          )) || (
            <Tooltip title="Upgrade your plan to enable emailing. Click to learn more.">
              <Button
                startIcon={<EmailIcon />}
                onClick={() => {
                  window.open('/plans', '_blank');
                }}
              >
                Email List Members
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const TableToolbar = ({
  currentUser,
  scope,
  selectedDataCount,
  onRemoveClick,
  onInvoiceClick,
  onSendEmailClick,
}) => {
  return (
    <Toolbar>
      {selectedDataCount > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {selectedDataCount} selected
        </Typography>
      ) : (
        <Typography variant="p" id="tableTitle" component="div">
          Viewing {_.startCase(_.camelCase(scope))} Members
        </Typography>
      )}

      {selectedDataCount > 0 ? (
        <Stack direction="row" spacing={2} sx={{ marginLeft: 2 }}>
          {scope !== 'removed' && (
            <Button
              variant="outlined"
              startIcon={<CheckIcon />}
              onClick={onRemoveClick}
            >
              Remove
            </Button>
          )}
          <Button
            variant="outlined"
            startIcon={<AttachMoneyIcon />}
            onClick={onInvoiceClick}
          >
            Invoice
          </Button>

          {(currentUser?.limits?.member_email_limit > 0 && (
            <Button
              variant="outlined"
              startIcon={<MailOutlineIcon />}
              onClick={onSendEmailClick}
            >
              Email
            </Button>
          )) || (
            <Tooltip title="Upgrade your plan to enable emailing. Click to learn more.">
              <Button
                variant="outlined"
                startIcon={<MailOutlineIcon />}
                onClick={() => {
                  window.open('/plans', '_blank');
                }}
              >
                Email
              </Button>
            </Tooltip>
          )}
        </Stack>
      ) : (
        <Tooltip title="Filter list" className="u-hide">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const DownloadCsvFileButton = ({ waitlist }) => {
  if (!waitlist) {
    return null;
  }

  const { currentUser } = useCurrentUser();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const mutation = useCreateExport({
    waitlistId: waitlist.id,
    onSuccess: () => {
      enqueueSnackbar(
        'Download started. When your download is ready we will send you an email with a link to your file. Can take up to 30 minutes.',
        {
          variant: 'success',
        }
      );
    },
    onError: (response) => {
      if (response.status === 401) {
        enqueueSnackbar('You are not allowed to perform this action', {
          variant: 'error',
        });
      }
    },
  });

  if (currentUser?.features?.download) {
    return (
      <Button
        startIcon={<GetAppIcon />}
        onClick={() => {
          mutation.mutate();
        }}
      >
        Download CSV file
      </Button>
    );
  } else {
    return (
      <Tooltip title="Upgrade your plan to enable this feature. Click to learn more.">
        <Button
          startIcon={<GetAppIcon />}
          onClick={() => {
            window.open('/plans', '_blank');
          }}
        >
          Download CSV file
        </Button>
      </Tooltip>
    );
  }
};

const WrapperMembers = (props) => (
  <MembersProvider>
    <Members {...props} />
  </MembersProvider>
);

export default WrapperMembers;
