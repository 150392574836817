import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

export const ManagePageHeader = ({ isLoading, waitlist }) => (
  <Box pb={4}>
    {!isLoading && waitlist.status_description === 'archived' && (
      <Box className="Notification Notification--gray" p={3}>
        <h3>This list is archived</h3>
        <p className="u-spaceBN">
          Some functionality may not be available to Archived lists. You can
          still view your list members and download their details. To manage the
          members of this list, go to your Dashboard and unarchive it first.
        </p>
      </Box>
    )}
    <Typography component="h1" variant="h1" gutterBottom>
      {isLoading ? <Skeleton /> : `Manage members of "${waitlist.title}"`}
    </Typography>
    <Typography>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          See info you've collected, remove list members once you've finished
          with them, send messages.&nbsp;
          <a
            href="https://waitlistr.freshdesk.com/support/solutions/articles/48001205098-your-manage-list-members-page"
            target="_blank"
          >
            Learn more about this page.
          </a>
        </>
      )}
    </Typography>
  </Box>
);
