export const chipTheme = (theme) => ({
  variants: [
    {
      props: { variant: 'selected' },
      style: {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#fd9103',
        ':hover': {
          backgroundColor: '#fd9103',
        },
      },
    },
  ],
});
