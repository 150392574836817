import React from 'react';
import { RoundedActionButton } from './RoundedActionButton';
import { Chat as ChatIcon } from '@mui/icons-material';

const RoundedChatButton = ({ isLoading, onClick }) => (
  <RoundedActionButton
    isLoading={isLoading}
    aria-label="start chat"
    onClick={onClick}
  >
    <ChatIcon fontSize="medium" />
  </RoundedActionButton>
);

export default RoundedChatButton;
