import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

export const RoundedActionButtonStyled = styled(IconButton)(({ theme }) => ({
  borderWidth: 1,
  borderColor: theme.palette.grey[400], //"#d6d6d6",
  borderStyle: 'solid',
  padding: 8,
  '&:hover': {
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,
    borderColor: 'transparent',
  },
}));

// export default StyledIconButton;

// const backgroundColor = (variant, theme) =>
//   ({
//     primary: theme.palette.primary.main,
//   }[variant] || 'somedefaultcolor');

// const hoverBackgroundColor = (variant, theme) =>
//   ({
//     primary: theme.palette.primary.contrastText,
//   }[variant] || 'somedefaultcolor');

// const color = (variant, theme) =>
//   ({
//     primary: theme.palette.primary.dark,
//   }[variant] || 'somedefaultcolor');

// export const StyledButton = styled(Button)(
//   ({ theme }) => `
//   background-color: ${(props) => backgroundColor(props.color, theme)};
//   color: ${(props) => color(props.color, theme)};
//   box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//   &:hover {
//     background-color: ${(props) => hoverBackgroundColor(props.color, theme)};
//   }
//   ${theme.breakpoints.up('sm')} {
//     font-size: 1.25rem;
//     padding: 14px 32px;
//   }
//   `
// );

// export const StyledPrimaryButton = styled(Button)(
//   ({ theme }) => `
//   background-color: ${theme.palette.primary.main};
//   color: ${theme.palette.primary.contrastText};
//   box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//   &:hover {
//     background-color: ${theme.palette.primary.dark};
//   }
//   ${theme.breakpoints.up("sm")} {
//     font-size: 1.25rem;
//     padding: 14px 32px;
//   }
//   `
// );

// export const StyledActionButton = styled(Button)(
//   ({ theme }) => `
//   padding-top: 10px;
//   padding-bottom: 10px;
//   background-color: ${theme.palette.action.selected};
//   color: ${theme.palette.grey.dark};
//   box-shadow: none;
//   &:hover {
//     background-color: ${theme.palette.action.hover};
//     box-shadow: none;
//   }
//   &:active {
//     background-color: ${theme.palette.action.active};
//     box-shadow: none;
//   }
// `
// );
