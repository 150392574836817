function PayInvoiceController(stripe) {
  var controller = this;
  this.stripe = stripe;
  controller.form = $("form#pay_invoice_form");
  this.formMessages = new FormMessages(controller.form);
  this.submitButtonLoader = new Loader("form#pay_invoice_form .Loader");

  setUpStripeForm();

  function setUpStripeForm() {
    const stripeElements = controller.stripe.elements();

    controller.cardNumber = stripeElements.create("cardNumber");
    controller.cardNumber.mount("#card-number");
    controller.cardExpiry = stripeElements.create("cardExpiry");
    controller.cardExpiry.mount("#card-expiry");
    controller.cardCvc = stripeElements.create("cardCvc");
    controller.cardCvc.mount("#card-cvc");
    controller.postalCode = stripeElements.create("postalCode");
    controller.postalCode.mount("#postal-code");
  }

  var onSubmitFormCallback = function (event) {
    event.preventDefault();

    const submitButton = controller.form.find('button[type="submit"]');

    submitButton.attr("disabled", true);
    controller.submitButtonLoader.show();

    if ($('input[name="credit_card_token"]').val().length == 0) {
      controller.stripe
        .createToken(controller.cardNumber)
        .then(function (response) {
          if (response.error) {
            controller.formMessages.addError(response.error.message);
            submitButton.removeAttr("disabled");
            controller.submitButtonLoader.hide();

            return false;
          }

          $('input[name="credit_card_token"]').val(response.token.id);

          controller.form.get(0).submit();
        });

      return false;
    }
  };

  $("form#pay_invoice_form").on("submit", onSubmitFormCallback);
}

module.exports = PayInvoiceController;
