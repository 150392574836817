const Sortable = require("Sortable/Sortable");
require("trumbowyg");

function WaitlistFormController(options) {
  this.maxFiles = options.maxFiles;
  this.maxCustomRequirementFields = options.maxCustomRequirementFields;
  var form = $("form.waitlistForm");
  var controller = this;
  // $('input[name="waitlist[visibility]"][value="public"]').prop('checked', true);

  $(document).on("change", "#requirements_full_name", function (e) {
    $("#waitlist_requirements_full_name").val($(this).is(":checked"));
  });
  $(document).on("change", "#requirements_mailing_address", function (e) {
    $("#waitlist_requirements_mailing_address").val($(this).is(":checked"));
  });
  $(document).on("change", "#requirements_phone_number", function (e) {
    $("#waitlist_requirements_phone_number").val($(this).is(":checked"));
  });

  $(document).on("change", "#yes_payemnt", function () {
    if ($(this).is(":checked")) {
      $(".paymentDetails").slideDown();
    } else {
      $(".paymentDetails").slideUp();
    }
  });

  $(document).on("change", 'input[name="accept_payments"]', function () {
    if ($('input[name="accept_payments"]:checked').val() === "yes") {
      $(".paymentDetails input").removeAttr("disabled");
      $(".paymentDetails textarea").removeAttr("disabled");
      $(".paymentDetails").slideDown();
    } else {
      $(".paymentDetails").slideUp();
      $(".paymentDetails input").attr("disabled", true);
      $(".paymentDetails textarea").attr("disabled", true);
    }
  });

  if ($('input[name="accept_payments"]:checked').val() === "yes") {
    $(".paymentDetails").slideDown();
  } else {
    $(".paymentDetails").slideUp();
  }

  $(document).on("click", "#addCustomRequirementLink", function () {
    var partialHtml = $(
      ".customRequirements .template .waitlistCustomRequirement"
    )
      .parent()
      .html();
    var id = new Date().getTime();
    partialHtml = partialHtml.split("[0]").join("[" + id + "]");
    partialHtml = partialHtml
      .split("waitlist_custom_requirements_attributes_0")
      .join("waitlist_custom_requirements_attributes_" + id);

    var div = $(partialHtml);

    $(div).appendTo(".customRequirements .list");
    $(div).slideDown("slow");
    $(div).find(".tokenfield").hide();

    showOrHideAddRequirementLink();

    return false;
  });

  function showOrHideAddRequirementLink() {
    if (
      $(
        '.customRequirements .list .waitlistCustomRequirement .active[value="true"]'
      ).length >= controller.maxCustomRequirementFields
    ) {
      $("#addCustomRequirementLink").fadeOut();
    } else {
      $("#addCustomRequirementLink").fadeIn();
    }
  }

  $(document).on("click", ".removeCustomRequirementLink", function () {
    if (
      $(this)
        .closest(".waitlistCustomRequirement")
        .find('input[name="persisted"]')
        .val() == "true"
    ) {
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".active")
        .val("false");
      $(this).closest(".waitlistCustomRequirement").data("removed", true);
      $(this)
        .closest(".waitlistCustomRequirement")
        .fadeOut(function () {
          showOrHideAddRequirementLink();
        });
    } else {
      $(this)
        .closest(".waitlistCustomRequirement")
        .fadeOut(function () {
          $(this).closest(".waitlistCustomRequirement").remove();
          showOrHideAddRequirementLink();
        });
    }

    return false;
  });

  $(document).on("click", ".option .remove", function () {
    $(this)
      .closest(".option")
      .fadeOut(function () {
        $(this).remove();
      });

    return false;
  });

  $(document).on("click", ".editCustomRequirementLink", function () {
    $(this)
      .closest(".waitlistCustomRequirement")
      .find(".form")
      .slideDown(function () {});

    return false;
  });

  $(document).on(
    "click",
    ".waitlistCustomRequirement .addNewOption",
    function () {
      var template = $(this)
        .closest(".waitlistCustomRequirement")
        .find(".optionTemplate")
        .html();
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".options .options-list")
        .append(template);

      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".options .options-list input:last")
        .focus();

      return false;
    }
  );

  $(document).on("change", ".customRequirementFieldType", function () {
    if (
      $(this).val() == "dropdown" ||
      $(this).val() == "radio" ||
      $(this).val() == "checkbox"
    ) {
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".options")
        .slideDown("slow");
    } else {
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".options")
        .slideUp("slow");
    }

    if ($(this).val() == "text" || $(this).val() == "textarea") {
      $(this)
        .closest(".waitlistCustomRequirement")
        .find('input[name*="[placeholder]"]')
        .parent()
        .slideDown("slow");
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".placeholderLabel")
        .slideDown("slow");
    } else {
      $(this)
        .closest(".waitlistCustomRequirement")
        .find('input[name*="[placeholder]"]')
        .parent()
        .slideUp("slow");
      $(this)
        .closest(".waitlistCustomRequirement")
        .find(".placeholderLabel")
        .slideUp("slow");
    }
  });

  if (
    $('input[name="waitlist[visibility]"]:checked').val() ===
    "invited_members_only"
  ) {
    $('input[name="waitlist[shareable]"]').parents(".Input-set").slideUp();
  } else {
    if (
      !$('input[name="waitlist[shareable]"]')
        .parents(".Input-set")
        .is(":visible")
    ) {
      $('input[name="waitlist[shareable]"]').parents(".Input-set").slideDown();
    }
  }

  $("#waitlist_price").maskMoney();

  $(document).on("submit", "form", function (event) {
    var submitButton = form.find('input[type="submit"]');
    submitButton.attr("disabled", true);
  });

  $.trumbowyg.svgPath = $(".html-editor").data("svg-icons-path");

  this.membersLimitChanged = function (e) {
    if (
      $('input[name="waitlist[autoclose_trigger]"]:checked').val() == "period"
    ) {
      $(".timeSelection").slideDown();
      $(".timeSelection input").removeAttr("disabled");
      var defaultStartDate = moment().format("YYYY-MM-DD");
      var defaultEndDate = moment().add(1, "month").format("YYYY-MM-DD");

      if ($(".timeSelection #waitlist_start_date").val().length == 0) {
        $(".timeSelection #waitlist_start_date").val(defaultStartDate);
      }
      if ($(".timeSelection #waitlist_end_date").val().length == 0) {
        $(".timeSelection #waitlist_end_date").val(defaultEndDate);
      }

      if ($(".timeSelection #waitlist_start_time").val().length == 0) {
        $(".timeSelection #waitlist_start_time").val("12:00");
      }

      if ($(".timeSelection #waitlist_end_time").val().length == 0) {
        $(".timeSelection #waitlist_end_time").val("12:00");
      }
    } else {
      $(".timeSelection").slideUp();
      $(".timeSelection input").val("");
      $(".timeSelection input").attr("disabled", true);
      $(".timeSelection #waitlist_start_date").val("");
      $(".timeSelection #waitlist_end_date").val("");
      $(".timeSelection #waitlist_start_time").val("");
      $(".timeSelection #waitlist_end_time").val("");
    }

    if (
      $('input[name="waitlist[autoclose_trigger]"]:checked').val() ==
      "members_limit"
    ) {
      $('input[name="waitlist[members_limit]"]').prop("disabled", false);
      $('input[name="waitlist[reopen_on_member_removal]"]').prop(
        "disabled",
        false
      );
    } else {
      $('input[name="waitlist[members_limit]"]').prop("disabled", true);
      $('input[name="waitlist[reopen_on_member_removal]"]').prop(
        "disabled",
        true
      );
    }
  };

  this.membersLimitChanged();

  $('input[name="waitlist[autoclose_trigger]"]').on("change", function (e) {
    controller.membersLimitChanged(e);
  });

  if ($('input[name="waitlist[list_type]"]').val() == "giveaway") {
    $(".termsAndConditions").slideDown();
    $(".paymentOptions").slideUp();
  } else {
    $(".termsAndConditions").slideUp();
    // $('.paymentOptions').slideDown();
  }
  $('input[name="waitlist[list_type]"]').on("change", function (e) {
    if ($(this).val() == "giveaway") {
      $(".termsAndConditions").slideDown();
      $('input[name="waitlist[payment_required]"]')
        .prop("checked", false)
        .change();
      $(".paymentOptions").slideUp();
    } else {
      $(".termsAndConditions").slideUp();
      $(".paymentOptions").slideDown();
    }
  });

  $(".html-editor").trumbowyg({
    removeformatPasted: true,
    autogrow: true,
    autogrowOnEnter: true,
    semantic: false,

    btnsDef: {
      image: {
        dropdown: ["insertImage", "noembed"],
        ico: "insertImage",
      },
    },

    btns: [
      ["formatting"],
      ["strong", "em", "del"],
      //['superscript', 'subscript'],
      // ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ["unorderedList", "orderedList"],
      ["horizontalRule"],
      ["link"],
      ["image"], // Our fresh created dropdown
      ["removeformat"],
      ["viewHTML"],
      ["fullscreen"],
    ],
  });

  form.validate({
    errorPlacement: function (errorMap, errorList) {},
    onsubmit: true,
    rules: {
      "waitlist[title]": {
        required: true,
      },
      "waitlist[description]": {
        required: true,
      },
      "waitlist[start_date]": {
        required: "#autoclose_period:checked",
      },
      "waitlist[end_date]": {
        required: "#autoclose_period:checked",
      },
      "waitlist[start_time]": {
        required: "#autoclose_period:checked",
      },
      "waitlist[end_time]": {
        required: "#autoclose_period:checked",
      },
      "waitlist[timezone]": {
        required: "#autoclose_period:checked",
      },
      "waitlist[price]": {
        required: "#accept_payments_yes:checked",
        min: 1,
      },
      ".Url-Field": {
        url: true,
      },
    },
    highlight: function (element) {
      $(element).addClass("Error");
    },
    unhighlight: function (element) {
      $(element).removeClass("Error");
      var submitButton = form.find('input[type="submit"]');
      submitButton.removeAttr("disabled");
    },
  });

  this.updateImageOrder = function () {
    $.each(
      $("#listImages").find(".Waitlist-thumbnail"),
      function (index, element) {
        ajaxize({
          method: "PUT",
          url: "/lists/" + listId + "/images/" + $(element).data("image-id"),
          data: {
            "waitlist_image[order]": index,
          },
          error: function () {
            console.log("Error updating image order");
          },
          done: function (response) {},
        });
      }
    );
  };

  this.applyCurrentTimezoneToTimezonesSelect = function () {
    var differenceFromUtc = -new Date().getTimezoneOffset() / 60;
    var timezoneInString = differenceFromUtc.toString();
    var signal = "+";

    if (timezoneInString.startsWith("-")) {
      signal = "-";
      timezoneInString = timezoneInString.replace("-", "");
    }

    if (timezoneInString.length == 1) {
      timezoneInString = "0" + timezoneInString;
    }

    timezoneInString = signal + timezoneInString + ":00";

    var selectedTimezone = $(
      $(
        'select[name="waitlist[timezone]"] option:contains(' +
          timezoneInString +
          ")"
      )[0]
    ).val();
    $('select[name="waitlist[timezone]"]').val(selectedTimezone);
  };

  var sortableElement = document.getElementById("listImages");
  var sortable = Sortable.create(sortableElement, {
    dataIdAttr: "data-order",
    onEnd: function (evt) {
      controller.updateImageOrder();
    },
  });

  $(document).on("change", 'input[name="waitlist[visibility]"]', function (e) {
    if (
      $('input[name="waitlist[visibility]"]:checked').val() ===
      "invited_members_only"
    ) {
      $('input[name="waitlist[shareable]"]').parents(".Input-set").slideUp();
    } else {
      if (
        !$('input[name="waitlist[shareable]"]')
          .parents(".Input-set")
          .is(":visible")
      ) {
        $('input[name="waitlist[shareable]"]')
          .parents(".Input-set")
          .slideDown();
      }
    }
  });

  if (!$('select[name="waitlist[timezone]"]').val()) {
    this.applyCurrentTimezoneToTimezonesSelect();
  }

  this.addImageToForm = function (imageId) {
    var html =
      '<input type="hidden" name="waitlist[image_ids][]" value="' +
      imageId +
      '" />';
    form.append(html);
  };

  uploader = new Uploader(form);
  uploader.init({ maxFiles: this.maxFiles });
  uploader.onUploadComplete(function (imageId) {
    controller.addImageToForm(imageId);
    $(form).find('input[type="submit"]').attr("disabled", false);
  });

  uploader.onFileRemoved(function (file) {
    form
      .find('input[name="waitlist[image_ids][]"][value="' + file.id + '"]')
      .remove();
  });

  uploader.onSending(function () {
    $(form).find('input[type="submit"]').attr("disabled", true);
  });

  var listId = $('input[name="list_id"]').val();

  if (listId) {
    ajaxize({
      method: "GET",
      url: "/lists/" + listId + "/images",
      error: function () {
        console.log("Error loading images");
      },
      done: function (response) {
        $.each(response.images, function (index, image) {
          uploader.addFile(image.id, image.thumb_url);
          controller.addImageToForm(image.id);
        });
      },
    });
  }
}

module.exports = WaitlistFormController;
