import React, { useState } from 'react';
import { Button } from '@components/button/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useListFilterSets } from '@fetch-components/filterSets';
import * as yup from 'yup';
import { Formik } from 'formik';
import TextField from '@components/TextField';
import { useCreateFilterSet } from '@fetch-components/filterSets';
import { ErrorMessage } from '@components/form/ErrorMessage/ErrorMessage';
import { RoundedActionButton } from '@components/button/RoundedActionButton';
import { useDeleteFilterSet } from '@fetch-components/filterSets';

const FilterSets = ({ waitlist, filters, onApply }) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const { isLoading, data } = useListFilterSets({ waitlistId: waitlist.id });

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={(event) => {
          setAnchorElement(event.currentTarget);
        }}
        isLoading={isLoading}
      >
        Saved Searches
      </Button>
      <Popover
        open={Boolean(anchorElement)}
        onClose={() => {
          setAnchorElement(null);
        }}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {isLoading ? (
          <LinearProgress />
        ) : (
          <FilterSetList
            filters={filters}
            filterSets={data}
            waitlist={waitlist}
            onSaveSuccess={() => {}}
            onSelect={(filterSet) => {
              setAnchorElement(null);
              onApply(filterSet.filters);
            }}
          />
        )}
      </Popover>
    </Box>
  );
};

const FilterSetListItem = ({ filterSet, onSelect }) => {
  const deleteMutation = useDeleteFilterSet({});
  return (
    <ListItem
      key={`filter-set-${filterSet.id}`}
      disablePadding
      secondaryAction={
        <RoundedActionButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            deleteMutation.mutate({
              waitlist_id: filterSet.waitlist.id,
              id: filterSet.id,
            });
          }}
          isLoading={deleteMutation.isLoading}
          disabled={deleteMutation.isLoading}
        >
          <DeleteIcon />
        </RoundedActionButton>
      }
    >
      <ListItemButton
        onClick={() => {
          onSelect(filterSet);
        }}
      >
        <ListItemText primary={filterSet.name}></ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const FilterSetList = ({
  waitlist,
  filters,
  filterSets,
  onSelect,
  onSaveSuccess,
}) => {
  const mutation = useCreateFilterSet({
    onSuccess: onSaveSuccess,
  });

  return (
    <List>
      {filterSets.map((filterSet) => (
        <FilterSetListItem filterSet={filterSet} onSelect={onSelect} />
      ))}
      <ListItem>
        <FilterSetForm
          filters={filters}
          handleSubmit={async (values) => {
            await mutation.mutateAsync({
              ...values,
              waitlist_id: waitlist.id,
            });
          }}
        />
      </ListItem>
    </List>
  );
};

const FilterSetForm = ({ handleSubmit, filters }) => {
  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    filters: yup.object().test(
      'has-filters',
      () => 'At least one filter must be set',
      (value) => Object.values(value).filter((e) => !!e).length > 0
    ),
  });

  const initialValues = { filters };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
        resetForm(initialValues);
      }}
      initialValues={initialValues}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit: handleFormSubmit,
        isSubmitting,
        isValid,
      }) => {
        return (
          <form onSubmit={handleFormSubmit}>
            <Divider />
            <Box mt={2}>
              <Typography variant="h5">Save new search</Typography>
            </Box>
            <Box my={2}>
              <TextField
                aria-label="name"
                placeholder="Name this search"
                fullWidth="true"
                size="small"
                id="name"
                label="Name"
                value={values.name || ''}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Box>

            {errors.filters && (
              <ErrorMessage error>{errors.filters}</ErrorMessage>
            )}

            <Button
              color="secondary"
              variant="contained"
              size="medium"
              fullWidth
              type="submit"
              disabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default FilterSets;
