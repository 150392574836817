import { Select as MuiSelect } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectStyled = styled(MuiSelect)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.primary.contrastText,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));
