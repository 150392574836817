import { Box, CircularProgress, Autocomplete } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from 'components/button/Button';
import TextField from 'components/TextField';
import { Formik } from 'formik';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { useListMembers } from '../../../fetch-components/members';

const validationSchema = yup.object().shape({
  to: yup.array().required().min(1),
  amount: yup.number().required().positive(),
  description: yup.string().required(),
});

export const Form = ({ currentUser, waitlist, handleSubmit, to }) => {
  const [memberFilterQuery, setMemberFilterQuery] = useState('');

  const { isLoading, data } = useListMembers({
    listId: waitlist?.slug,
    page: 1,
    perPage: 100,
    filters: { email: memberFilterQuery, scope: 'current' },
  });

  return (
    <Formik
      initialValues={{ to }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit: handleFormSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleFormSubmit}>
            <Autocomplete
              multiple
              id="to"
              filterSelectedOptions
              getOptionLabel={(value) => value.username}
              getOptionSelected={(option, value) => option.id === value.id}
              options={data?.waitlist_members?.map((wm) => wm.user) || []}
              loading
              defaultValue={to || []}
              value={values.to}
              onChange={(_, value) => {
                setFieldValue('to', value, false);
              }}
              onInputChange={(_, value) => {
                setMemberFilterQuery(value);
              }}
              renderInput={(params) => (
                <TextField
                  size="medium"
                  {...params}
                  variant="outlined"
                  label="To"
                  error={Boolean(errors.to)}
                  helperText={errors.to}
                  autoComplete="off"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <Box my={2}>
              <NumberFormat
                customInput={TextField}
                id="amount"
                label="Amount"
                type="numeric"
                variant="outlined"
                value={values.amount}
                onChange={handleChange}
                error={Boolean(errors.amount)}
                helperText={errors.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {
                        currentUser.billing_account.country
                          .default_currency_symbol
                      }
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box my={2}>
              <TextField
                aria-label="minimum height"
                placeholder="What's it for?"
                fullWidth="true"
                multiline
                id="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                error={Boolean(errors.description)}
                helperText={errors.description}
              />
            </Box>

            <Button
              color="secondary"
              variant="contained"
              size="large"
              type="submit"
              fullWidth
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Request
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
