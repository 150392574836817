function NewSubscriptionFormController(paymentProcessor) {
  const stripe = paymentProcessor;
  const form = $(".subscription-form");
  const formMessages = new FormMessages(form);

  const stripeElements = stripe.elements();
  const cardNumber = stripeElements.create("cardNumber");
  cardNumber.mount("#card-number");
  const cardExpiry = stripeElements.create("cardExpiry");
  cardExpiry.mount("#card-expiry");
  const cardCvc = stripeElements.create("cardCvc");
  cardCvc.mount("#card-cvc");
  const postalCode = stripeElements.create("postalCode");
  postalCode.mount("#postal-code");

  this.onSubmitForm = function (event) {
    event.preventDefault();
    formMessages.clear();
    const submitButton = form.find('input[type="submit"]');
    submitButton.attr("disabled", true);

    stripe.createToken(cardNumber).then(function (response) {
      if (response.error) {
        formMessages.addError(response.error.message);
        formMessages.scrollToMessages();
        submitButton.removeAttr("disabled");

        return false;
      }

      $('input[name="subscription[credit_card_token]"]').val(response.token.id);
      form.get(0).submit();
    });

    return false;
  };

  this.validateExpMonth = function (event) {
    if ($(this).val().length >= 2) {
      return false;
    }
  };

  this.validateExpYear = function (event) {
    if ($(this).val().length >= 4) {
      return false;
    }
  };

  $(document).on("submit", ".subscription-form", this.onSubmitForm);
  $(document).on(
    "keydown",
    'input[name="subscription[exp_month]"]',
    this.validateExpMonth
  );
  $(document).on(
    "keydown",
    'input[name="subscription[exp_year]"]',
    this.validateExpYear
  );
}

module.exports = NewSubscriptionFormController;
