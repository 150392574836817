import React from 'react';
import {
  useCreateProfile,
  useGetProfile,
  useUpdateProfile,
} from '@fetch-components/profiles';

import { useSnackbar } from 'notistack';
import { CircularProgress, Typography } from '@mui/material';

import PersonalProfileForm from '@components/profile/PersonalProfileForm/PersonalProfileForm';
import { useCurrentUser } from '@contexts/CurrentUserContext';

const PersonalProfileTab = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  const { isLoading, data } = useGetProfile({
    onError: (error) => {
      if (error.status === 500) {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
        });
      }
    },
  });

  const createMutation = useCreateProfile({
    onSuccess: (data) => {
      if (data.errors) {
        enqueueSnackbar(data.errors[0].full_message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Profile created successfully!', {
          variant: 'success',
        });
      }
    },
  });

  const updateMutation = useUpdateProfile({
    onSuccess: (data) => {
      if (data.errors) {
        enqueueSnackbar(data.errors[0].full_message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Profile updated successfully!', {
          variant: 'success',
        });
      }
    },
  });

  const mutation = data?.id ? updateMutation : createMutation;

  if (mutation.isError) {
    enqueueSnackbar('Something went wrong!', {
      variant: 'error',
    });
  }

  return (
    <div class="u-spaceTM">
      <Typography paragraph>
        This is the info list owners will use to contact you when it is your
        turn, so be sure to keep it up to date and accurate.{' '}
        <em>
          <strong>
            We do not release this info to anyone other than the owners of
            waitlists you subscribe to.
          </strong>
        </em>
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <PersonalProfileForm
          initialValues={{
            email: currentUser.email,
            ...data,
          }}
          handleSubmit={async (values) => {
            await mutation.mutateAsync(values);
          }}
        />
      )}
    </div>
  );
};

export default PersonalProfileTab;
