import { Box, styled } from '@mui/system';

export const MembersTableWrap = styled(Box)(({ theme }) => ({
  table: {
    th: {
      paddingBottom: 6,
      borderBottomColor: theme.palette.grey[400],
    },
    // target checkbox rows
    'th:first-of-type, td:first-of-type': {
      paddingRight: 0,
    },
    // target expand icon rows
    'th:nth-of-type(2), td:nth-of-type(2)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    // target position/entries rows
    'th:nth-of-type(3), td:nth-of-type(3)': {
      paddingLeft: 0,
      cursor: 'pointer',
    },
    // target position/entries, members, joined rows
    'td:nth-of-type(3), td:nth-of-type(4), td:nth-of-type(5)': {
      cursor: 'pointer',
    },
    // compensate for sort ui
    'span.MuiTableSortLabel-root': {
      marginRight: '-25px',
    },
  },
}));
