import { Box, styled } from '@mui/system';

export const GiveawayWinnersTableStyled = styled(Box)(({ theme }) => ({
  table: {
    th: {
      paddingBottom: 6,
      borderBottomColor: theme.palette.grey[400],
    },
    // target expand icon rows
    'th:first-of-type, td:first-of-type': {
      paddingRight: 0,
    },
    // target entries rows
    'th:nth-of-type(2), td:nth-of-type(2)': {
      paddingLeft: 0,
    },
    // target entries, members, & joined rows
    'td:nth-of-type(2), td:nth-of-type(3), td:nth-of-type(4)': {
      cursor: 'pointer',
    },
  },
}));
