global.HeaderController = require("./header_controller");
global.IndexController = require("./index_controller");
global.ChatController = require("./chat_controller");
global.ChatRoomsController = require("./chat_rooms_controller");
global.CreditCardsController = require("./credit_cards_controller");
global.EditAccountController = require("./edit_account_controller");
global.EditBankingController = require("./edit_banking_controller");
global.JoinListFormController = require("./join_list_form_controller");
global.ListManageController = require("./list_manage_controller");
global.ListsController = require("./lists_controller");
global.NewSubscriptionFormController = require("./new_subscription_form_controller");
global.PayInvoiceController = require("./pay_invoice_controller");
global.UserFormController = require("./user_form_controller");
global.WaitlistFormController = require("./waitlist_form_controller");
global.WaitlistStatsController = require("./waitlist_stats_controller");
global.WaitlistViewController = require("./waitlist_view_controller");
global.WidgetModalController = require("./widget_modal_controller");
global.WidgetsController = require("./widgets_controller");
