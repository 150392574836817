import { Box, styled } from "@mui/system";
import React from "react";

export const StyledPageLoading = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  `
);
