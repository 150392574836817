import { useQuery, useMutation, useQueryClient } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

export const useGetWaitlist = ({ id }) => {
  return useQuery(['get-waitlist', id], () =>
    fetch(`${API_URL}/waitlists/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};

export const usePickGiveawayWinner = ({ waitlistId, onSuccess }) => {
  const queryClient = useQueryClient();

  const request = () =>
    fetch(`${API_URL}/waitlists/${waitlistId}/pick_giveaway_winner`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());

  return useMutation(request, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('list-members', waitlistId);
      queryClient.invalidateQueries('giveaway-winners', waitlistId);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
