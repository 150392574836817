class WidgetModalController {
  constructor() {
    $(".Free-widget").hide();
    $(".Premium-widget").hide();
    if (!$("#widget_id").val()) {
      $("#widget-embed-code-box").hide();
    }

    this.submitLoader = new Loader(
      $('#embedBox form input[type="submit"] .Loader')
    );

    $(document).on("ajax:success", "#embedBox form", (event) => {
      this.submitLoader.hide();
    });
    $(document).on("ajax:error", "#embedBox form", (event) => {
      this.submitLoader.hide();
    });
    $(document).on("submit", "#embedBox form", () => {
      this.submitLoader.show();
    });
    $(document).on("click", "#free-widget", this.onFreeWidgetClick);
    $(document).on("click", "#premium-widget", this.onPremiumWidgetClick);

    $(document).on("click", "#back-link", this.backLinkClick);

    $(document).on("click", "#embedBox .toggleEmbed", this.backLinkClick);

    $(document).on(
      "input",
      "#embedBox #widget_button_text",
      this.onButtonTextInput
    );

    $(document).on("widget.created", (event, widget) => {
      this.widgetCreated(widget);
    });

    $(document).on("widget.updated", (event, widget) => {
      this.widgetUpdated(widget);
    });

    $("#widget_button_background_color").spectrum({
      color: $("#widget_button_background_color").val(),
      preferredFormat: "hex",
      showInput: true,
      change: function (color) {
        $('input[name="button-background-color"]').val(color.toHexString());
        $("#preview-button").css({ "background-color": color.toHexString() });
      },
      move: function (color) {
        $('input[name="button-background-color"]').val(color.toHexString());
        $("#preview-button").css({ "background-color": color.toHexString() });
      },
    });

    $("#widget_button_text_color").spectrum({
      color: $("#widget_button_text_color").val(),
      preferredFormat: "hex",
      showInput: true,
      change: function (color) {
        $('input[name="button-text-color"]').val(color.toHexString());
        $("#preview-button").css({ color: color.toHexString() });
      },
      move: function (color) {
        $('input[name="button-text-color"]').val(color.toHexString());
        $("#preview-button").css({ color: color.toHexString() });
      },
    });

    $("#preview-button").css({
      "background-color": $("#widget_button_background_color").val(),
    });
    $("#preview-button").css({ color: $("#widget_button_text_color").val() });
    $("#preview-button").text($("#widget_button_text").val());
  }

  widgetUpdated(widget) {
    $("#widget-embed-code-box").show();
    $("#widgetSuccessMessage").html(
      '<div class="u-center u-textBranded u-spaceTM" style="display:none;">widget updated</div>'
    );
    $("#widgetSuccessMessage div").fadeIn("slow", function () {
      $(this).delay(2000).fadeOut("slow");
    });
    //alert('widget updated')
  }

  widgetCreated(widget) {
    $("#widget-embed-code-box").show();
    $("#widgetSuccessMessage").html(
      '<div class="u-center u-textBranded u-spaceTM" style="display:none;">widget created</div>'
    );
    $("#widgetSuccessMessage div").fadeIn("slow", function () {
      $(this).delay(2000).fadeOut("slow");
    });
    //alert('widget created')
  }

  onButtonTextInput(event) {
    if (event.keyCode !== 13) {
      if ($("#embedBox #widget_button_text").val().length > 0) {
        $("#preview-button").text($("#embedBox #widget_button_text").val());
      } else {
        $("#preview-button").text("JOIN MY LIST");
      }
    }
  }

  backLinkClick(event) {
    $(".Widget-selector").show();
    $(".Free-widget").hide();
    $(".Premium-widget").hide();
  }

  onFreeWidgetClick(event) {
    $(".Widget-selector").hide();
    $(".Free-widget").show();
  }

  onPremiumWidgetClick(event) {
    $(".Widget-selector").hide();
    $(".Premium-widget").show();
  }
}

module.exports = WidgetModalController;
