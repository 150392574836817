import React from "react";
import { Alert as MuiAlert, AlertTitle } from "@mui/material";
import { useAlerts } from "../../contexts/AlertContext";

export const AlertsContainer = () => {
  const { alerts } = useAlerts();

  return (
    <div>
      {alerts.map((alert) => (
        <Alert data={alert} />
      ))}
    </div>
  );
};

const Alert = ({ data }) => {
  if (data.render) {
    <MuiAlert severity={data.type}>{data.render()}</MuiAlert>;
  }
  if (data.title) {
    return (
      <MuiAlert severity={data.type}>
        <AlertTitle>{data.title}</AlertTitle>
        {data.message}
      </MuiAlert>
    );
  }
  return null;
};
