import {
  AttachMoney as AttachMoneyIcon,
  Check as CheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useCreateChatRoom } from '../../../fetch-components/chats';
import { RoundedActionButton } from '../../button/RoundedActionButton';
import RoundedChatButton from '../../button/RoundedChatButton';

const SelectCell = ({ isLoading, memberId, selected, checkBoxProps }) => (
  <TableCell role="checkbox">
    {isLoading ? (
      <Skeleton />
    ) : (
      <Checkbox
        checked={selected}
        inputProps={{ 'aria-labelledby': memberId }}
        {...checkBoxProps}
      />
    )}
  </TableCell>
);

const ExpandCell = ({ isLoading, expanded, buttonProps }) => (
  <TableCell>
    {isLoading ? (
      <Skeleton />
    ) : (
      <IconButton aria-label="expand row" size="small" {...buttonProps}>
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    )}
  </TableCell>
);

const PositionCell = ({ position, isLoading, onClick }) => {
  if (!position && !isLoading) {
    return <RemovedMemberPositionCell />;
  }
  return (
    <TableCell align="right" onClick={onClick}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography variant="body1">{`#${position}`}</Typography>
      )}
    </TableCell>
  );
};

const EntriesCell = ({ isLoading, entries, onClick }) => (
  <TableCell align="right" onClick={onClick}>
    <Typography variant="body1">
      {isLoading ? <Skeleton /> : `${entries} ${pluralize('entry', entries)}`}
    </Typography>
  </TableCell>
);

const RemovedMemberPositionCell = () => <TableCell align="right"></TableCell>;

const UsernameCell = ({ memberId, username, isLoading, onClick }) => (
  <TableCell id={memberId} scope="row" sx={{ width: '30%' }} onClick={onClick}>
    <Typography variant="h4">{isLoading ? <Skeleton /> : username}</Typography>
  </TableCell>
);

const ScoreCell = ({ isLoading, score }) => (
  <TableCell align="right">
    {isLoading ? (
      <Skeleton variant="text" />
    ) : (
      <NumberFormat value={score} displayType="text" decimalScale={0} />
    )}
  </TableCell>
);

const JoinDateCell = ({ date, isLoading, onClick }) => (
  <TableCell align="right" onClick={onClick}>
    {isLoading ? (
      <Skeleton variant="text" />
    ) : (
      moment(date).utc().format('MMM. Do YYYY, h:mm:ss a')
    )}
  </TableCell>
);

const PointsCell = ({ isLoading, memberId, points, score, onClick }) => (
  <TableCell align="center" onClick={onClick}>
    {isLoading ? (
      <Skeleton variant="text" />
    ) : (
      <Tooltip
        arrow
        title={
          <div>
            {points.map((mp, i) => (
              <div key={`member-points-${memberId}-${i}`}>
                {mp.description} +{mp.quantity}{' '}
                {pluralize('point', mp.quantity)}
              </div>
            ))}
          </div>
        }
      >
        <div>
          <NumberFormat value={score} displayType="text" decimalScale={0} />
        </div>
      </Tooltip>
    )}
  </TableCell>
);

const ProfileCell = ({ member, isLoading }) => {
  if (isLoading) {
    return null;
  }
  return <TableCell>{member.user.profile.name}</TableCell>;
};

const PaymentCell = ({
  isLoading,
  payment,
  invoice,
  onPaymentClick,
  onInvoiceClick,
}) => {
  const status = () => {
    if (payment) {
      return <>{_.startCase(payment.status)}</>;
    }
    if (invoice?.billing_payment) {
      return <>{_.startCase(invoice.billing_payment.status)}</>;
    }
    return <>{'Invoiced'}</>;
  };

  if (isLoading) {
    return (
      <TableCell align="right">
        <Skeleton />
      </TableCell>
    );
  }

  if (!invoice && !payment) {
    return <TableCell align="right"></TableCell>;
  }
  return (
    <TableCell align="right">
      {isLoading ? (
        <Skeleton variant="text" />
      ) : (
        <a
          href="#"
          onClick={() => {
            payment || invoice?.billing_payment
              ? onPaymentClick(payment)
              : onInvoiceClick(invoice);
          }}
        >
          {status()}
          &nbsp;
          <NumberFormat
            value={(payment || invoice).amount_cents / 100}
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale
            prefix={'$'}
            displayType="text"
          />
        </a>
      )}
    </TableCell>
  );
};

const ActionsCell = ({
  isLoading,
  member,
  onPaymentClick,
  onInvoiceClick,
  onNewInvoiceClick,
  onRemoveClick,
  onDisqualifyClick,
  includeDisqualify,
  includeRemove,
}) => (
  <TableCell align="right">
    {isLoading ? (
      <Skeleton />
    ) : (
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <ChatButton member={member} />
        <PaymentButton
          isLoading={isLoading}
          payment={member.billing_payment}
          invoice={member.billing_invoice}
          onPaymentClick={onPaymentClick}
          onInvoiceClick={onInvoiceClick}
          onNewInvoiceClick={onNewInvoiceClick}
        />
        {includeRemove && (
          <RemoveButton removedAt={member.removed_at} onClick={onRemoveClick} />
        )}
        {includeDisqualify && (
          <DisqualifyButton
            removedAt={member.removed_at}
            onClick={onDisqualifyClick}
          />
        )}
      </Stack>
    )}
  </TableCell>
);

const ChatButton = ({ member }) => {
  const mutation = useCreateChatRoom({
    onSuccess: (response) => {
      window.open(`/chat/rooms/${response.id}`, '_blank');
    },
  });
  const participants = [member.waitlist.owner.username, member.user.username];

  return (
    <Tooltip arrow title="Send message">
      <Box>
        <RoundedChatButton
          isLoading={mutation.isLoading}
          onClick={() => {
            mutation.mutate({
              participants,
            });
          }}
        />
      </Box>
    </Tooltip>
  );
};

const PaymentButton = ({
  payment,
  invoice,
  onPaymentClick,
  onInvoiceClick,
  onNewInvoiceClick,
}) => {
  if (payment || invoice?.billing_payment) {
    return (
      <Tooltip arrow title="Show payment">
        <Box>
          <RoundedActionButton
            aria-label="show payment"
            onClick={() => {
              onPaymentClick(payment || invoice?.billing_payment);
            }}
          >
            <AttachMoneyIcon fontSize="medium" />
          </RoundedActionButton>
        </Box>
      </Tooltip>
    );
  }
  if (invoice) {
    return (
      <Tooltip arrow title="Show invoice">
        <Box>
          <RoundedActionButton
            aria-label="show invoice"
            onClick={() => {
              onInvoiceClick(invoice);
            }}
          >
            <AttachMoneyIcon fontSize="medium" />
          </RoundedActionButton>
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip arrow title="Send invoice">
      <Box>
        <RoundedActionButton
          aria-label="send invoice"
          onClick={onNewInvoiceClick}
        >
          <AttachMoneyIcon fontSize="medium" />
        </RoundedActionButton>
      </Box>
    </Tooltip>
  );
};

const RemoveButton = ({ removedAt, onClick }) => (
  <Tooltip arrow title="Mark as done & remove from list">
    <Box>
      <RoundedActionButton
        aria-label="remove from list"
        disabled={Boolean(removedAt)}
        onClick={onClick}
      >
        <CheckIcon fontSize="medium" />
      </RoundedActionButton>
    </Box>
  </Tooltip>
);

const DisqualifyButton = ({ onClick }) => (
  <Tooltip arrow title="Disqualify and remove list member">
    <Box>
      <RoundedActionButton aria-label="remove and disqualify" onClick={onClick}>
        <CloseIcon fontSize="medium" />
      </RoundedActionButton>
    </Box>
  </Tooltip>
);

export {
  SelectCell,
  ExpandCell,
  PositionCell,
  UsernameCell,
  ScoreCell,
  EntriesCell,
  RemovedMemberPositionCell,
  JoinDateCell,
  PointsCell,
  PaymentCell,
  ActionsCell,
  ProfileCell,
};
