import InputSet from '@components/form/input/InputSet/InputSet';
import { Box, FormControl, Grid, RadioGroup, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../themes/default';
import CategoriesSuggestions from './CategoriesSuggestions';
import Category from './Category';
import { ImageWrap } from './Category.styled';
import Subcategories from './Subcategories';
import { filterCategoriesByKeywords } from './utils';

const WaitlistInstructions = ({ waitlist }) => {
  if (waitlist.id) {
    return (
      <span>
        A categorized list helps users find your list more easily.&nbsp;
      </span>
    );
  } else {
    return (
      <span>
        Categorizing your list helps users find your list more easily!&nbsp;
      </span>
    );
  }
};

const TopCategories = ({ categories, selected, onChange }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="Category"
        name="waitlist[top_category_id]"
        sx={{ gridGap: 20, mt: 1 }}
      >
        {categories.map((category, i) => (
          <Category
            key={`category-${i}`}
            category={category}
            checked={category.id === selected?.id}
            onChange={onChange}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const SelectedCategories = ({
  topCategory,
  subcategory,
  toggleSelectedCategories,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <InputSet header={"Your list's category"}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <ImageWrap>
              <img src={topCategory?.active_icon_url} />
            </ImageWrap>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5">
              {topCategory?.name} {subcategory ? `> ${subcategory.name}` : null}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.grey[600], lineHeight: 1.25 }}
            >
              {topCategory?.description}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2}>
          <a href="#" onClick={toggleSelectedCategories}>
            Change category
          </a>
        </Box>
      </InputSet>
    </ThemeProvider>
  );
};

const Categories = ({
  waitlist,
  categories,
  selectedTopCategoryId,
  selectedSubcategoryId,
}) => {
  const [topCategory, setTopCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [keywords, setKeywords] = useState({});
  const [selectedCategoriesVisible, setSelectedCategoriesVisible] =
    useState(false);
  const [allCategoriesVisible, setAllCategoriesVisible] = useState(false);
  const [suggested, setSuggested] = useState(false);
  const handleTopCategoryChange = (category) => {
    setTopCategory(category);

    if (category != topCategory) {
      setSubcategory(null);
    }
  };
  const toggleAllCategories = (e, visible) => {
    e.preventDefault();
    setAllCategoriesVisible(visible);
  };
  const toggleSelectedCategories = (e, visible) => {
    e.preventDefault();
    setSelectedCategoriesVisible(false);
  };

  useEffect(() => {
    // TODO: remove this listener workaround after the wizard implementation
    const titleInput = document.getElementById('waitlist_title');
    const descriptionInput = document.getElementById('waitlist_description');

    const titleInputListener = titleInput.addEventListener('change', (e) => {
      setKeywords({ ...keywords, title: e.target.value });
    });
    const descriptionInputListener = descriptionInput.addEventListener(
      'change',
      (e) => {
        setKeywords({ ...keywords, description: e.target.value });
      }
    );

    if (selectedTopCategoryId) {
      const topCategory = categories.find(
        (c) => c.id === selectedTopCategoryId
      );

      setTopCategory(topCategory);

      if (topCategory && selectedSubcategoryId) {
        const subcategory = topCategory.subcategories.find(
          (sc) => sc.id === selectedSubcategoryId
        );
        setSubcategory(subcategory);
      }

      setSelectedCategoriesVisible(true);
    }

    return () => {
      document.removeEventListener('change', titleInputListener);
      document.removeEventListener('change', descriptionInputListener);
    };
  }, []);

  useEffect(() => {
    const suggestions = filterCategoriesByKeywords(categories, keywords);

    if (suggestions.length > 0) {
      const suggestedTopCategory = suggestions.find(
        (category) => category.match
      );

      if (!waitlist.id && suggestedTopCategory) {
        setTopCategory(suggestedTopCategory);
        setSuggested(true);
      }

      setSuggestions(suggestions);
    }
  }, [keywords]);

  if (selectedCategoriesVisible) {
    return (
      <SelectedCategories
        topCategory={topCategory}
        subcategory={subcategory}
        toggleSelectedCategories={toggleSelectedCategories}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <InputSet
        header={'Choose a category (optional)'}
        instructions={
          <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>
            {allCategoriesVisible ? (
              <>
                Browse all categories and sub-categories or&nbsp;
                <a href="#" onClick={(e) => toggleAllCategories(e, false)}>
                  go back to the top categories.
                </a>
              </>
            ) : (
              <>
                <WaitlistInstructions waitlist={waitlist} />
                <a href="#" onClick={(e) => toggleAllCategories(e, true)}>
                  View all categories
                </a>
              </>
            )}
          </Typography>
        }
      >
        {allCategoriesVisible ? (
          <CategoriesSuggestions
            categories={categories}
            selectedTopCategory={topCategory}
            selectedSubcategory={subcategory}
            onChangeTopCategory={handleTopCategoryChange}
            onChangeSubcategory={setSubcategory}
            toggleAllCategories={toggleAllCategories}
          />
        ) : (
          <Box>
            <TopCategories
              categories={categories}
              selected={topCategory}
              onChange={handleTopCategoryChange}
            />
            <Subcategories
              topCategory={topCategory}
              selected={subcategory}
              onChange={setSubcategory}
            />
          </Box>
        )}
      </InputSet>
    </ThemeProvider>
  );
};

export default Categories;
