import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { StyledPageLoading } from "./PageLoading.style";

const PageLoading = () => (
  <StyledPageLoading>
    <CircularProgress />
  </StyledPageLoading>
);

export default PageLoading;
