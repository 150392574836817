import { useCurrentUser } from '@contexts/CurrentUserContext';
import { useCreateInvoice } from '@fetch-components/billing/invoices';
import { Box, Divider, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { Form } from './Form';

const NewInvoiceModal = ({ waitlist, members }) => {
  const onSuccess = () => {};

  const mutation = useCreateInvoice({ onSuccess });

  const { currentUser } = useCurrentUser();

  return (
    <Box sx={{ width: ['100%', 380], maxWidth: 380 }}>
      <Typography variant="h6">Request Money</Typography>
      <Box my={2}>
        <Divider />
      </Box>
      {mutation.isSuccess && (
        <SuccessMessage
          message={
            'Your invoice was sent! You will receive a notice when your list member pays it.'
          }
        />
      )}
      {mutation.isError && (
        <ErrorMessage
          message={
            "Something went wrong and we couldn't send the invoice. Try again alter."
          }
        />
      )}
      {!mutation.isSuccess && (
        <Form
          waitlist={waitlist}
          currentUser={currentUser}
          to={members.map((m) => m.user)}
          handleSubmit={async (values) => {
            await mutation.mutateAsync({
              ...values,
              waitlist_id: waitlist.id,
              to: values.to.map((e) => e.id),
            });
          }}
        />
      )}
    </Box>
  );
};

const SuccessMessage = ({ message }) => (
  <Alert severity="success">{message}</Alert>
);
const ErrorMessage = ({ message }) => <Alert severity="error">{message}</Alert>;

export default NewInvoiceModal;
