import { useQuery, useMutation, useQueryClient } from 'react-query';
import { authToken } from './auth';
import { API_URL } from './api';

import qs from 'qs';

export const useListWebhookEvents = ({ waitlistId }) => {
  const query = qs.stringify({
    waitlist_id: waitlistId,
  });
  return useQuery(['list-webhook-events', waitlistId], () =>
    fetch(`${API_URL}/webhook_events?${query}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  );
};
