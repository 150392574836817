function Loader(element) {
  var loader = this;
  this.element = element;
  $(this.element).hide();

  this.show = function () {
    $(loader.element).show();
  };

  this.hide = function () {
    $(loader.element).hide();
  };
}

global.Loader = Loader;
