import React from "react";
import Page from "../../pages/Page";
import BusinessProfileTab from "../../profile/BusinessProfileTab";
import PersonalProfileTab from "../../profile/PersonalProfileTab";
import Tabs from "../../Tabs/Tabs";

const EditProfile = () => {
  const tabOptions = [
    { value: "business", label: "Business Profile" },
    { value: "personal", label: "Personal Profile" },
  ];

  return (
    <Page>
      <div className="Layout-body Business-edit_profile">
        <div className="Layout-content Wrap">
          <h1>Profile</h1>

          <Tabs initialSelectedTab="business" options={tabOptions}>
            {({ selectedTab }) =>
              selectedTab === "personal" ? (
                <PersonalProfileTab />
              ) : (
                <BusinessProfileTab />
              )
            }
          </Tabs>
        </div>
      </div>
    </Page>
  );
};

export default EditProfile;
