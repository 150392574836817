const Dropzone = require("dropzone");

function Uploader(form) {
  this.form = form;
  uploader = this;

  this.onUploadComplete = function (callback) {
    this.onUploadCompleteCallback = callback;
  };

  this.onSending = function (callback) {
    this.onSendingCallback = callback;
  };

  this.onFileRemoved = function (callback) {
    this.onFileRemovedCallback = callback;
  };

  this.addFile = function (id, url) {
    var file = {
      name: url,
      size: 12345,
      id: id,
      status: Dropzone.ADDED,
      accepted: true,
    };
    this.dropzone.files.push(file);
    this.dropzone.emit("addedfile", file);
    this.dropzone.emit("thumbnail", file, url);
    this.dropzone.emit("complete", file);
  };

  this.init = function (options) {
    this.dropzone = new Dropzone("#featuredImage", {
      url: "/uploads",
      maxFilesize: 10,
      paramName: "image[file]",
      // thumbnailWidth: 389,
      thumbnailWidth: 200,
      // thumbnailHeight: 220,
      thumbnailHeight: 112,
      previewsContainer: "#listImages",
      maxFiles: options.maxFiles || 6,
      acceptedMimeTypes: "image/jpeg, image/jpg, image/png, image/gif",
      previewTemplate: document.getElementById("image-preview-template")
        .innerHTML,
      dictRemoveFileConfirmation: "Are you sure?",
      // uploadprogress: function(file, progress, bytesSent) {

      // },

      success: function (file, data) {
        $(file.previewElement).data("image-id", data.id);
        $(file.previewElement).find("#loading").hide();
        if (uploader.onUploadCompleteCallback) {
          uploader.onUploadCompleteCallback(data.id);
        }
      },
      uploadprogress: function (file, progress, bytesSent) {},
      sending: function (file) {
        $(file.previewElement).find("#loading").show();
        if (uploader.onSendingCallback !== undefined) {
          uploader.onSendingCallback();
        }
      },
      totaluploadprogress: function (progress) {},
      maxfilesexceeded: function (file) {
        this.removeFile(file);
        //alert('Remove your current file to upload a new one!');
        $("#maximageModal").addClass("Modal--show");
      },
      complete: function (file) {
        $(file.previewElement).find("#loading").hide();
      },
      // resize: function(file) {
      //   var info;

      //   // drawImage(image, srcX, srcY, srcWidth, srcHeight, trgX, trgY, trgWidth, trgHeight) takes an image, clips it to
      //   // the rectangle (srcX, srcY, srcWidth, srcHeight), scales it to dimensions (trgWidth, trgHeight), and draws it
      //   // on the canvas at coordinates (trgX, trgY).
      //   info = {
      //     srcX:0,
      //     srcY:0,
      //     srcWidth: file.width,
      //     srcHeight: file.height,
      //     trgX:0,
      //     trgY:0,
      //     trgWidth: this.options.thumbnailWidth,
      //     // trgHeight: parseInt(this.options.thumbnailWidth * file.height / file.width)
      //     trgHeight: this.options.thumbnailHeight
      //   }

      //   return info;
      // },
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
    });

    this.dropzone.on("removedfile", function (file) {
      if (uploader.onFileRemovedCallback) {
        uploader.onFileRemovedCallback(file);
      }
    });

    this.dropzone.on("addedfile", function (file) {
      if (file.id) {
        $(file.previewElement).data("image-id", file.id);
      }
    });
  };
}

global.Uploader = Uploader;
