import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { CategoryList } from './Category.styled';

const CategoriesSuggestions = ({
  categories,
  selectedTopCategory,
  selectedSubcategory,
  onChangeTopCategory,
  onChangeSubcategory,
  toggleAllCategories,
}) => {
  const handleTopCategoryChange = (category) => {
    onChangeTopCategory(category);
    onChangeSubcategory(null);
  };
  const handleSubcategoryChange = (topCategory, subcategory) => {
    onChangeTopCategory(topCategory);
    onChangeSubcategory(subcategory);
  };
  const waitlist = {
    title: document.getElementById('waitlist_title').value,
    description: document.getElementById('waitlist_description').value,
  };
  // const filteredCategories = useMemo(() => {
  //   return filterCategoriesByKeywords(categories, waitlist);
  // }, [categories, waitlist]);

  return (
    <>
      <CategoryList>
        <FormControl component="fieldset">
          <RadioGroup
            name="waitlist[top_category_id]"
            value={selectedTopCategory?.id || ''}
            onChange={(event) => {
              const category = categories.find(
                (c) => c.id == event.target.value
              );

              handleTopCategoryChange(category);
            }}
          >
            {categories.map((category) => (
              <React.Fragment key={`all-category-${category.id}`}>
                <FormControlLabel
                  value={category.id}
                  control={<Radio />}
                  label={category.name}
                />
                {category.description && (
                  <Box pl={4} mt={-1}>
                    <FormHelperText sx={{ fontSize: 13, ml: 0 }}>
                      {category.description}
                    </FormHelperText>
                  </Box>
                )}
                <RadioGroup
                  name="waitlist[subcategory_id]"
                  value={selectedSubcategory?.id || ''}
                  onChange={(event) => {
                    const subcategory = category.subcategories.find(
                      (s) => s.id == event.target.value
                    );
                    handleSubcategoryChange(category, subcategory);
                  }}
                >
                  {category.subcategories.map((subcategory) => (
                    <React.Fragment key={`all-subcategory-${subcategory.id}`}>
                      <Box pl={4}>
                        <FormControlLabel
                          value={subcategory.id}
                          control={<Radio />}
                          label={`${category.name} > ${subcategory.name}`}
                        />
                        {subcategory.description && (
                          <Box pl={4}>
                            <FormHelperText sx={{ fontSize: 13, ml: 0 }}>
                              {subcategory.description}
                            </FormHelperText>
                          </Box>
                        )}
                      </Box>
                    </React.Fragment>
                  ))}
                </RadioGroup>
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
        <Box mt={2}>
          <strong>
            Still can't find one that fits?&nbsp;
            <a href="/feedback" target="_blank">
              Suggest a category.
            </a>
          </strong>
        </Box>
      </CategoryList>
    </>
  );
};

export default CategoriesSuggestions;
